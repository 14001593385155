<template
  ><div class="etc-schedule-staff-form">
    <div class="etc-schedule-staff-form__button__wrapper" :class="{ showPopover: showStaffPopover }">
      <el-button class="etc-schedule-staff-form__popup-open-button" plain size="small" v-popover:selectedStaffs
        >{{ currentSelectedStaffs }}
      </el-button>
      <span class="el-input__suffix" :class="{ showClearButton: showClearButton }">
        <span class="el-select" v-show="!showClearButton">
          <span class="el-input">
            <i class="el-select__caret el-input__icon el-icon-arrow-up" :class="{ 'is-reverse': showStaffPopover }" />
          </span>
        </span>
      </span>

      <button class="clear-button" @click.stop="handleClearSelectedStaffs">
        <i class="el-icon-circle-close" />
      </button>
    </div>
    <el-popover
      popper-class="etc-schedule-staff-form__popper"
      ref="selectedStaffs"
      trigger="click"
      v-model="showStaffPopover"
      @show="handleShowStaffsPopover"
      @hide="handleCancelSelectStaffs"
    >
      <custom-checkbox
        class="etc-schedule-staff-form__popper__check-all"
        :checked="checkAllStaffs"
        :indeterminate="!checkAllStaffs && selectedStaffs.length !== 0"
        @change="handleChangeCheckStaffAll"
        >전체 선택</custom-checkbox
      >
      <transition-group name="flip-list" class="etc-schedule-staff-form__popper__list">
        <li v-for="staff in staffOptions" :key="staff.id">
          <custom-checkbox
            :value="staff.id"
            :checked="selectedStaffs.includes(staff.id)"
            :disabled="currentUser.id === staff.id"
            @change="handleChangeShowStaffIds"
            >{{ staff.name }}</custom-checkbox
          >
          <span v-if="currentUser.id === staff.id" class="register-text">작성자</span>
        </li>
      </transition-group>
      <div class="etc-schedule-staff-form__popper__buttons">
        <el-button @click="handleCancelSelectStaffs">
          취소
        </el-button>
        <el-button type="primary" @click="handleConfirmSelectStaffs">
          완료
        </el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      staffOptions: [],
      selectedStaffs: [],
      tempSelectedStaffs: [],
      checkAllStaffs: false,
      showStaffPopover: false,
      showClearButton: false,
    };
  },

  computed: {
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    currentSelectedStaffs() {
      if (this.selectedStaffs.length > 1) {
        return `${this.currentUser.name}, +${this.selectedStaffs.length - 1}`;
      }
      return this.currentUser.name;
    },
    isEditMode() {
      return !!this.$route.query.id;
    },
  },
  watch: {
    etcScheduleDetail: {
      deep: true,
      handler(newValue) {
        if (!this.isEditMode || !newValue) return;
        // 강사 목록이 로드된 후에 처리하기 위해 nextTick 사용
        this.$nextTick(() => {
          this.handleEtcScheduleDetailChange(newValue);
        });
      },
    },
    // staffOptions가 업데이트될 때도 selectedStaffs를 검증
    staffOptions: {
      handler() {
        if (this.isEditMode && this.etcScheduleDetail) {
          this.handleEtcScheduleDetailChange(this.etcScheduleDetail);
        }
      },
    },
  },

  created() {
    this.selectedStaffs = [this.currentUser.id];
    this.fetchStaffList();
  },

  methods: {
    handleShowStaffsPopover() {
      this.tempSelectedStaffs = [...this.selectedStaffs];
    },

    handleCancelSelectStaffs() {
      // 취소 버튼 클릭 시 이전 값으로 복원하고 팝업 닫기
      this.selectedStaffs = [...this.tempSelectedStaffs];
      this.showStaffPopover = false;
    },

    handleChangeCheckStaffAll() {
      this.checkAllStaffs = !this.checkAllStaffs;
      if (this.checkAllStaffs) {
        this.selectedStaffs = this.staffOptions.map(staff => staff.id);
      } else {
        this.selectedStaffs = [this.currentUser.id];
      }
    },

    handleChangeShowStaffIds(staffId) {
      const index = this.selectedStaffs.indexOf(staffId);
      const isSelected = index > -1;
      if (isSelected) {
        this.selectedStaffs.splice(index, 1);
      } else {
        this.selectedStaffs.push(staffId);
      }
      const staffCountAll = this.staffOptions.length;
      const staffCount = this.selectedStaffs.length;
      this.checkAllStaffs = staffCountAll === staffCount;
    },

    handleClearSelectedStaffs(e) {
      e.stopPropagation();
      // 작성자만 남기고 모두 초기화
      this.selectedStaffs = [this.currentUser.id];
      this.tempSelectedStaffs = [...this.selectedStaffs];
      this.checkAllStaffs = false;
      this.showStaffPopover = false;
      this.$emit('handleChangeSelectedStaffs', this.selectedStaffs);
    },

    handleConfirmSelectStaffs() {
      // 완료 버튼 클릭 시 현재 선택된 값을 저장하고 팝업 닫기
      this.tempSelectedStaffs = [...this.selectedStaffs];
      this.$emit('handleChangeSelectedStaffs', this.selectedStaffs);
      this.showStaffPopover = false;
    },

    handleEtcScheduleDetailChange(detail) {
      if (!detail) return;

      const { register, staffs } = detail;

      // 기존 선택된 강사 ID 목록 생성
      const staffIds = staffs.map(({ id }) => id);
      let selectedStaffs = [register.id, ...staffIds];

      // staffOptions에 존재하는 ID만 필터링
      const validStaffIds = this.staffOptions.map(staff => staff.id);
      selectedStaffs = selectedStaffs.filter(id => validStaffIds.includes(id));

      // 작성자는 항상 포함되어야 함
      if (!selectedStaffs.includes(this.currentUser.id)) {
        selectedStaffs.unshift(this.currentUser.id);
      }

      this.selectedStaffs = selectedStaffs;
      this.tempSelectedStaffs = [...selectedStaffs];
      this.$emit('handleChangeSelectedStaffs', this.selectedStaffs);
    },

    async fetchStaffList() {
      try {
        const res = await this.$api.staff.getAll();
        const staffList = res.data.staffs.map(staff => {
          return { id: staff.id, name: staff.profile.name };
        });

        // 현재 작성자를 찾아서 배열의 첫번째로 이동
        const currentUserIndex = staffList.findIndex(staff => staff.id === this.currentUser.id);
        if (currentUserIndex !== -1) {
          const currentUser = staffList.splice(currentUserIndex, 1)[0];
          staffList.unshift(currentUser);
        }

        this.staffOptions = staffList;

        // 기본적으로 작성자만 선택
        if (!this.selectedStaffs.length) {
          this.selectedStaffs = [this.currentUser.id];
          this.tempSelectedStaffs = [this.currentUser.id];
        }

        // etcScheduleDetail이 이미 있는 경우 선택된 강사 업데이트
        if (this.isEditMode) {
          this.handleEtcScheduleDetailChange(this.etcScheduleDetail);
        }
      } catch (error) {
        this.staffOptions = [{ id: this.currentUser.id, name: this.currentUser.name }];
        this.selectedStaffs = [this.currentUser.id];
        this.tempSelectedStaffs = [this.currentUser.id];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-staff-form {
  &__button__wrapper {
    width: 200px;
    height: 32px;
    position: relative;
    border-bottom: 2px solid #eee;
    cursor: pointer;

    &.showPopover {
      border-bottom: 2px solid #408ada;
      &:hover {
        border-color: #408ada;
      }
    }

    &:hover {
      &__popup-open-button {
        background-color: #f8f9fa;
        border-radius: 3px;
        border-color: #c0c4cc;
        color: #606266;
      }

      .clear-button {
        display: block;
        background-color: #f8f9fa;
      }
    }
  }

  .el-input__suffix {
    display: block;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -7px;

    &.showClearButton {
      transition: none;
    }
  }

  .clear-button {
    display: none;
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 3px;
    top: 50%;
    margin-top: -9px;
    background-color: #fff;

    .el-icon-circle-close {
      height: 100%;
      color: #c0c4cc;
    }

    &:hover {
      background-color: #fff;
    }
  }

  &__popup-open-button {
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
    color: #606266;
    transition: none;

    &:hover {
      background-color: #f8f9fa;
      border-radius: 3px;
      border-color: #c0c4cc;
      color: #606266;
    }

    &:focus {
      color: #606266;
    }
  }
}
</style>

<style lang="scss">
.etc-schedule-staff-form__popper {
  min-width: 327px;
  height: 305px;
  padding: 26px 20px 10px;

  label.etc-schedule-staff-form__popper__check-all {
    padding: 0;
    margin-bottom: 12px;
  }

  &__list {
    @include flex(column);
    padding: 10px 0;
    border: solid #eee;
    border-width: 1px 0;
    max-height: 180px;
    overflow-y: auto;
    gap: 14px;

    li {
      margin-right: 7px;
      transition: background 0.15s;

      &:first-of-type {
        display: flex;
        align-items: center;
        gap: 8px;

        .register-text {
          flex: 0 0 auto;
          display: inline-block;
          width: 40px;
          height: 20px;
          text-align: center;
          border-radius: 2px;
          font-size: 10px;
          font-weight: 500;
          line-height: 20px;
          color: #4799f2;
          background: rgba(71, 153, 242, 0.12);
        }
      }

      label {
        padding: 0;

        input {
          flex: 0 0 auto;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
