<template>
  <el-dialog
    :class="`payment-list-dialog ${type}`"
    :title="typeText"
    width="497px"
    :visible="show"
    @close="handleClickCancel"
    append-to-body
  >
    <p class="description">{{ description }}</p>
    <button class="btn-text-blue" @click="isEditing = !isEditing">{{ editButtonText }}</button>
    <ul :class="`payment-list__list-container${isEditing ? ' editing' : ''}`">
      <li
        v-for="(item, itemKey) in itemList"
        :key="itemKey"
        :class="`payment-list__list-item ${item.class ? item.class : ''} ${isDeprecatedPay(item) ? 'disabled' : ''}`"
        @click="() => handleClickCard(item)"
      >
        <p v-if="item.class === 'addCard'">{{ item.nick_name || item.card_name }}</p>
        <p v-if="item.class !== 'addCard'" class="title">
          {{ `${item.nick_name || item.card_name} ${getLastFourDigits(item)}` }}
          <span v-if="!isEditing && item.class === 'selected'">현재 선택 카드</span>
          <label v-if="!isEditing && !item.label && isDeprecatedPay(item)">더이상 결제할 수 없는 카드입니다.</label>
        </p>

        <button
          v-if="isEditing && !item.label && item.class !== 'addCard'"
          class="delete"
          @click="() => handleClickDeleteCard(item.id)"
        >
          삭제
        </button>
        <button v-if="isEditing && item.label" disabled>{{ item.label }}</button>
      </li>
    </ul>

    <el-pagination
      class="payment-list__pagination"
      :current-page="page"
      layout="prev, pager, next"
      :page-size="perPage"
      :total="total"
      @current-change="handleChangePage"
    />
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
    paymentList: Array,
    showRegistCardModal: Function,
  },

  data() {
    return {
      page: 1,
      perPage: 6,
      isEditing: false,
    };
  },

  computed: {
    type() {
      return this.$store.getters['payment/type'];
    },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    subscriptionCardId() {
      return this.paymentPolicy.subscription_card_id;
    },

    hasPhoneNumber() {
      return this.type === 'point_card_id'
        ? this.paymentPolicy.point_charging_payment_notification_number
        : this.paymentPolicy.subscription_payment_notification_number;
    },

    itemList() {
      return this.itemListContainer[this.page - 1];
    },

    itemListContainer() {
      const addCard = {
        class: 'addCard',
        card_name: '카드 추가',
        event: () => this.showRegistCardModal(),
      };

      const paymentList = [...this.setChosen(this.paymentList), addCard];
      const result = paymentList.reduce((acc, currentItem, currentIdx) => {
        const index = parseInt(currentIdx / this.perPage);
        if (!acc[index]) {
          acc[index] = [];
        }
        acc[index].push(currentItem);
        return acc;
      }, []);
      return result;
    },

    total() {
      return this.paymentList.length + 1;
    },

    editButtonText() {
      return this.isEditing ? '편집 완료' : '카드 편집';
    },

    isBothAutoCharging() {
      return (
        this.paymentPolicy.usable_subscription_auto_charge &&
        this.paymentPolicy.usable_point_auto_charge &&
        this.subscriptionCardId === this.paymentPolicy.point_card_id
      );
    },

    typeText() {
      return this.type === 'point_card_id' ? '포인트 자동 충전 카드' : '정기 결제 카드';
    },
    description() {
      return this.type === 'point_card_id'
        ? '포인트 자동 충전 중인 카드는 삭제할 수 없습니다.'
        : '정기 결제 사용 중인 카드는 삭제할 수 없습니다.';
    },
  },

  watch: {
    show() {
      if (this.show) {
        this.handleChangePage(1);
        this.isEditing = false;
      }
    },
  },

  methods: {
    handleChangePage(page) {
      this.page = page;
    },
    async handleClickDeleteCard(id) {
      const {
        usable_point_auto_charge,
        usable_subscription_auto_charge,
        subscription_card_id,
        point_card_id,
      } = this.paymentPolicy;
      if (
        usable_point_auto_charge &&
        usable_subscription_auto_charge &&
        subscription_card_id === point_card_id &&
        point_card_id === id
      ) {
        return this.$utils.notify.error(
          this,
          '오류',
          '서비스 결제 및 포인트 자동 충전 카드로 등록된 경우 삭제가 불가합니다. 결제 유형 변경 또는 결제 수단을 변경해주세요.',
        );
      }
      if (usable_point_auto_charge && point_card_id === id) {
        return this.$utils.notify.error(
          this,
          '오류',
          '포인트 자동 충전 카드로 등록된 경우 삭제가 불가합니다. 자동 충전을 해제 또는 자동 충전 카드를 변경해주세요.',
        );
      }

      if (usable_subscription_auto_charge && subscription_card_id === id) {
        return this.$utils.notify.error(
          this,
          '오류',
          '서비스 이용료 자동 결제 카드로 등록된 경우 삭제가 불가합니다. 서비스 결제 카드를 변경해주세요.',
        );
      }

      const title = ``;
      const message = `카드를 삭제하시겠습니까?`;
      this.$confirm(message, title, {
        confirmButtonText: '삭제',
        dangerouslyUseHTMLString: true,
        customClass: 'payment-list-dialog-modal',
      }).then(async () => {
        const res = await this.$store.dispatch('payment/deletePayment', {
          id,
          caseType: this.type === 'point_card_id' ? 'point' : 'monthly',
        });

        if (res.success) {
          await this.$store.dispatch('payment/getPaymentPolicy');
          await this.$utils.notify.success(this, '성공', '등록된 카드가 삭제되었습니다.');
        } else {
          if (res.error.response.data.status_code === 404) {
            this.$utils.notify.error(this, '오류', '이미 삭제된 카드입니다. 새로고침 후 이용해주세요.');
          } else {
            this.$utils.notify.error(this, '오류', res.error.response.data.message);
          }
        }
      });
    },

    handleClickCard(item) {
      if (this.type === 'subscription_card_id' && !this.paymentPolicy.usable_subscription_auto_charge)
        return this.$utils.notify.error(this, '오류', '정기 결제 서비스를 이용하는 경우에만 등록 가능합니다.');
      if (this.type === 'point_card_id' && !this.paymentPolicy.usable_point_auto_charge)
        return this.$utils.notify.error(this, '오류', '포인트 자동 충전 서비스를 이용하는 경우에만 등록 가능합니다.');
      if (item.class === 'addCard' && !this.hasPhoneNumber)
        return this.$utils.notify.error(this, '오류', '결제 알림을 받을 휴대폰 번호를 먼저 등록해주세요.');

      if (item.class === 'addCard') return item.event();

      if (
        this.type === 'point_card_id' &&
        this.paymentPolicy.usable_point_auto_charge &&
        item.id === this.paymentPolicy.point_card_id
      )
        return;

      if (
        this.type === 'subscription_card_id' &&
        this.paymentPolicy.usable_subscription_auto_charge &&
        item.id === this.subscriptionCardId
      )
        return;
      if (!this.isEditing) return this.dispatchUpdatePaymentCard(item);
    },

    dispatchUpdatePaymentCard: _.debounce(async function(item) {
      const res = await this.$store.dispatch('payment/updatePaymentPolicy', { [this.type]: item.id });

      if (res.success) {
        this.$utils.notify.success(this, '성공', `${this.typeText}로 등록되었습니다.`);
      } else {
        if (res.error.response.data.status_code === 422) {
          const title = ``;
          const message = `카드를 다시 등록해주세요.`;

          this.$confirm(message, title, {
            showCancelButton: false,
            confirmButtonText: '확인',
            dangerouslyUseHTMLString: true,
            customClass: 'carousel-item-container-modal',
          })
            .then(() => {
              this.$router.go(0);
            })
            .catch(() => {
              this.$router.go(0);
            });
        } else {
          this.$utils.notify.error(this, '오류', res.error.response.data.errors[this.type][0]);
        }
      }
    }, 250),

    setChosen(data) {
      if (!data) return [];
      return data.reduce((acc, current) => {
        let chosen = { class: '', label: '' };
        if (
          !this.isBothAutoCharging &&
          this.paymentPolicy.usable_subscription_auto_charge &&
          current.id === this.subscriptionCardId
        ) {
          chosen.class = this.type === 'subscription_card_id' ? 'selected' : '';
          chosen.label = '서비스 정기 결제 카드';
        }

        if (
          !this.isBothAutoCharging &&
          this.paymentPolicy.usable_point_auto_charge &&
          current.id === this.paymentPolicy.point_card_id
        ) {
          chosen.class = this.type === 'point_card_id' ? 'selected' : '';
          chosen.label = '포인트 정기 결제 카드';
        }

        if (this.isBothAutoCharging && this.subscriptionCardId === current.id) {
          // 서비스 결제 및 포인트 충전 모두 자동결제하는 경우
          chosen.class = 'selected';
          chosen.label = '서비스 / 포인트 정기 결제 카드';
        }
        if (chosen.label) {
          return (acc = [...acc, { ...current, ...chosen }]);
        }
        return (acc = [...acc, { ...current, class: '' }]);
      }, []);
    },

    isDeprecatedPay(item) {
      return item.pg_type === 'ks_pay';
    },

    getLastFourDigits({ card_number }) {
      return card_number ? `(${card_number.slice(-4).replace('X', '*')})` : '';
    },
  },
};
</script>

<style scoped lang="scss">
.payment-list-dialog {
  &.subscription_card_id {
    /deep/ .el-dialog__header:before {
      background: url('../../../assets/images/icons/change-subscription-card.svg') 0 0 no-repeat;
    }
  }
  &.point_card_id {
    /deep/ .el-dialog__header:before {
      background: url('../../../assets/images/icons/change-point-card.svg') 0 0 no-repeat;
    }
  }

  /deep/ .el-dialog {
    top: 2vh;
    padding: 135px 30px 30px 30px;
    border-radius: 4px;

    &__header {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #343a40;
      padding: 0px 0px 2px 0px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 45px;
        left: 30px;
        width: 64px;
        height: 64px;
        background-size: cover;
      }
    }

    &__body {
      padding: 0px 0px 0px 0px;
      > p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a3a3a3;
        padding: 2px 0px 20px 0px;
        &.description {
          font-size: 13px;
          color: #606266;
        }
      }
    }
  }

  button {
    &.btn-text-blue {
      position: absolute;
      top: 135px;
      right: 30px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #1da0ff;
    }
  }
  .payment-list {
    &__list {
      &-container {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 25px 0px;

        &.editing {
          cursor: auto;
          pointer-events: none;

          .title {
            border-bottom: 1px solid #eee;
            width: 80%;
            margin: 0 auto;
            height: 53px;
          }

          button {
            cursor: pointer;
            pointer-events: auto;
            height: 100%;
            line-height: 1;

            &.delete {
              color: #ff9393;
            }
          }

          .selected {
            button {
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #a3a3a3;
            }
          }

          .payment-list__list-item:not(.addCard) {
            height: 93px;
          }
        }

        &:not(.editing) .selected {
          background: $dodger-blue;
          border: none;

          .title {
            color: $white;
            font-weight: bold;
            gap: 8px;
            span {
              font-size: 12px;
              font-weight: normal;
              line-height: 1;
            }
          }

          .sub-title {
            color: $white;
            font-weight: 500;
          }
        }

        &:not(.editing) .disabled {
          border: 1px solid #dcdfe6;
          background: #f8f8f8;
          cursor: not-allowed;
          pointer-events: none;
          .title {
            height: 100%;
            gap: 4px;
            flex-direction: column;
            line-height: 1;
            color: #a3a3a3;

            label {
              color: #ff8d8d;
              font-size: 10px;
              font-weight: 400;
              line-height: 140%; /* 14px */
              letter-spacing: -0.25px;
            }
          }
        }
      }
      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 60px;
        line-height: 60px;
        border-radius: 4px;
        border: solid 1px #eee;
        background-color: $white;
        margin: 0px 0px 10px 0px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #808080;
        cursor: pointer;

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        p pre {
          height: 55px;
          line-height: 55px;
          margin: 0px;
        }

        &:last-child {
          margin: 0px 0px 0px 0px;
        }

        button {
          display: inline-block;
          right: 0px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ff9393;
          padding: 0px;
        }

        button {
          &:not(.editing) {
            //width: 70%;
            color: #a3a3a3;
          }
        }

        &.selected {
          button {
            //width: 70%;
            font-weight: 500;
            color: #fff;
            cursor: auto;
            pointer-events: none;
          }
        }

        &.addCard {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: solid 1px #eee;
          background-color: #f8f8f8;
          color: #a3a3a3;
          gap: 6px;
          &:before {
            content: '';
            display: block;
            left: 30px;
            width: 18px;
            height: 18px;
            background-size: cover;
            background: url('../../../assets/images/icons/plus.svg') 0 0 no-repeat;
          }
          p {
            width: auto;
            font-size: 12px;
          }
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss">
.payment-list-dialog-modal {
  position: relative;
  width: 430px !important;
  height: 243px !important;

  &.el-message-box {
    width: 430px !important;
    height: 243px !important;
  }

  .el-message-box {
    &__content {
      display: block;
      position: relative;
      padding: 105px 30px 20px 30px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: $charcoal-grey;

      &:before {
        content: '';
        width: 90px;
        height: 90px;
        position: absolute;
        top: 0;
        left: 30px;
        background: url('../../../assets/images/icons/icon_warning-red.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    &__btns {
      position: absolute;
      right: 0px;
      bottom: 20px;
      padding: 0px 30px 0px 30px;
      button {
        width: 64px;
        height: 32px;
        font-size: 14px;

        span {
          padding: 0px;
        }
      }

      button:first-child {
        border: 1px solid #8a8a8a;
        color: rgba(138, 138, 138, 0.9);
      }
    }

    &__headerbtn {
      top: 30px;
      right: 30px;
      z-index: 1;
    }
  }
}
</style>
