<template>
  <el-dialog :visible="show" width="500px" @close="$emit('close')">
    <p slot="title" class="header">
      등록 완료
    </p>
    <div class="content">
      <b>{{ cardName }}</b>
      <p>카드 등록이 완료되었습니다.</p>
      <br />
      <p>계속해서 결제를 진행하시겠습니까?</p>
    </div>
    <div slot="footer">
      <el-button @click="$emit('close')">취소</el-button>
      <el-button type="primary" @click="$emit('proceedPayment')">확인</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    cardAdded: { type: Object, default: null },
  },
  computed: {
    cardName() {
      const name = _.get(this.cardAdded, 'card_name', '');
      const number = _.get(this.cardAdded, 'card_number', '')
        .slice(-4)
        .replace('X', '*');
      return `${name}(${number})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px;
  font-weight: 500;
  color: #303133;
}
.content {
  b {
    font-weight: 700;
  }
}
</style>
