<template>
  <el-dialog :visible="show" @close="$emit('close')" width="500px">
    <span slot="title" class="title"> 카드 재등록 요청</span>
    <div class="change-notice">
      <p>스튜디오메이트 결제 시스템이 변경되었습니다.</p>
      <p>더 편리한 결제를 위해 카드 정보를 다시 등록해주세요.</p>
      <ul>
        <li>기존 : KSNET</li>
        <li>변경 : 토스 페이먼츠</li>
      </ul>
    </div>

    <div slot="footer">
      <el-button @click="$emit('close')">취소</el-button>
      <el-button type="primary" @click="$emit('registerCard')">카드 등록</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #303133;
  font-size: 18px;
  font-weight: 500;
}
.change-notice {
  ul {
    line-height: 1.4;
    margin-top: 20px;
    padding-left: 20px;

    li {
      list-style: disc;
    }
  }
}
</style>
