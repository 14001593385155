<template>
  <MainLayout :padded="false">
    <ContentHeader :breadcrumb="breadcrumb" :title="title" :contentMaxWidth="1120" />
    <section class="create-group-lesson">
      <form-block :index="1" label="수업 설정">
        <div class="create-group-lesson__basic-info">
          <div class="create-group-lesson__basic-info__course-title">
            <label>수업명</label>
            <TextInput ref="courseTitle" v-model="course.title" required material />
          </div>
          <div class="create-group-lesson__basic-info__instructor">
            <label>담당 강사</label>
            <el-select
              ref="instructorId"
              v-model="course.instructor_id"
              :disabled="!canCreateOthersGroupLecture"
              filterable
              placeholder="강사 선택"
            >
              <el-option
                v-for="instructor in filteredInstructors"
                :key="instructor.id"
                :value="instructor.id"
                :label="instructor.name"
              />
            </el-select>
          </div>
          <div class="create-group-lesson__basic-info__description">
            <label>수업 소개</label>
            <TextInput v-model="course.description" material />
          </div>
          <div class="create-group-lesson__basic-info__max-trainee">
            <label>최대 수강 인원</label>
            <NumberInput ref="maxTrainee" v-model="course.max_trainee" :min="1" :max="255" />
          </div>
          <div class="create-group-lesson__basic-info__min-trainee">
            <label>최소 수강 인원</label>
            <NumberInput ref="minTrainee" v-model="course.min_trainee" :min="0" :max="course.max_trainee" />
          </div>
          <p class="create-group-lesson__basic-info__alert">
            '폐강 시간' 이후, 최소 수강 인원이 채워지지 않으면 이 수업은 자동 삭제 됩니다.<br />
            단, 최소 수강 인원이 0명인 경우는 삭제되지 않습니다.
          </p>
        </div>
      </form-block>

      <form-block v-if="studioPolicies.weekly_waiting_limit" :index="2" label="예약 대기 가능 인원">
        <div class="create-group-lesson__waiting-trainee-limit">
          <NumberInput ref="waitingTraineeLimit" v-model="course.waiting_trainee_limit" :min="0" :max="99" />
          <p>
            0을 입력할 경우, 예약대기 인원 수에는 제한이 없습니다.
          </p>
        </div>
      </form-block>

      <form-block :index="studioPolicies.weekly_waiting_limit ? 3 : 2" label="룸 설정">
        <div class="create-group-lesson__room">
          <el-select v-model="course.room_id" placeholder="룸 설정 안함" :disabled="!useRooms" filterable clearable>
            <el-option v-for="room in rooms" :key="room.id" :value="room.id" :label="room.name" />
          </el-select>
        </div>
      </form-block>

      <form-block :index="studioPolicies.weekly_waiting_limit ? 4 : 3" label="수업 구분">
        <div class="create-group-lesson__division">
          <p>
            필라테스, 요가 등 수업의 종목을 선택할 수 있습니다.<br />
            수업 종목을 설정하여 특정 종목의 수강권을 가진 회원만 수업을 예약하게 할 수 있습니다.
          </p>
          <el-select v-model="course.division_id" placeholder="수업 구분 없음" filterable clearable>
            <el-option v-for="division in divisions" :key="division.id" :value="division.id" :label="division.title" />
          </el-select>
        </div>
      </form-block>

      <form-block :index="studioPolicies.weekly_waiting_limit ? 5 : 4" label="예약 / 취소 가능 시간">
        <div class="create-group-lesson__deadlines">
          <div class="create-group-lesson__group">
            <div class="create-group-lesson__type-viewer">
              {{ viewRuleType(course.group_booking_rule_type) }}
            </div>
            <AvailableHoursInput
              :type="course.group_booking_rule_type"
              :relativeStart="course.group_booking_startline"
              relativeStartPropName="group_booking_startline"
              :relativeEnd="course.group_booking_deadline"
              relativeEndPropName="group_booking_deadline"
              :absoluteStartDays="course.group_booking_start_days"
              absoluteStartDaysPropName="group_booking_start_days"
              :absoluteStartTime="course.group_booking_start_time"
              absoluteStartTimePropName="group_booking_start_time"
              :absoluteEndDays="course.group_booking_end_days"
              absoluteEndDaysPropName="group_booking_end_days"
              :absoluteEndTime="course.group_booking_end_time"
              absoluteEndTimePropName="group_booking_end_time"
              @change="handleAvailableHoursChange"
            >
              <span slot="suffix">까지 <b>예약 가능</b>합니다.</span>
            </AvailableHoursInput>
          </div>
          <div class="create-group-lesson__group">
            <div class="create-group-lesson__type-viewer">
              {{ viewRuleType(course.group_booking_cancel_rule_type) }}
            </div>
            <AvailableHoursInput
              :type="course.group_booking_cancel_rule_type"
              :relativeStart="course.group_booking_cancel_startline"
              relativeStartPropName="group_booking_cancel_startline"
              :relativeEnd="course.group_booking_cancel_deadline"
              relativeEndPropName="group_booking_cancel_deadline"
              :absoluteStartDays="course.group_booking_cancel_start_days"
              absoluteStartDaysPropName="group_booking_cancel_start_days"
              :absoluteStartTime="course.group_booking_cancel_start_time"
              absoluteStartTimePropName="group_booking_cancel_start_time"
              :absoluteEndDays="course.group_booking_cancel_end_days"
              absoluteEndDaysPropName="group_booking_cancel_end_days"
              :absoluteEndTime="course.group_booking_cancel_end_time"
              absoluteEndTimePropName="group_booking_cancel_end_time"
              @change="handleAvailableHoursChange"
            >
              <span slot="suffix">까지 <b>예약 취소 가능</b>합니다.</span>
            </AvailableHoursInput>
          </div>
        </div>
      </form-block>
      <form-block
        :index="studioPolicies.weekly_waiting_limit ? 6 : 5"
        label="당일 예약 변경 / 폐강 시간 / 예약 대기 자동 예약 시간"
      >
        <div class="create-group-lesson__deadlines">
          <AvailableHoursInput
            type="R"
            :relativeStart="course.daily_booking_change_deadline"
            relativeStartPropName="daily_booking_change_deadline"
            :relativeEnd="course.daily_booking_change_deadline"
            relativeEndPropName="daily_booking_change_deadline"
            @change="handleAvailableHoursChange"
          >
            <span slot="suffix"> 까지 <b>당일 예약 변경 가능</b>합니다. </span>
          </AvailableHoursInput>
          <AvailableHoursInput
            type="R"
            :relativeStart="course.autoclose_deadline"
            relativeStartPropName="autoclose_deadline"
            :relativeEnd="course.autoclose_deadline"
            relativeEndPropName="autoclose_deadline"
            @change="handleAvailableHoursChange"
          >
            <span slot="suffix">
              에 <b>폐강</b>합니다.
              <el-tooltip effect="light" placement="right">
                <p slot="content">
                  최소 수강 인원 미달시<br />
                  수업을 자동으로 삭제하고 회원의 예약을 취소하는 시간<br />
                  단, 최소 수강 인원이 0명인 경우는 삭제되지 않습니다.
                </p>
                <i
                  class="el-icon-question"
                  :style="{
                    color: '#64AEFF',
                    fontSize: '18px',
                  }"
                ></i>
              </el-tooltip>
            </span>
          </AvailableHoursInput>
          <AvailableHoursInput
            type="R"
            :relativeStart="course.booking_auto_shift_deadline"
            relativeStartPropName="booking_auto_shift_deadline"
            :relativeEnd="course.booking_auto_shift_deadline"
            relativeEndPropName="booking_auto_shift_deadline"
            @change="handleAvailableHoursChange"
          >
            <span slot="suffix">
              까지 예약대기는 자동 예약 됩니다.
              <el-tooltip effect="light" placement="right">
                <p slot="content">
                  예약대기는 공석이 발생할 경우<br />
                  예약대기 1순위가 자동으로 예약되며 예약대기 순번은 자동 변경됩니다.<br />
                </p>
                <i
                  class="el-icon-question"
                  :style="{
                    color: '#64AEFF',
                    fontSize: '18px',
                  }"
                ></i>
              </el-tooltip>
            </span>
          </AvailableHoursInput>
        </div>
      </form-block>
      <form-block ref="schedules" :index="studioPolicies.weekly_waiting_limit ? 7 : 6" label="수업 일정" v-if="!course.id">
        <div class="create-group-lesson__daterange">
          <label>수업 기간</label>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="~"
            start-placeholder="수업 시작일"
            end-placeholder="수업 종료일"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="daterangePickerOptions"
            :clearable="false"
            required
          />
        </div>
        <ul class="create-group-lesson__weekday-buttons">
          <li v-for="weekday in weekdays" :key="weekday">
            <el-button
              size="small"
              circle
              :type="lessonHours[weekday].length > 0 ? 'primary' : 'default'"
              @click="handleClickWeekday(weekday)"
              >{{ weekday }}</el-button
            >
          </li>
        </ul>
        <ul v-for="weekday in weekdays" :key="weekday" class="create-group-lesson__lesson-hour-list">
          <li v-for="(time, index) in lessonHours[weekday]" :key="index">
            <h5>{{ index === 0 ? `${weekday}요일` : '' }}</h5>
            <hours-input
              v-model="lessonHours[weekday][index]"
              :startHourOptions="{
                start: '00:00',
                end: '23:50',
                step: '00:05',
              }"
              :endHourOptions="{
                start: lessonHours[weekday][index].start || '00:00',
                end: '23:55',
                step: '00:05',
                minTime: lessonHours[weekday][index].start,
              }"
              :clearable="false"
            />
            <el-button
              size="small"
              type="info"
              :disabled="!lessonHours[weekday][index].start"
              @click="setEndTime(weekday, index, 50)"
              >50분</el-button
            >
            <el-button icon="el-icon-minus" size="mini" circle @click="handleClickRemoveLessonHour(weekday, index)" />
            <el-button @click="handleClickApplyAll(weekday, index)" size="mini">모든 요일에 일괄 적용</el-button>
          </li>
          <li v-if="lessonHours[weekday].length > 0">
            <el-button
              class="create-group-lesson__lesson-hour-list__button"
              @click="handleClickAddLessonHour(weekday)"
              size="mini"
              round
            >
              수업시간 추가
              <i class="el-icon-plus"></i>
            </el-button>
          </li>
        </ul>
      </form-block>
      <!-- 체크인 시간 세팅 -->
      <form-block
        :index="studioPolicies.weekly_waiting_limit ? (!this.originalCourse.id ? 8 : 7) : !this.originalCourse.id ? 7 : 6"
        v-if="showEnterTime && (studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        :inputInline="true"
        class="wrap-enter"
        label="체크인 가능 시간"
      >
        <AvailableHoursInput
          style="margin-right: 20px"
          type="R"
          :relativeStart="course.enter_start_deadline"
          relativeStartPropName="enter_start_deadline"
          :relativeEnd="course.enter_start_deadline"
          relativeEndPropName="enter_start_deadline"
          :setMaxTime="5"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix">수업 시작 </span>
          <span slot="suffix"> 부터 </span>
        </AvailableHoursInput>
        <AvailableHoursInput
          type="R"
          :relativeStart="course.enter_end_deadline"
          relativeStartPropName="enter_end_deadline"
          :relativeEnd="course.enter_end_deadline"
          relativeEndPropName="enter_end_deadline"
          :afterText="true"
          :setMaxTime="5"
          @change="handleAvailableHoursChange"
        >
          <span slot="prefix">수업 종료 </span>
          <span slot="suffix"> 까지 체크인이 가능합니다.</span>
        </AvailableHoursInput>
      </form-block>
      <!-- 예약 필수 수업 체크 -->
      <form-block
        v-if="showEnterTime && (studio.grade === 2 || studio.usable_enter) && studioPolicies.is_enter"
        :index="studioPolicies.weekly_waiting_limit ? (!this.originalCourse.id ? 9 : 8) : !this.originalCourse.id ? 8 : 7"
        label="예약 필수 수업"
      >
        <el-checkbox
          v-model="course.is_booking_only"
          :true-label="1"
          :false-label="0"
          label="사용함"
          slot="checkbox"
        ></el-checkbox>
      </form-block>
    </section>

    <bottom-action-bar :message="lastUpdatedAt" :alert="alertMessage">
      <el-button v-loading="isSaving" :disabled="isSaving" @click="saveLesson">{{
        !originalCourse.id ? '수업 등록 완료' : '수업 수정 완료'
      }}</el-button>
    </bottom-action-bar>

    <el-dialog
      id="overlapping-lectures"
      class="overlapping-lectures"
      :title="`수업 ${!course.id ? '생성' : '수정'} 실패`"
      :visible="!!overlappingLectures.length"
      @close="overlappingLectures = []"
    >
      <p class="overlapping-lectures__result">{{ overlappingLectures.length }}개의 수업과 일정이 중복됩니다.</p>

      <li class="overlapping-lectures__list-header">
        <span></span>
        <span>수업명</span>
        <span>수업일</span>
        <span>수업시간</span>
        <span>강사</span>
        <span>룸</span>
      </li>

      <ul class="overlapping-lectures__list">
        <li class="overlapping-lectures__list-item" v-for="lecture in overlappingLectures" :key="lecture.id">
          <i class="el-icon-error"></i>
          <a
            :href="`/lecture/detail?id=${lecture.id}`"
            :style="!lecture.isLecture && `pointer-events: none;`"
            target="_blank"
            rel="noopenner noreferrer"
            >{{ lecture.title }}</a
          >
          <span>{{ lecture.lectureDate }}</span>
          <span>{{ lecture.lectureTime }}</span>
          <span :class="{ highlight: lecture.instructorOverlapped }">
            {{ lecture.staff ? `${lecture.staff.name} 강사` : '' }}
          </span>
          <span :class="{ highlight: lecture.roomOverlapped }">
            {{ lecture.room ? `${lecture.room.name} 룸` : '' }}
          </span>
        </li>
      </ul>

      <div slot="footer" class="overlapping-lectures__buttons">
        <el-button @click="overlappingLectures = []" type="primary">
          확인
        </el-button>
      </div>
    </el-dialog>
  </MainLayout>
</template>

<script>
import AvailableHoursInput from '@/components/Settings/AvailableHoursInput';

const DEFAULT_DEADLINES = {
  rule_type: 'R',
  group_booking_rule_type: 'R',
  group_booking_cancel_rule_type: 'R',
  autoclose_deadline: 0,
  daily_booking_change_deadline: 0,
  group_booking_startline: null,
  group_booking_deadline: 0,
  group_booking_start_days: null,
  group_booking_start_time: null,
  group_booking_end_days: null,
  group_booking_end_time: null,
  group_booking_cancel_startline: null,
  group_booking_cancel_deadline: 0,
  group_booking_cancel_start_days: null,
  group_booking_cancel_start_time: null,
  group_booking_cancel_end_days: null,
  group_booking_cancel_end_time: null,
  enter_start_deadline: 0,
  enter_end_deadline: 0,
  is_booking_only: 1,
};

export default {
  components: { AvailableHoursInput },

  data() {
    const { moment } = this;

    return {
      isSaving: false,
      instructors: [],
      rooms: [],
      divisions: [],
      daterangePickerOptions: {
        disabledDate(date) {
          const aMonthAgo = moment().subtract(1, 'months');
          const aYearLater = moment().add(1, 'year');
          return !moment(date).isBetween(aMonthAgo, aYearLater, null, '[]');
        },
      },
      weekdays: ['월', '화', '수', '목', '금', '토', '일'],
      routeFromLectureId: null,
      fieldNames: {
        title: '수업명',
        instructor_id: '담당 강사',
        description: '수업 소개',
        max_trainee: '최대 수강 인원',
        min_trainee: '최소 수강 인원',
        room_id: '룸',
        division_id: '수업 구분',
        rule_type: '예약/취소 가능 시간 기준',
        group_booking_rule_type: '예약 가능 시간 기준',
        group_booking_startline: '예약 가능 시간',
        group_booking_deadline: '예약 가능 시간',
        group_booking_start_days: '예약 가능 시간',
        group_booking_start_time: '예약 가능 시간',
        group_booking_end_days: '예약 가능 시간',
        group_booking_end_time: '예약 가능 시간',
        group_booking_cancel_rule_type: '취소 가능 시간 기준',
        group_booking_cancel_startline: '취소 가능 시간',
        group_booking_cancel_deadline: '취소 가능 시간',
        group_booking_cancel_start_days: '취소 가능 시간',
        group_booking_cancel_start_time: '취소 가능 시간',
        group_booking_cancel_end_days: '취소 가능 시간',
        group_booking_cancel_end_time: '취소 가능 시간',
        autoclose_deadline: '폐강 시간',
        daily_booking_change_deadline: '당일 예약 변경 가능 시간',
        waiting_trainee_limit: '예약 대기 가능 인원',
        enter_start_deadline: '체크인 시작 시간',
        enter_end_deadline: '체크인 종료 시간',
        is_booking_only: '예약 필수 수업',
        booking_auto_shift_deadline: '예약대기 자동 예약 시간',
      },

      //선택 옵션
      deadlineTypeOptions: [
        { value: 'A', label: '수업일 기준 - 마감시간' },
        { value: 'AR', label: '수업일 기준 - 시작시간부터 마감시간까지' },
        { value: 'R', label: '수업시간 기준 - 마감시간' },
        { value: 'RR', label: '수업시간 기준 - 시작시간부터 마감시간까지' },
      ],

      /** 수업데이터 - 공통 */
      course: {
        active: 1,
        coupon_deduction: 1,
        type: 'G',
        title: '',
        instructor_id: null,
        room_id: null,
        description: '',
        max_trainee: 4,
        min_trainee: 0,
        division_id: null,
        waiting_trainee_limit: 0,
        ...DEFAULT_DEADLINES,
      },

      /** 수업데이터 - 생성시 */
      daterange: [
        moment().format('YYYY-MM-DD'),
        moment()
          .add(3, 'month')
          .format('YYYY-MM-DD'),
      ],
      lessonHours: { 월: [], 화: [], 수: [], 목: [], 금: [], 토: [], 일: [] },
      overlappingLectures: [],

      /** 수업데이터 - 수정시 */
      originalCourse: {},
    };
  },

  computed: {
    studio() {
      return this.$store.getters['studio/studio'];
    },

    breadcrumb() {
      return {
        parent: { path: '/schedule', label: '일정' },
        current_route_label: this.title,
      };
    },

    title() {
      return !this.originalCourse.id ? '그룹 수업 만들기' : '그룹 수업 수정하기';
    },

    /** 강사 목록 */
    filteredInstructors() {
      if (!this.instructors.length) return [];

      if (!this.canCreateOthersGroupLecture) {
        return this.instructors.filter(({ id }) => id === this.currentUser.id);
      } else if (!this.canCreateMyGroupLecture) {
        return this.instructors.filter(({ id }) => id !== this.currentUser.id);
      }

      return this.instructors;
    },

    lessonHoursCount() {
      const { weekdays, lessonHours } = this;
      return weekdays.reduce((count, weekday) => {
        return count + lessonHours[weekday].length;
      }, 0);
    },

    invalidLessonHoursCount() {
      const { weekdays, lessonHours } = this;
      return weekdays.reduce((count, weekday) => {
        return count + lessonHours[weekday].filter(({ start, end }) => !start || !end || start >= end).length;
      }, 0);
    },

    updatedFields() {
      if (!this.originalCourse.id) return [];
      return Object.keys(this.course).filter(key => this.course[key] !== this.originalCourse[key]);
    },

    lastUpdatedAt() {
      const date = this.originalCourse.updated_at || this.originalCourse.created_at;
      if (!date) return null;

      const suffix = this.originalCourse.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },

    alertMessage() {
      if (!this.originalCourse.id) return '';
      return '*수정시각 이후 수업을 대상으로 일괄 수정';
    },

    useRooms() {
      return _.get(this.studioPolicies, 'is_use_rooms');
    },

    showEnterTime() {
      return _.get(this.studioPolicies, 'is_enter');
    },
  },

  watch: {
    'course.max_trainee'(newValue) {
      if (newValue > 255) {
        this.course.max_trainee = 255;
      }
    },
  },

  /** 수업 생성/수정 권한 체크 */
  beforeRouteEnter(to, from, next) {
    next(vm => {
      let authorized = false;
      if (from.query.id) {
        // 수정을 위한 진입시
        authorized = vm.canUpdateMyGroupLecture || vm.canUpdateOthersGroupLecture;
        vm.routeFromLectureId = from.query.id;
      } else {
        // 생성을 위한 진입시
        authorized = vm.canCreateMyGroupLecture || vm.canCreateOthersGroupLecture;
      }

      if (!authorized) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/schedule');
      }

      next();
    });
  },

  async created() {
    /*초기 날짜 세팅 */
    if (this.$route.query) {
      const { start_on } = this.$route.query;
      const resultDate = [
        this.moment(start_on).format('YYYY-MM-DD'),
        this.moment(start_on)
          .add(3, 'month')
          .format('YYYY-MM-DD'),
      ];
      this.daterange = resultDate;
    }
    /** 강사 목록 */
    try {
      const res = await this.$api.staff.getAll();
      this.instructors = res.data.staffs.map(staff => ({
        id: staff.id,
        name: staff.profile.name,
      }));
    } catch (error) {
      this.instructors = [];
    }

    /** 수업구분 목록 */
    try {
      const res = await this.$api.studio.divisions.get();
      this.divisions = res.data;
    } catch (error) {
      this.divisions = [];
    }

    /** 룸 목록 */
    try {
      const res = await this.$api.studio.rooms.get();
      this.rooms = res.data;
    } catch (error) {
      this.rooms = [];
    }

    /** 초기값 세팅 */
    const { id, staff_id, instructor_id, room_id } = this.$route.query;

    if (staff_id) this.course.instructor_id = parseInt(staff_id) || null;
    if (instructor_id) this.course.instructor_id = parseInt(instructor_id) || null;
    if (room_id) this.course.room_id = parseInt(room_id) || null;
    if (id) {
      try {
        const res = await this.$api.course.get(id);
        this.originalCourse = { ...res.data.course };
        this.course = { ...res.data.course };
      } catch (error) {
        const checkTimeout = error.message.includes('Lock wait timeout');
        if (checkTimeout) this.$utils.notify.error(this, '오류', '요청한 데이터량이 너무 많습니다. 기간, 횟수를 줄여주세요.');
        else this.$utils.notify.parseError(this, error);
      }
    } else {
      this.course = {
        ...this.course,
        rule_type: this.studioPolicies.rule_type,

        group_booking_rule_type: this.studioPolicies.group_booking_rule_type,
        group_booking_cancel_rule_type: this.studioPolicies.group_booking_cancel_rule_type,
        autoclose_deadline: this.studioPolicies.autoclose_deadline || 0,
        daily_booking_change_deadline: this.studioPolicies.daily_booking_change_deadline || 0,
        group_booking_startline: this.studioPolicies.group_booking_startline,
        group_booking_deadline: this.studioPolicies.group_booking_deadline,
        group_booking_start_days: this.studioPolicies.group_booking_start_days,
        group_booking_start_time: this.studioPolicies.group_booking_start_time,
        group_booking_end_days: this.studioPolicies.group_booking_end_days,
        group_booking_end_time: this.studioPolicies.group_booking_end_time,
        group_booking_cancel_startline: this.studioPolicies.group_booking_cancel_startline,
        group_booking_cancel_deadline: this.studioPolicies.group_booking_cancel_deadline,
        group_booking_cancel_start_days: this.studioPolicies.group_booking_cancel_start_days,
        group_booking_cancel_start_time: this.studioPolicies.group_booking_cancel_start_time,
        group_booking_cancel_end_days: this.studioPolicies.group_booking_cancel_end_days,
        group_booking_cancel_end_time: this.studioPolicies.group_booking_cancel_end_time,
        enter_start_deadline: this.studioPolicies.enter_start_deadline || 0,
        enter_end_deadline: this.studioPolicies.enter_end_deadline || 0,
        booking_auto_shift_deadline: this.studioPolicies.booking_auto_shift_deadline || 0,
      };
    }
    if (!this.canCreateOthersGroupLecture) this.course.instructor_id = this.currentUser.id;
    if (!this.instructors.some(({ id }) => id == this.course.instructor_id)) this.course.instructor_id = null;
  },

  methods: {
    /** 종료 시간 설정 */
    setEndTime(weekday, index, duration) {
      const [hour, minute] = this.lessonHours[weekday][index].start.split(':');
      const endTime = this.moment()
        .hour(hour)
        .minute(minute)
        .add(duration, 'minutes')
        .format('HH:mm');
      this.lessonHours[weekday][index].end = endTime;
    },

    viewRuleType(type) {
      const labels = {
        R: '수업시간 기준 - 마감시간',
        RR: '수업시간 기준 - 시작시간부터 마감시간까지',
        A: '수업일 기준 - 마감시간',
        AR: '수업일 기준 - 시작시간부터 마감시간까지',
      };

      return labels[type];
    },

    handleAvailableHoursChange(value) {
      this.course = { ...this.course, ...value };
    },

    /** 요일 선택시 */
    handleClickWeekday(weekday) {
      if (!this.lessonHours[weekday].length) {
        this.handleClickAddLessonHour(weekday);
      } else {
        this.lessonHours[weekday] = [];
      }
    },

    /** 수업시간 추가시 */
    handleClickAddLessonHour(weekday) {
      const lessonHours = this.lessonHours[weekday];
      if (!lessonHours.length) {
        this.lessonHours[weekday].push({ start: '09:00', end: '10:00' });
      } else {
        const { startTime, endTime } = this.$utils.getNextTimeRange(lessonHours[lessonHours.length - 1]);
        const dayOverLimit = this.$utils.checkDayOverLimit(endTime);
        if (!startTime) return;
        if (dayOverLimit) {
          this.$utils.notify.error(this, '오류', '종료시간을 24:00로 사용하실 수 없습니다.');
          return;
        }

        this.lessonHours[weekday].push({ start: startTime, end: endTime });
      }
    },

    /** 수업시간 삭제시 */
    handleClickRemoveLessonHour(weekday, index) {
      this.lessonHours[weekday].splice(index, 1);
    },

    /** 일괄적용 클릭시 */
    handleClickApplyAll(baseWeekday, index) {
      const base = this.lessonHours[baseWeekday][index];
      const oldLessonHours = { ...this.lessonHours };
      this.weekdays.forEach(weekday => {
        if (oldLessonHours[weekday][index]) {
          oldLessonHours[weekday][index] = { ...base };
        }
      });

      this.lessonHours = { ...oldLessonHours };
    },

    validate() {
      const timeCheck = this.$utils.checkBookingTime(
        this.course,
        null,
        this.course.group_booking_rule_type,
        null,
        this.course.group_booking_cancel_rule_type,
      );
      if (!this.course.title) {
        this.$utils.notify.error(this, '오류', '수업명을 입력해주세요.');
        this.$refs.courseTitle.$el.querySelector('input').focus();
        return false;
      } else if (!this.course.instructor_id) {
        this.$utils.notify.error(this, '오류', '담당강사를 선택해주세요.');
        this.$refs.instructorId.$el.querySelector('input').focus();
        return false;
      } else if (isNaN(this.course.max_trainee)) {
        this.$utils.notify.error(this, '오류', '최대 수강 인원을 입력해주세요.');
        this.$refs.maxTrainee.$el.querySelector('input').focus();
        return false;
      } else if (isNaN(this.course.min_trainee)) {
        this.$utils.notify.error(this, '오류', '최소 수강 인원을 입력해주세요.');
        this.$refs.minTrainee.$el.querySelector('input').focus();
        return false;
      } else if (isNaN(this.course.waiting_trainee_limit)) {
        this.$utils.notify.error(this, '오류', '예약 대기 가능 인원을 입력해주세요.');
        this.$refs.waitingTraineeLimit.$el.querySelector('input').focus();
        return false;
      } else if (!this.originalCourse.id && !this.lessonHoursCount) {
        this.$utils.notify.error(this, '오류', '수업 시간을 입력해주세요.');
        this.$refs.schedules.$el.scrollIntoView({ behavior: 'smooth' });
        return false;
      } else if (!this.originalCourse.id && !!this.invalidLessonHoursCount) {
        this.$utils.notify.error(this, '오류', '잘못 입력된 수업 시간이 있습니다.');
        this.$refs.schedules.$el.scrollIntoView({ behavior: 'smooth' });
        return false;
      } else if (timeCheck === 'groupBookingTimeError') {
        this.$utils.notify.error(this, '그룹 수업 예약 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
        return false;
      } else if (timeCheck === 'groupCancelTimeError') {
        this.$utils.notify.error(this, '그룹 수업 예약 취소 가능 시간', '시작 시간이 종료 시간보다 빨라야 합니다.');
        return false;
      } else if (this.originalCourse.id && !this.updatedFields.length) {
        return this.redirectToLecture();
      }

      return true;
    },

    async saveLesson() {
      if (!this.validate()) return;

      this.isSaving = true;

      /** 룸 설정 확인 */
      if (this.useRooms && !this.course.room_id) {
        const title = '룸 설정';
        const message = '룸이 설정되지 않았습니다.<br>계속 하시겠습니까?';
        const proceed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!proceed) {
          this.isSaving = false;
          return;
        }
      }

      /** 최소 수강 인원 확인 */
      if (this.course.min_trainee !== 0) {
        const title = '최소 수강 인원 확인';
        const message = `최소 수강 인원 (${this.course.min_trainee ||
          0}명) 이 채워지지 않으면 이 수업은 자동으로 삭제되고 예약은 자동 취소됩니다.`;

        const checkMinTrainee = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!checkMinTrainee) {
          this.isSaving = false;
          return this.$refs.minTrainee.$el.querySelector('.el-input__inner').focus();
        }
      }

      /** 수정시 일괄 적용 경고 */
      if (this.originalCourse.id) {
        const updatedFields = _.uniq(this.updatedFields.map(key => this.fieldNames[key])).join(', ');

        const title = '클래스 수정';
        const message = `
            변경된 항목: <b>${updatedFields}</b><br>
            변경된 항목이 모든 수업에 적용됩니다.<br>
            계속 하시겠습니까?
          `;

        const confirmed = await this.$confirm(message, title, {
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (!confirmed) {
          this.isSaving = false;
          return;
        }

        this.updateCourse();
      } else {
        this.createCourse();
      }
    },

    async createCourse() {
      const course = {
        ...this.course,
        min_trainee: this.course.min_trainee || 0,
        waiting_trainee_limit: this.course.waiting_trainee_limit || 0,
        start_date: this.moment(this.daterange[0]).format('YYYY-MM-DD'),
        end_date: this.moment(this.daterange[1]).format('YYYY-MM-DD'),
        schedules: this.flattenSchedule(),
      };

      /** 중복 수업 확인 */
      this.overlappingLectures = await this.getOverlappingLectures(course);
      if (this.overlappingLectures.length) {
        this.isSaving = false;
        return;
      }

      try {
        const res = await this.$api.course.create(course);
        this.$utils.notify.success(this, '성공', '수업이 생성되었습니다.');
        this.$router.push(`/lecture/detail?id=${res.data.first_lecture_id}`);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSaving = false;
      }
    },

    async updateCourse() {
      let course = {};
      this.updatedFields.forEach(key => (course[key] = this.course[key]));
      course = this.$utils.replaceNullWithMinusOne(course);

      const instructorOrRoomUpdated = ['instructor_id', 'room_id'].some(key => this.updatedFields.includes(key));

      if (instructorOrRoomUpdated) {
        /** 중복 수업 확인 */
        this.overlappingLectures = await this.getOverlappingLectures({
          ...this.course,
          ...course,
        });
        if (this.overlappingLectures.length) {
          this.isSaving = false;
          return;
        }
      }

      try {
        await this.$api.course.update({
          ...course,
          id: this.originalCourse.id,
        });
        this.$utils.notify.success(this, '성공', '수정되었습니다.');
        this.redirectToLecture();
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isSaving = false;
      }
    },

    redirectToLecture() {
      let lectureId = this.routeFromLectureId || this.originalCourse.lectures[0].id;
      this.$router.push(`/lecture/detail?id=${lectureId}`);
    },

    flattenSchedule() {
      let schedule = [];
      this.weekdays.forEach(weekday => {
        this.lessonHours[weekday].forEach(({ start, end }) => {
          const weekdayNumber = this.$utils.translate.weekdayText(weekday);
          const duplicate = schedule.find(item => {
            return item.weekday === weekdayNumber && item.start_time === start && item.end_time === end;
          });
          if (!duplicate) {
            schedule.push({
              weekday: weekdayNumber,
              start_time: start,
              end_time: end,
            });
          }
        });
      });
      return schedule;
    },

    /** 시간, 강사, 룸 겹치는 수업 반환 */
    async getOverlappingLectures(course) {
      const { start_date, end_date, schedules, instructor_id, room_id, id } = course;

      try {
        const resAll = await Promise.all([
          ...schedules.map(({ weekday, start_time, end_time }) => {
            const params = {
              start_date,
              end_date,
              week_day: weekday,
              period_time: `${start_time.slice(0, 5)},${end_time.slice(0, 5)}`,
              staff_room_id: `${instructor_id},${room_id}`,
            };
            return this.$api.lecture.getAll({ ...params, with: 'staff.avatars;staff.profile' });
          }),
          ...schedules.map(({ weekday, start_time, end_time }) => {
            const params = {
              staff_id: instructor_id,
              start_date,
              end_date,
              weekday: [weekday],
              start_time: `${start_time}:00`,
              end_time: `${end_time}:00`,
            };
            return this.$api.etcSchedule.getCheck(params);
          }),
        ]);

        const reduced = resAll.reduce((sum, res) => {
          const filtered = res.data.filter(({ course_id, start_on }) => {
            const isThisCourse = course_id && course_id === id;
            const isFutureLecture = this.moment().isBefore(start_on);
            return !isThisCourse && isFutureLecture;
          });
          return [...sum, ...filtered];
        }, []);
        return this.appendAdditionalInfo(reduced, instructor_id, room_id);
      } catch (error) {
        return [];
      }
    },

    /** 수업시간, 중복되는 항목 추가 */
    appendAdditionalInfo(lectures, instructorId, roomId) {
      return lectures.map(lecture => {
        const { start_on, end_on } = lecture;
        const date = this.$filters.dateKorean(start_on);
        const weekday = this.moment(start_on).format('(ddd)');
        const startOn = this.moment(start_on).format('HH:mm');
        const endOn = this.moment(end_on).format('HH:mm');
        const isLecture = !!lecture.course_id;
        const staff = lecture.staff
          ? lecture.staff
          : !lecture.staffs.length && lecture.register
          ? lecture.register
          : lecture.staffs.find(({ id }) => id === instructorId);

        return {
          ...lecture,
          staff,
          lectureDate: `${date} ${weekday}`,
          lectureTime: `${startOn}~${endOn}`,
          instructorOverlapped: staff.id === instructorId,
          roomOverlapped: lecture.room && lecture.room.id === roomId,
          isLecture,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-group-lesson {
  width: 100%;
  max-width: 1120px;
  margin: auto;
  padding: 10px 10px 60px;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }

  &__group {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }

  &__type-viewer {
    margin-right: 10px;
    width: 238px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    height: 32px;
    width: 238px;
    text-align: center;
    font-size: 12px;
    color: $gray-500;
    line-height: 32px;

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 32px;
      width: 238px;
      padding: 0 15px 0 0;
      text-align: center;
      font-size: 12px;
      color: $gray-500;
      &:hover {
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }

  &__type-selector {
    padding-right: 10px;
    /deep/ .el-select {
      width: 238px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 32px;
      width: 238px;
      padding: 0 15px 0 0;
      text-align: center;
      font-size: 12px;
      color: $gray-500;
      &:hover {
        color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }

  &__basic-info {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'instructor instructor'
      'title title'
      'description description'
      'max-trainee min-trainee'
      'alert alert';
    width: 100%;
    max-width: 600px;

    @include mq(768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'title title instructor .'
        'description description description .'
        'max-trainee min-trainee . .'
        'alert alert alert alert';
    }

    & > div {
      @include flex(column);

      label {
        font-size: 12px;
        font-weight: 500;
      }
    }

    &__course-title {
      grid-area: title;
    }
    &__instructor {
      grid-area: instructor;
    }
    &__description {
      grid-area: description;
    }
    &__max-trainee {
      grid-area: max-trainee;
    }
    &__min-trainee {
      grid-area: min-trainee;
    }
    &__alert {
      grid-area: alert;
      color: $color-danger;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__waiting-trainee-limit {
    .custom-number-input {
      max-width: 200px;
    }

    p {
      color: $dodger-blue;
      font-size: 12px;
      font-weight: bold;
      margin-top: 12px;
    }
  }

  &__room {
    /deep/ .el-select {
      width: 100%;
      max-width: 200px;
    }
  }

  &__division {
    p {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  &__rule-type {
    align-self: flex-start;
    background: $deep-sky-blue;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 16px;
    padding: 7px 14px;
    width: auto;
  }

  &__deadlines {
    p {
      @include flex(row, center, flex-start);
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  &__daterange {
    @include flex(column);

    label {
      font-size: 12px;
      font-weight: 500;
    }

    /deep/ .el-input__inner {
      width: 100%;
      max-width: 280px;
    }
  }

  &__weekday-buttons {
    @include flex(row);
    padding: 12px 0;

    li + li {
      margin-left: 5px;
    }
  }

  &__lesson-hour-list {
    width: 100%;
    max-width: 580px;

    li {
      display: grid;
      align-items: center;
      grid-template-columns: 50px 1fr auto auto auto auto;
      margin-bottom: 12px;

      h5 {
        font-size: 13px;
        font-weight: 300;
      }
    }

    &__button {
      grid-column: 2 / 3;
      max-width: 130px;
      margin-left: 20px;
    }
  }
}

.overlapping-lectures {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 720px;
  }

  &__result {
    font-weight: bold;
    opacity: 0.6;
  }

  &__list {
    @include flex(column);
    margin-top: 5px;
    max-height: 40vh;
    overflow-y: auto;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 20px 2fr 3fr repeat(3, 2fr);
      place-items: center;
      padding-bottom: 8px;
    }

    &-header {
      border-bottom: 1px solid #ebebeb;
      margin-top: 8px;
      font-weight: bold;
    }

    &-item {
      i {
        color: $color-danger;
      }

      a,
      span {
        @include ellipsis;
        text-align: center;
        width: 100%;
      }

      span.highlight {
        color: $color-danger;
        font-weight: bold;
      }
    }
  }
}

.wrap-enter {
  /deep/ p {
    font-size: 16px;
    display: inline;
    color: $gray-600;
  }
}
</style>
