<template>
  <el-dialog
    class="payment-result-dialog"
    :visible="show"
    :title="paymentSuccess ? '결제 성공' : '결제 실패'"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div v-if="paymentSuccess" class="payment-result-dialog__body">
      <label>결제수단</label>
      <p class="payment-method">
        <span v-for="method in paymentMethod" :key="method">
          {{ method }}
        </span>
      </p>
      <label>결제금액</label>
      <span>
        {{ paymentAmount | comma }}원
        <span v-if="showInstallment">/ 할부 {{ payload.installment }}개월</span>
      </span>
    </div>

    <div v-else class="payment-result-dialog__fail">
      <span>결제 실패 하였습니다. <br /></span>
      <span>실패코드 : {{ paymentResultCode }} ({{ errorMessage }}) </span>
    </div>

    <div slot="footer">
      <BaseButton @click="$emit('close')" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    payload: { type: Object, default: null },
    smsCount: { type: Number, default: 0 },
  },

  computed: {
    paymentSuccess() {
      return _.get(this.payload, 'success');
    },

    paymentMethod() {
      return _.get(this.payload, 'paymentMethod', '').split(' ');
    },

    errorMessage() {
      return _.get(this.payload, 'errorMessage', '');
    },

    paymentAmount() {
      return _.get(this.payload, 'paymentAmount', 0);
    },

    paymentResultCode() {
      return _.get(this.payload, 'paymentResultCode');
    },

    showInstallment() {
      return this.payload.installment > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-result-dialog {
  /deep/ .el-dialog {
    width: 95%;
    max-width: 320px;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }

  /deep/ .el-dialog__footer {
    @include flex(row, center, center);
  }

  &__body {
    display: grid;
    grid-template-columns: 8rem 1fr;
    grid-gap: 20px;
    align-items: center;

    .payment-method {
      @include flex(column, end, end);
    }
    label {
      font-size: 13px;
    }
    span {
      font-size: 13px;
      font-weight: bold;
      text-align: right;
    }
  }

  &__fail {
    span {
      font-size: 13px;
    }
  }
}
</style>
