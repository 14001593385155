<template>
  <section class="carousel">
    <button v-if="hasPrev" class="carousel-btn-prev" @click="handleClickToPrev"></button>
    <ul :class="`carousel-item-container flex-row ${isEditing ? 'isEditing' : ''}`">
      <li v-for="(item, itemKey) in itemList" :key="itemKey">
        <div @click="handleClickCard(item)" :class="getClassNames(item)">
          <!--                <p v-if="item.class === 'addCard'"><pre>{{ item.nick_name || item.card_name }}</pre></p>-->
          <p class="title">{{ item.nick_name || item.card_name }}</p>
          <p v-if="item.class !== 'addCard' && item.card_number" class="sub-title">{{ `(${getLastFourDigits(item)})` }}</p>
          <button v-if="isEditing && !item.label && item.class !== 'addCard'" @click="() => handleClickDeleteCard(item.id)">
            카드 삭제
          </button>
          <label v-if="item.label">{{ item.label }}</label>
          <label v-if="!isEditing && !item.label && isDeprecatedPay(item)">더 이상 결제할 수 없는 카드입니다.</label>
        </div>
        <el-select
          v-if="showInstallmentSelection(item)"
          class="carousel-installment-selection"
          v-model="installmentId"
          ref="installmentId"
          :disabled="!!item.card_type || !isInstallmentCondition"
        >
          <el-option
            v-for="installmentOption in installmentOnCard(item)"
            :key="installmentOption.value"
            :value="installmentOption.value"
            :label="installmentOption.name"
          />
        </el-select>
      </li>
    </ul>
    <button v-if="hasNext" class="carousel-btn-next" @click="handleClickToNext"></button>
  </section>
</template>

<script>
const defaultOption = { name: '일시불', value: 0 };
export default {
  data() {
    return {
      currentItemId: null,
      installmentId: defaultOption.name,
      totalPromotions: [],
    };
  },
  props: {
    itemList: Array,
    handleClickToPrev: Function,
    handleClickToNext: Function,
    isEditing: Boolean,
    hasPrev: Boolean,
    hasNext: Boolean,
    selectedCardId: Number,
    selectedProductPaymentAmount: Number,
    isInstallmentCondition: Boolean,
  },

  computed: {
    type() {
      return this.$store.getters['payment/type'];
    },

    typeText() {
      return this.type === 'point_card_id' ? '포인트 자동 충전 카드' : '구독 결제 카드';
    },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    hasPhoneNumber() {
      return this.type === 'point_card_id'
        ? this.paymentPolicy.point_charging_payment_notification_number
        : this.paymentPolicy.subscription_payment_notification_number;
    },
  },

  watch: {
    currentItemId() {
      this.$emit('handleClickCardItem', this.currentItemId);
      this.initializeInstallment();
    },

    installmentId() {
      if (this.installmentId < 2) return;
      this.$emit('setRequestPaymentData', { installment: this.installmentId });
    },

    selectedProductPaymentAmount() {
      this.initializeInstallment();
    },

    itemList() {
      this.setDefaultCardId();
    },
  },

  created() {
    this.setDefaultCardId();
    this.getCardPromotions();
  },

  methods: {
    async handleClickDeleteCard(id) {
      const {
        usable_point_auto_charge,
        usable_subscription_auto_charge,
        point_card_id,
        subscription_card_id,
      } = this.paymentPolicy;
      if (
        usable_point_auto_charge &&
        usable_subscription_auto_charge &&
        subscription_card_id === point_card_id &&
        point_card_id === id
      ) {
        return this.$utils.notify.error(
          this,
          '오류',
          '서비스 결제 및 포인트 자동 충전 카드로 등록된 경우 삭제가 불가합니다. 결제 유형 변경 또는 결제 수단을 변경해주세요.',
        );
      }
      if (usable_point_auto_charge && point_card_id === id) {
        return this.$utils.notify.error(
          this,
          '오류',
          '포인트 자동 충전 카드로 등록된 경우 삭제가 불가합니다. 자동 충전을 해제 또는 자동 충전 카드를 변경해주세요.',
        );
      }
      if (usable_subscription_auto_charge && subscription_card_id === id) {
        return this.$utils.notify.error(
          this,
          '오류',
          '서비스 이용료 자동 결제 카드로 등록된 경우 삭제가 불가합니다. 서비스 결제 카드를 변경해주세요.',
        );
      }

      const title = ``;
      const message = `카드를 삭제하시겠습니까?`;

      this.$confirm(message, title, {
        confirmButtonText: '삭제',
        dangerouslyUseHTMLString: true,
        customClass: 'carousel-item-container-modal',
        cancelButtonClass: 'cancel-button',
      }).then(async () => {
        const res = await this.$store.dispatch('payment/deletePayment', {
          id,
          caseType: this.type === 'point_card_id' ? 'point' : 'monthly',
        });

        if (res.success) {
          await this.$store.dispatch('payment/getPaymentPolicy');
          await this.$utils.notify.success(this, '성공', '등록된 카드가 삭제되었습니다.');
        } else {
          if (res.error.response.data.status_code === 404) {
            this.$utils.notify.error(this, '오류', '이미 삭제된 카드입니다. 새로고침 후 이용해주세요.');
          } else {
            this.$utils.notify.error(this, '오류', res.error.response.data.message);
          }
        }
      });
    },

    handleClickCard(item) {
      this.currentItemId = item.id;
      if (item.class === 'addCard' && !this.hasPhoneNumber)
        return this.$utils.notify.error(this, '오류', '결제 알림을 받을 휴대폰 번호를 먼저 등록해주세요.');
      if (item.class === 'addCard') return item.event();
      if (item.id === this.selectedCardId) return;

      const {
        usable_point_auto_charge,
        usable_subscription_auto_charge,
        point_card_id,
        subscription_card_id,
      } = this.paymentPolicy;

      if (this.type === 'subscription_card_id') {
        if (!subscription_card_id || !usable_subscription_auto_charge) return;
      }

      if (this.type === 'point_card_id') {
        if (!point_card_id || !usable_point_auto_charge) return;
      }

      if (!this.isEditing) return this.dispatchUpdatePaymentCard(item);
    },

    dispatchUpdatePaymentCard: _.debounce(async function(item) {
      const res = await this.$store.dispatch('payment/updatePaymentPolicy', { [this.type]: item.id });

      if (res.success) {
        this.$utils.notify.success(this, '성공', `${this.typeText}로 등록되었습니다.`);
      } else {
        if (res.error.response.data.status_code === 422) {
          const title = ``;
          const message = `카드를 다시 등록해주세요.`;

          this.$confirm(message, title, {
            showCancelButton: false,
            confirmButtonText: '확인',
            dangerouslyUseHTMLString: true,
            customClass: 'carousel-item-container-modal',
          })
            .then(() => {
              this.$router.go(0);
            })
            .catch(() => {
              this.$router.go(0);
            });
        } else {
          this.$utils.notify.error(this, '오류', res.error.response.data.errors[this.type][0]);
        }
      }
      this.setDefaultCardId();
    }, 250),

    getLastFourDigits({ card_number }) {
      return card_number.slice(-4).replace('X', '*');
    },

    setDefaultCardId() {
      const autoChargingCardId = _.get(this.paymentPolicy, this.type);
      if (
        ((!!this.paymentPolicy.usable_point_auto_charge && this.type === 'point_card_id') ||
          (!!this.paymentPolicy.usable_subscription_auto_charge && this.type === 'subscription_card_id')) &&
        !!autoChargingCardId
      ) {
        this.currentItemId = autoChargingCardId;
        return;
      }

      this.currentItemId =
        (this.itemList[0].class !== 'addCard' && !this.isDeprecatedPay(this.itemList[0]) && this.itemList[0].id) || -1;
    },

    getClassNames(item) {
      let classnames = 'carousel-item';
      if (item.class) {
        classnames += ` ${item.class}`;
      }
      if (this.selectedCardId) {
        classnames += item.id === this.selectedCardId ? ' selected' : '';
      }
      if (this.isDeprecatedPay(item)) {
        classnames += ' disabled';
      }
      return classnames;
    },

    isDeprecatedPay(item) {
      return item.pg_type === 'ks_pay';
    },

    showInstallmentSelection(item) {
      return !this.isEditing && item.class !== 'addCard' && item.id === this.selectedCardId;
    },

    async getCardPromotions() {
      const response = await this.$api.payment.getCardPromotions();
      this.totalPromotions = response.data;
    },

    installmentOnCard(card) {
      const promotion = this.totalPromotions.find(promotion => promotion.company === card.card_name);
      const MAX_INSTALLMENT = 12;

      const options = Array.from(new Array(MAX_INSTALLMENT), (_, index) => {
        if (index === 0) {
          return defaultOption;
        }
        const month = index + 1; // 2 ~ 12개월
        const isFreeMonth =
          promotion &&
          promotion.installmentFreeMonths.includes(month) &&
          promotion.minimalAmount >= this.selectedProductPaymentAmount;

        return {
          name: `${month}개월 ${isFreeMonth ? '(무)' : ''}`,
          value: month,
        };
      });
      return options;
    },

    initializeInstallment() {
      this.$emit('setRequestPaymentData', { installment: 0 });
      this.installmentId = defaultOption.value;
    },
  },
};
</script>

<style scopped lang="scss">
.carousel {
  &-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    position: relative;

    .addCard {
      position: relative;
      background-color: #f8f8f8;
      cursor: pointer;

      p {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #a3a3a3;
        padding: 32px 0px 0px 0px;
        white-space: pre-line;
      }

      &:before {
        position: absolute;
        display: block;
        top: 4px;
        left: calc(50% - 8px);
        content: '+';
        font-size: 36px;
        color: #a3a3a3;
        text-align: center;
      }
    }

    li + li {
      margin: 0px 0px 0px 25px;
    }

    button,
    label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #808080;
      border-top: 1px solid #eee;
      padding: 8.5px 0px 0px 0px;
      margin: 8.5px 15px 0px 15px;
    }

    &.isEditing {
      button {
        color: #ff8d8d;

        &:disabled {
          cursor: default;
          pointer-events: none;
          color: #808080;
        }
      }
    }

    &:not(.isEditing) .disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: #f8f8f8;

      p {
        opacity: 0.5;
      }

      label {
        color: #ff8d8d;
        font-weight: 400;
      }
    }

    li {
      position: relative;
      padding: 0px 0px 80px 0px;
    }

    &:not(.isEditing):not(.addCard) {
      .selected {
        background: $dodger-blue;
        border: none;
        cursor: default;
        pointer-events: none;

        .title {
          color: $white;
          font-weight: bold;
        }

        .sub-title {
          color: $white;
          font-weight: 500;
        }

        label {
          color: $white;
          font-weight: 500;
          cursor: default;
          pointer-events: none;
        }
      }
    }

    &-modal {
      position: relative;
      width: 430px !important;
      height: 243px !important;

      .el-message-box {
        &__content {
          display: block;
          position: relative;
          padding: 105px 30px 20px 30px;
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          letter-spacing: normal;
          text-align: left;
          color: $charcoal-grey;

          &:before {
            content: '';
            width: 90px;
            height: 90px;
            position: absolute;
            top: 0;
            left: 30px;
            background: url('../../assets/images/icons/icon_warning-red.svg') 0 0 no-repeat;
            background-size: cover;
          }
        }

        &__btns {
          padding: 15px 30px 0px;
          button {
            width: 64px;
            height: 32px;

            &.cancel-button {
              border: 1px solid #8a8a8a;
              color: rgba(138, 138, 138, 0.9);
            }
          }
        }

        &__headerbtn {
          top: 30px;
          right: 30px;
          z-index: 1;
        }
      }
    }
  }

  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 230px;
    height: 100px;
    border-radius: 4px;
    border: solid 1px #eee;
    background-color: $white;

    .title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #808080;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #a3a3a3;
      padding: 5px 0px 0px 0px;
    }
  }

  &-btn-prev {
    position: absolute;
    width: 35px;
    height: 35px;
    background: url('../../assets/images/icons/round-arrow-prev.svg') 0 0 no-repeat;
    background-size: cover;
  }

  &-btn-next {
    position: absolute;
    width: 35px;
    height: 35px;
    background: url('../../assets/images/icons/round-arrow-next.svg') 0 0 no-repeat;
    background-size: cover;
  }

  &-installment-selection {
    display: block;
    position: absolute;
    margin: 15px 0px 0px 0px;
    width: 100%;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #e9ecef;

    input[type='text'] {
      border-bottom: none;
      padding: 0px 0px 0px 15px;
    }
  }
}
</style>
