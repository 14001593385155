<template>
  <section class="wrap-serviceplan" v-loading.fullscreen="loading">
    <h4>{{ servicePlanTitle }}</h4>
    <hr />

    <RadioGroup :value="currentProductPeriod" @input="handleChangeProductPeriod" :options="planOptions" />

    <div class="products">
      <el-card
        v-for="(product, productKey) in products"
        :key="productKey"
        :shadow="selectedProductId === product.id ? 'always' : 'hover'"
      >
        <div
          class="product"
          v-if="product"
          :class="{ active: selectedProductId === product.id }"
          @click="handleClickProduct(product)"
        >
          <div class="title">{{ product.grade.toUpperCase() }}</div>
          <div class="info">
            <p v-html="product.context"></p>
          </div>
          <div v-if="currentProductPeriod === 'yearly'" class="origin">{{ product.original_price | comma }} 원</div>
          <div class="price">{{ product.actual_price | comma }}원</div>
          <p class="tax">(부가세 포함)</p>
        </div>
      </el-card>
    </div>

    <hr />

    <div :class="`wrap-payment ${currentProductPeriod}`">
      <div class="wrap-title notification" v-if="currentProductPeriod === 'monthly'">
        <span>
          <h5>결제 알림</h5>
          <p class="wrap-payment__input-box__sub">결제 실패 시 카카오톡 혹은 문자가 발송됩니다.</p>
        </span>
        <span>
          <PhoneNumberInput
            v-model="chargeNotificationNumber"
            placeholder="휴대폰 번호를 입력해 주세요"
            :class="phoneNumberInputClass"
          />
          <p class="warning" v-if="phoneNumberErrorMessage">{{ phoneNumberErrorMessage }}</p>
          <button @click="handleClickChangeChargeNotificationNumber" :class="phoneNumberButtonClass">저장하기</button>
        </span>
      </div>
      <div class="wrap-title card" v-if="currentProductPeriod === 'monthly'">
        <h5>결제 카드</h5>
        <button class="btn-text-blue" @click="() => (isEditing = !isEditing)">
          {{ editButtonText }}
        </button>
      </div>
      <p v-if="currentProductPeriod === 'monthly'">정기 결제 사용 중인 카드는 삭제할 수 없습니다.</p>
      <div class="wrap-serviceplan__payment-list" v-if="currentProductPeriod === 'monthly'">
        <PaymentList
          :isEditing="isEditing"
          :itemList="paymentCarouselList"
          :handleClickToPrev="handleClickToPrev"
          :handleClickToNext="handleClickToNext"
          :hasPrev="hasPrev"
          :hasNext="hasNext"
          :selectedCardId="selectedCardId"
          :isInstallmentCondition="isInstallmentCondition"
          :selectedProductId="selectedProductId"
          @handleClickCardItem="handleClickCardItem"
          @setRequestPaymentData="setRequestPaymentData"
        />
      </div>

      <div class="wrap-title invoice">
        <h5>결제 금액 확인</h5>
        <p v-if="currentProductPeriod === 'monthly'">매달 만료일에 아래 금액으로 결제됩니다.</p>
      </div>
      <div class="info" v-if="selectedProduct">
        <div class="title">
          <span>요금제</span>
          <span class="name-product">{{ selectedProduct.grade.toUpperCase() }}</span>
          <span class="origin" v-if="selectedProduct.original_price != selectedProduct.actual_price"
            >{{ selectedProduct.original_price | comma }}원</span
          >
          <span class="price">{{ selectedProduct.actual_price | comma }}원</span>
        </div>
      </div>
      <div class="info">
        <div class="title">
          <span>이용기간</span>
          <span class="period">{{ isNewContract ? servicePeriodNew : servicePeriodUpdated }}</span>
        </div>
      </div>

      <!-- 요금제 변경 안내 문구 -->
      <div class="txt-updated">
        <TxtChangePayPlan :existPlan="currentProductNumber" :selectedPlan="selectedProduct" :paymentDate="studioExpireDate" />
      </div>
    </div>

    <section class="wrap-btn">
      <div class="area-btn">
        <el-button
          class="toss-setting"
          @click="openBrandPaySetting"
          v-if="hasTossCardForYearlyPay && currentProductPeriod === 'yearly'"
        >
          카드 설정
        </el-button>
        <el-button type="primary" :disabled="preventPayment" @click="agreeUseTerms" class="btn-text-block">{{
          textClickPayButton
        }}</el-button>
      </div>
    </section>
    <CompanyProfile />

    <PaymentConfirm
      :case="resultProductNumber"
      :payPrice="resultProductPrice"
      :expireDate="studioExpireDate"
      :show="showPaymentConfirmDialog"
      :selectedProduct="selectedProduct"
      :isLoading="loading"
      @close="handleCloseConfirmDialog"
      @proceedPayment="proceedPayment"
    />
    <PaymentYearlyResultDialog
      :show="showPaymentYearlyResultDialog"
      :payload="paymentYearlyResultPayload"
      @close="handleCloseResultDialog"
    />
    <PaymentMonthlyResultDialog
      :show="showPaymentMonthlyResultDialog"
      :payload="paymentMonthlyResultPayload"
      @close="handleCloseResultDialog"
    />
    <ChangeSubscriptionResultDialog
      :show="showSubscriptionResultDialog"
      :payload="selectedProduct.title"
      @close="handleClickCloseChangeSubscriptionResultDialog"
    />

    <PGChangeNotice :show="showPGChangeNotice" @close="showPGChangeNotice = false" @registerCard="registerCard" />
    <ContinueMonthlyPaymentDialog
      :show="!!cardAdded"
      :cardAdded="cardAdded"
      @close="cardAdded = null"
      @proceedPayment="continueMonthlyPayment"
    />
    <UseTerms v-if="showUseTerms" :open="showUseTerms" @close="showUseTerms = false" />
  </section>
</template>

<script>
import CompanyProfile from '@components/shared/CompanyProfile';
import PaymentYearlyResultDialog from './PaymentYearlyResultDialog';
import PaymentMonthlyResultDialog from './PaymentMonthlyResultDialog';
import ChangeSubscriptionResultDialog from './ChangeSubscriptionResultDialog';
import ContinueMonthlyPaymentDialog from './ContinueMonthlyPaymentDialog.vue';
import PaymentConfirm from './PaymentConfirmDialog';

import TxtChangePayPlan from './txtChangePayPlan';
import UseTerms from '@components/Modals/UseTerms';
import PGChangeNotice from '@components/Modals/PGChangeNotice';
import { loadTossPayments } from '@tosspayments/tosspayments-sdk';

export default {
  components: {
    CompanyProfile,
    PaymentYearlyResultDialog,
    PaymentMonthlyResultDialog,
    PaymentConfirm,
    TxtChangePayPlan,
    UseTerms,
    ChangeSubscriptionResultDialog,
    ContinueMonthlyPaymentDialog,
    PGChangeNotice,
  },
  props: {
    active_tab: String,
  },
  data() {
    return {
      planOptions: [
        { value: 'yearly', label: '연 결제' },
        { value: 'monthly', label: '월 정기 결제' },
      ],
      currentProductPeriod: 'yearly',
      selectedProduct: {
        id: 0,
        title: '',
        grade: '',
        context: '',
        quantity: 0,
        actual_price: 0,
        original_price: 0,
      },
      paymentMonthlyResultPayload: {},
      paymentYearlyResultPayload: {},
      showPaymentYearlyResultDialog: false,
      showPaymentMonthlyResultDialog: false,
      showPaymentConfirmDialog: false,
      showSubscriptionResultDialog: false,
      cardAdded: null,
      showPGChangeNotice: false,
      showUseTerms: false,
      expireStatus: 'purchase',
      carouselIndex: 0,
      carouselItemNumber: 3,
      isEditing: false,
      loading: false,
      selectedCardId: -1,
      requestPaymentData: {
        installment: 0,
      },
      chargeNotificationNumber: null,
      hasTossCardForYearlyPay: false,
    };
  },
  computed: {
    servicePlanTitle() {
      if (this.isNewContract || this.resultProductNumber === `22` || this.resultProductNumber === `44`) {
        //신규고객 || 연 결제
        return '결제하기';
      }
      return '요금제 변경';
    },
    studioData() {
      return this.$store.getters['studio/studio'];
    },
    customerKey() {
      return this.studioData.customer_key.service;
    },
    studioExpireDate() {
      return this.studioData.expire_on;
    },
    currentProductNumber() {
      // subscription_id 는 마지막으로 주문한 상품을 참조하므로
      // 이용중 상품이 있는데, 월 결제로 변경하고, 마지막 결제 취소하면 반영하지 못함
      // 현재 이용 중인 요금제는 paymentInfo.now_payment 참조

      // 만료일이 지났으면 return ''
      const expired = this.moment().isAfter(this.studioExpireDate);
      if (expired) return '';

      let id = 0;
      let ids = []; // 연 결제 또는 월 결제에 해당하는 id
      if (!this.nowPayment) return '';
      const { product_name, product_type } = this.nowPayment;
      if (!product_name || !product_type) return '';
      ids = product_type.includes('월') ? [1, 3] : [2, 4];
      this.servicePlanProducts.map(product => {
        if (ids.includes(product.id) && product.grade === product_name.toLowerCase()) {
          id = product.id;
        }
      });
      return id || '';
    },
    expireIn() {
      if (this.studioExpireDate === null) return false;
      return this.$utils.getDaysDiff(this.studioExpireDate);
    },
    isNewContract() {
      if (this.expireIn <= 0) return true;
      return false;
    },
    textClickPayButton() {
      const price = this.$filters.comma(this.selectedProduct.actual_price);
      if (this.selectedProductId === 1 || this.selectedProductId === 3) {
        return `${price}원 정기 결제하기`;
      }
      return `${price}원 결제하기`;
    },
    isAgreedTerms() {
      return this.$store.getters['studio/studio'].is_terms;
    },
    isExtendMonthly() {
      return (
        `${this.currentProductNumber}${this.selectedProductId}` === `11` ||
        `${this.currentProductNumber}${this.selectedProductId}` === `33`
      );
    },
    preventPayment() {
      if (this.expireIn < 0) return;
      //결제 버튼 비활성화
      //1.신규가 아니고, 기존 상품이 선택, 2.연결제에서 플러스 또는 베이직으로 변경시, 3.예약된 결제(?)가 있는 경우 disabled
      return (
        this.loading ||
        (this.expireIn >= 0 && this.isExtendMonthly) ||
        this.resultProductNumber === '23' ||
        this.resultProductNumber === '24' ||
        this.resultProductNumber === '42'
        // this.changeCount
        // || this.expireStatus === 'reservation'
      );
    },

    selectedProductId() {
      return _.get(this.selectedProduct, 'id');
    },
    servicePeriodNew() {
      if (this.currentProductPeriod === 'yearly') {
        return this.calcYearMonthAdd('year');
      }
      return this.calcYearMonthAdd('month');
    },
    periodMonth() {
      return `${this.$filters.date(this.moment())} ~ ${this.$filters.date(
        this.moment()
          .add(1, 'month')
          .subtract(1, 'day'),
      )}`;
    },
    servicePeriodUpdated() {
      switch (this.resultProductNumber) {
        case '1':
        case '01':
        case '3':
        case '03':
        case '13':
        case '31':
        case '21':
        case '23':
        case '41':
        case '43':
          return this.calcAdd1Day('month');
        case '2':
        case '02':
        case '4':
        case '04':
        case '12':
        case '14':
        case '32':
        case '34':
        case '22':
        case '44':
          return this.calcAdd1Day('year');
        case '24':
          return `~ ${this.$filters.date(this.studioExpireDate)}`;
        // return this.periodMonth;
        // case '42':
        //   return this.calcYearPLUStoYearBASIC();
        default:
          return '';
      }
    },
    resultProductNumber() {
      return `${this.currentProductNumber}${this.selectedProductId}`;
    },
    resultProductPrice() {
      return this.selectedProduct.actual_price;
    },
    // changeCount() {
    //   return _.get(this.$store.state.monthlyPayment, 'changeCount');
    // },

    paymentPolicy() {
      return this.$store.getters['payment/paymentPolicy'];
    },

    subscriptionCardId() {
      return this.paymentPolicy.subscription_card_id;
    },

    paymentList() {
      return this.setChosen(this.$store.getters[`payment/${this.currentProductPeriod}Cards`]);
    },

    hasPrev() {
      return this.carouselIndex - 1 >= 0;
    },

    hasNext() {
      return (this.carouselIndex + 1) * this.carouselItemNumber < this.paymentList.length + 1;
    },

    paymentCarouselList() {
      const addCard = {
        class: 'addCard',
        card_name: this.paymentList.length > 0 ? '카드 추가' : `등록된 카드가 없습니다.\n카드 등록을 진행해 주세요`,
        event: this.registerCardForMonthlyBilling,
      };

      if (this.isEditing) {
        return [addCard, ...this.paymentList].slice(
          this.carouselIndex * this.carouselItemNumber,
          this.carouselIndex * this.carouselItemNumber + this.carouselItemNumber,
        );
      }
      return [...this.paymentList, addCard].slice(
        this.carouselIndex * this.carouselItemNumber,
        this.carouselIndex * this.carouselItemNumber + this.carouselItemNumber,
      );
    },

    editButtonText() {
      return this.isEditing ? '편집 완료' : '카드 편집';
    },

    products() {
      const [monthly, yearly] = this.formatProducts(this.servicePlanProducts);
      if (this.currentProductPeriod === 'monthly') {
        return monthly;
      }
      return yearly;
    },

    servicePlanProducts() {
      return this.$store.getters['payment/servicePlanProducts'];
    },

    isBothAutoCharging() {
      return (
        this.paymentPolicy.usable_subscription_auto_charge &&
        this.paymentPolicy.usable_point_auto_charge &&
        this.subscriptionCardId === this.paymentPolicy.point_card_id
      );
    },

    isInstallmentCondition() {
      // 실제 결제 금액 5만원 이상 + 단건 결제(정기결제를 하고 있지 않을 때)
      return this.selectedProduct.actual_price >= 50000;
    },

    installmentInfoLink() {
      return 'https://studiomate.notion.site/studiomate/d97c4ec5aef34a1c9b04e150890c06cb';
    },

    phoneNumberInputClass() {
      const isSame = this.paymentPolicy.subscription_payment_notification_number == this.chargeNotificationNumber;
      if (!this.chargeNotificationNumber || isSame) return 'default';
      return this.phoneNumberErrorMessage ? 'warning' : 'valid';
    },

    phoneNumberErrorMessage() {
      if (!this.chargeNotificationNumber) return '';
      const phoneNumberValidation = /^01([0|1|6|7|9])\d{7,8}$/;
      if (!phoneNumberValidation.test(this.chargeNotificationNumber)) {
        return '핸드폰 번호만 입력이 가능합니다';
      }
      return '';
    },

    phoneNumberButtonClass() {
      if (!this.chargeNotificationNumber) return 'btn-disabled';
      const isSame = this.paymentPolicy.subscription_payment_notification_number == this.chargeNotificationNumber;
      return `btn-${!this.phoneNumberErrorMessage && !isSame ? 'valid' : 'disabled'}`;
    },

    paymentInfo() {
      return this.$store.getters['payment/paymentInfo'];
    },

    nowPayment() {
      return this.paymentInfo.now_payment;
    },

    hasTossCard() {
      if (this.currentProductPeriod === 'yearly') {
        return this.hasTossCardForYearlyPay;
      }
      return this.paymentList.some(card => card.pg_type?.includes('toss'));
    },

    hasKSNetCard() {
      return this.paymentList.some(card => card.pg_type?.includes('ks'));
    },
  },
  watch: {
    paymentList() {
      if (this.carouselIndex - 1 >= 0 && !this.paymentCarouselList.length) {
        this.handleClickToPrev();
      }
    },
    $route: {
      handler() {
        this.$store.dispatch('payment/updateType', 'subscription_card_id');
      },
      immediate: true,
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      await this.initializePaymentData();
      await this.checkTerms();
      await this.setDefaultProduct();

      this.chargeNotificationNumber = this.paymentPolicy.subscription_payment_notification_number;

      // 월결제 카드 등록에서 리다이렉트된 경우 화면 유지
      const productId = Number(this.$route.query.selectedProductId);
      const step = this.$route.query.step;

      if (!Number.isNaN(productId) || step) {
        this.restoreSelectedProductForMonthlyCard(productId, step);
      }
    },

    async initializePaymentData() {
      await this.$store.dispatch('payment/getPaymentPolicy');
      await this.$store.dispatch('payment/getServicePlanProducts');
      await this.$store.dispatch('payment/getPaymentInfo');
      this.getPaymentList();
    },

    async restoreSelectedProductForMonthlyCard(productId, step) {
      const selectedProduct = this.servicePlanProducts.find(product => product.id === productId);
      if (selectedProduct) {
        this.selectedProduct = selectedProduct;
        this.currentProductPeriod = productId === 1 || productId === 3 ? 'monthly' : 'yearly';
        await this.getPaymentList();
      }

      if (step === 'monthlyPayment') {
        const cardId = Number(this.$route.query.cardId);
        this.cardAdded = this.paymentList.find(card => card.id === cardId);
      }
      if (step === 'addCard') {
        this.$utils.notify.success(this, '성공', '카드가 등록되었습니다.');
      }
    },

    async getPaymentList() {
      await this.$store.dispatch('payment/getPaymentList', this.currentProductPeriod);
      if (this.currentProductPeriod === 'yearly') {
        await this.getTossCardCount();
      }
    },

    async getTossCardCount() {
      try {
        const response = await this.$api.payment.getTossCardCount();
        this.hasTossCardForYearlyPay = response.data.count && response.data.count > 0;
      } catch (error) {
        this.hasTossCardForYearlyPay = false;
        this.$utils.notify.parseError(this, error);
      }
    },

    agreeUseTerms() {
      if (!this.isAgreedTerms) return this.open();
      this.checkPeriod();
    },
    checkPeriod() {
      if (!this.selectedProductId) {
        this.$utils.notify.error(this, '오류', '상품을 선택해주세요.');
        return;
      }
      if (this.currentProductPeriod === 'monthly' && !this.paymentList.length) {
        this.$utils.notify.error(this, '오류', '결제 카드를 추가해주세요.');
        return;
      }
      if (this.currentProductPeriod === 'monthly' && !this.selectedCardId) {
        this.$utils.notify.error(this, '오류', '결제 카드 리스트에서 결제할 카드를 선택해주세요.');
        return;
      }

      const hasOnlyKSNetCard = this.hasKSNetCard && !this.hasTossCard;
      const isKSNetCardSelected = this.paymentList.find(card => card.id === this.selectedCardId)?.pg_type.includes('ks');

      if (hasOnlyKSNetCard || (this.currentProductPeriod === 'monthly' && isKSNetCardSelected)) {
        this.showPGChangeNotice = true;
        return;
      }
      const nextStep = this.currentProductPeriod === 'monthly' ? this.showConfirmDialog : this.yearlyPay;
      nextStep();
    },

    registerCard() {
      this.showPGChangeNotice = false;
      if (this.selectedProductId === 1 || this.selectedProductId === 3) {
        return this.registerCardForMonthlyBilling('monthlyPayment');
      }
      return this.yearlyPay(); // 토스 연결제는 카드등록&결제 모두 토스에서 통합 진행
    },

    async registerCardForMonthlyBilling(step) {
      try {
        this.loading = true;
        const tossPaymentSdk = await loadTossPayments(process.env.VUE_APP_TOSS_BILLING_CLIENT_KEY);
        const tossPayment = tossPaymentSdk.payment({ customerKey: this.customerKey });
        const page = `${window.location.origin}/settings/service-payments/toss-card`;
        await tossPayment.requestBillingAuth({
          method: 'CARD',
          // 토스 월결제 카드등록용 빌링은 REDIRECT 방식만 지원해서 현재 페이지로 리다이렉트되도록 함
          successUrl: `${page}?result=success&selectedProductId=${this.selectedProductId}&step=${step ?? 'addCard'}`,
          failUrl: `${page}?result=fail&selectedProductId=${this.selectedProductId}`,
        });
      } catch (error) {
        if (error?.code === 'USER_CANCEL') {
          return;
        }
        this.$utils.notify.parseError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async monthlyPay() {
      try {
        this.loading = true;
        const payload = {
          success: false,
          existPlan: this.currentProductNumber,
          selectedPlan: this.selectedProduct,
        };
        const data = { subscription_id: this.selectedProductId, card_id: this.selectedCardId };
        const response = await this.$store.dispatch('payment/requestPayment', data);
        payload.success = response.status === 'success';
        payload.paymentDate = response.expire_on || this.moment(); //현재 요금제 종료 시점에 결제 예약 | 바로 결제되는 경우
        payload.errorMessage = response.message;
        payload.paymentResultCode = response.status_code;

        if (response.status === 'success') {
          const isAfter = this.moment(this.studioExpireDate).isAfter(this.moment());
          if (isAfter && this.selectedProduct.quantity === 1) {
            // 기존 결제 상품이 있는데, 월 결제로 바꾸는 경우
            this.loading = false;
            this.handleClickShowChangeSubscriptionResultDialog();
            return;
          }
          const selectedCard = this.paymentList.find(card => card.id === this.selectedCardId);
          payload.paymentAmount = this.selectedProduct.actual_price;
          payload.paymentMethod = `${selectedCard?.card_name} ${
            selectedCard?.card_number ? `(${selectedCard?.card_number?.slice(-4).replace('X', '*')})` : ''
          }`;
          await this.setPaymentResultPayload({
            flag: 'paymentMonthlyResultPayload',
            data: payload,
          });
        }
        if (response.status !== 'success') {
          await this.setPaymentResultPayload({
            flag: 'paymentMonthlyResultPayload',
            data: payload,
          });
        }
        this.loading = false;
        this.showPaymentMonthlyResultDialog = true;
        await this.$store.dispatch('studio/getStudio');
      } catch (error) {
        this.loading = false;
        this.$utils.notify.parseError(this, error);
      }
    },

    async yearlyPay() {
      try {
        this.loading = true;
        const orderIdResponse = await this.$api.payment.getOrderId(this.selectedProductId);
        const orderId = orderIdResponse.data;
        const amount = { currency: 'KRW', value: this.selectedProduct.actual_price };
        const orderName = `연결제 (${this.selectedProduct.grade.toUpperCase()})`;

        const tossPaymentSdk = await loadTossPayments(process.env.VUE_APP_TOSS_BRANDPAY_CLIENT_KEY);

        const sdk = tossPaymentSdk.brandpay({
          customerKey: this.customerKey,
          redirectUrl: process.env.VUE_APP_TOSS_BRANDPAY_REDIRECT_URL,
        });

        const payload = { amount, orderId, orderName };

        sdk
          .requestPayment(payload)
          .then(async response => {
            await this.confirmYearPayment(response);
          })
          .catch(error => {
            this.loading = false;
            if (error.code !== 'USER_CANCEL') {
              this.$utils.notify.parseError(this, error);
            }
          })
          .finally(async () => {
            await this.$api.payment.tossBrandPayCardRefresh(); // 백엔드 카드갱신 요청
            await this.getPaymentList();
          });
      } catch (error) {
        this.loading = false;
        this.$utils.notify.parseError(this, error);
      }
    },

    async confirmYearPayment({ orderId, paymentKey, amount }) {
      const resultPayload = {
        success: false,
        existPlan: this.currentProductNumber,
        selectedPlan: this.selectedProductId,
        paymentAmount: this.selectedProduct.actual_price,
        installment: this.requestPaymentData.installment,
      };
      try {
        const response = await this.$api.payment.confirmYearPayment({
          orderId,
          paymentKey,
          amount: amount.value,
          subscriptionId: this.selectedProductId,
          chargeNotificationNumber: this.chargeNotificationNumber,
        });
        resultPayload.success = true;
        resultPayload.paymentResultCode = response.data?.code;
        resultPayload.paymentDate = response.expire_on || this.moment();
        resultPayload.paymentMethod = `${response.data?.card_name} ${
          response.data?.card_number ? `(${response.data?.card_number?.slice(-4).replace('X', '*')})` : ''
        }`;
      } catch (error) {
        resultPayload.paymentResultCode = error.response.data?.code;
        resultPayload.errorMessage = error.response?.data?.message ?? error.message;
      } finally {
        this.setPaymentResultPayload({
          flag: 'paymentYearlyResultPayload',
          data: resultPayload,
        });
        this.loading = false;
        this.showPaymentYearlyResultDialog = true;
      }
    },

    async setPaymentResultPayload({ flag, data }) {
      await (this[flag] = data);
    },

    initializePaymentResultPayload() {
      this.paymentMonthlyResultPayload = {};
      this.paymentYearlyResultPayload = {};
    },
    handleChangeProductPeriod(value) {
      this.currentProductPeriod = value;
      this.setDefaultProduct();
      this.getPaymentList();
    },
    handleClickProduct(product) {
      this.selectedProduct = product;
    },
    proceedPayment: _.debounce(function() {
      if (this.loading) return;
      this.handleCloseConfirmDialog();
      if (this.selectedProductId === 1 || this.selectedProductId === 3) {
        return this.monthlyPay();
      }
      return this.yearlyPay();
    }, 500),
    showConfirmDialog() {
      this.showPaymentConfirmDialog = true;
    },
    handleCloseConfirmDialog() {
      this.showPaymentConfirmDialog = false;
    },
    async handleCloseResultDialog() {
      const success = this.paymentMonthlyResultPayload.success || this.paymentYearlyResultPayload.success;
      this.initializePaymentResultPayload();
      if (!success) {
        this.showPaymentMonthlyResultDialog = false;
        this.showPaymentYearlyResultDialog = false;
        return;
      }
      return this.$router.push('/settings/service-payments/info').catch(() => {});
    },
    open() {
      this.showUseTerms = true;
    },
    checkTerms() {
      if (!this.isAgreedTerms) this.open();
    },
    calcYearPLUStoYearBASIC() {
      //결제요금 설계 엑셀파일 연결제PLUS ->BASIC 계산식내용과 같음 https://docs.google.com/spreadsheets/d/1CSyuXubxrLZY9zEiGVagffBFTkI9GjNYcyErPkPUi6Q/edit#gid=0
      //시작일
      const start = this.moment(this.studioExpireDate)
        .subtract(1, 'year')
        .add(1, 'day')
        .format('YYYY-MM-DD');

      //종료일
      const end = this.studioExpireDate;
      //오늘날짜
      const today = this.moment().format('YYYY-MM-DD');

      //잔여개월
      const remain_month = this.moment(end).diff(today, 'month');

      const calc_month = this.moment(today)
        .add(remain_month, 'month')
        .format('YYYY-MM-DD');

      const remain_days = this.moment(end).diff(calc_month, 'day');

      const pay = Math.floor(remain_month * 10000 + (remain_days / 30) * 10000);

      const result_month = Math.round(pay / 28900);

      const result_days = Math.floor(((pay % 28900) / 28900) * 30);

      const final_date = this.moment(end)
        .add(result_month, 'month')
        .add(result_days, 'day')
        .format('YYYY-MM-DD');

      return `${this.$filters.date(start)} ~ ${this.$filters.date(final_date)}`;
    },
    calcYearMonthAdd(period) {
      return `${this.moment().format('YYYY. M. D')} ~ ${this.moment()
        .add(1, period)
        .subtract(1, 'day')
        .format('YYYY. M. D')}`;
    },
    calcAdd1Day(period) {
      return `${this.$filters.date(this.moment(this.studioExpireDate).add(1, 'day'))} ~ ${this.$filters.date(
        this.moment(this.studioExpireDate).add(1, period),
      )}`;
    },

    handleClickToPrev() {
      if (!this.hasPrev) {
        this.carouselIndex = 0;
        return;
      }
      return this.carouselIndex--;
    },

    handleClickToNext() {
      if (!this.hasNext) {
        return;
      }
      return this.carouselIndex++;
    },

    setChosen(data) {
      if (!data) return [];
      return data.reduce((acc, current) => {
        let chosen = { class: '', label: '' };
        if (
          !this.isBothAutoCharging &&
          this.paymentPolicy.usable_subscription_auto_charge &&
          current.id === this.subscriptionCardId
        ) {
          chosen.label = '서비스 정기 결제 카드';
        }

        if (
          !this.isBothAutoCharging &&
          this.paymentPolicy.usable_point_auto_charge &&
          current.id === this.paymentPolicy.point_card_id
        ) {
          chosen.label = '포인트 정기 결제 카드';
        }

        if (this.isBothAutoCharging && this.subscriptionCardId === current.id) {
          // 서비스 결제 및 포인트 충전 모두 자동결제하는 경우
          chosen.label = '서비스 및 포인트 정기 결제 카드';
        }
        if (chosen.label) {
          return (acc = [...acc, { ...current, ...chosen }]);
        }
        return (acc = [...acc, { ...current, class: '' }]);
      }, []);
    },

    formatProducts(products) {
      const [monthly, yearly] = products?.reduce(
        (acc, currentProduct) => {
          if (currentProduct.quantity === 1) {
            acc[0].push(currentProduct);
          } else acc[1].push(currentProduct);
          return acc;
        },
        [[], []],
      );

      return [monthly, yearly];
    },

    setDefaultProduct() {
      const alreadySelected = this.products.filter(p => p.id === this.currentProductNumber);
      if (!alreadySelected.length) {
        this.selectedProduct = this.products[0];
        return;
      }
      this.selectedProduct = alreadySelected[0];
      return;
    },

    handleClickCardItem(id) {
      this.selectedCardId = id;
    },

    setRequestPaymentData(data) {
      this.requestPaymentData = { ...this.requestPaymentData, ...data };
    },

    async handleClickChangeChargeNotificationNumber() {
      if (!this.chargeNotificationNumber) return;
      await this.dispatchUpdatePayment({ subscription_payment_notification_number: this.chargeNotificationNumber });
      await this.$utils.notify.success(this, '확인', '결제 알림 휴대폰 번호가 변경되었습니다.');
    },

    async dispatchUpdatePayment(data) {
      await this.$store.dispatch('payment/updatePaymentPolicy', data);
    },
    handleClickCloseChangeSubscriptionResultDialog() {
      this.showSubscriptionResultDialog = false;
    },
    handleClickShowChangeSubscriptionResultDialog() {
      this.showSubscriptionResultDialog = true;
    },
    continueMonthlyPayment() {
      this.cardAdded = null;
      this.showPaymentConfirmDialog = true;
    },
    async openBrandPaySetting() {
      const tossPaymentSdk = await loadTossPayments(process.env.VUE_APP_TOSS_BRANDPAY_CLIENT_KEY);

      const sdk = tossPaymentSdk.brandpay({
        customerKey: this.customerKey,
        redirectUrl: process.env.VUE_APP_TOSS_BRANDPAY_REDIRECT_URL,
      });
      await sdk.openSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
$content: 50rem;
.wrap-serviceplan {
  max-width: 1024px;
  margin: 0 auto;
  hr {
    border-top: 1px solid $gray-200;
    border-bottom: none;
    margin: 20px 0;
  }
  h4 {
    font-size: 24px;
    font-weight: bold;
    color: #303133;
    width: $content;
    margin: 0 auto;
  }
  .custom-radio-group {
    width: $content;
    grid-template-columns: repeat(2, 1fr);
    margin: 10px auto;
  }
  .products {
    width: $content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    padding: 24px 0;

    /deep/ .el-card__body {
      padding: 0;
    }

    .el-card {
      text-align: center;
      .product {
        display: grid;
        padding: 16px;
        grid-template-rows: 50px 170px 2.1rem;
        transition: 0.15s background, 0.15s color;
        cursor: pointer;
        &.active {
          background: $dodger-blue;
          color: #fff;
          .origin,
          .tax {
            color: #fff;
          }
        }
      }
      &:hover {
        background: #fff;
        color: $dodger-blue;
        .origin,
        .tax {
          color: $dodger-blue;
        }
      }
      .title {
        font-size: 2.5rem;
        font-weight: bold;
      }
      .info {
        font-size: 1.25rem;
        line-height: 2;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .origin {
        text-decoration: line-through;
        font-size: 1.3rem;
        color: $gray-500;
      }
      .price {
        font-size: 1.75rem;
        font-weight: bold;
      }
      .tax {
        padding-top: 10px;
        color: $gray-500;
      }
    }
  }

  .wrap-payment {
    position: relative;
    width: $content;
    margin: 0 auto;

    &.yearly {
      padding-top: 15px;
    }

    .wrap-title.notification {
      padding: 15px 0px 30px 0px;
      &:before {
        content: '';
        position: absolute;
        top: 13px;
        left: -64px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/cell-phone.svg') 0 0 no-repeat;
        background-size: cover;
      }

      .el-input {
        width: 253px !important;

        &::placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #bababa;
        }
      }

      .btn {
        &-disabled {
          width: 114px;
          height: 36px;
          top: 15px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          background: #b5b5b5;
          color: $white;
          pointer-events: none;
        }
        &-valid {
          width: 114px;
          height: 36px;
          top: 15px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #1da0ff;
          border: solid 1px $dodger-blue;
          background: $white;
        }
      }

      span {
        &:nth-child(2) {
          display: flex;
          line-height: 49px;
          .el-input {
            padding: 0px 57px 0px 0px;
          }
        }
      }

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      p.warning {
        font-size: 12px;
        line-height: 18px;
        color: $coral;
        position: absolute;
        top: 57px;
      }

      /deep/ .warning .el-input__inner,
      /deep/.warning .el-input__inner:focus {
        border: none;
        border-bottom: 2px solid #f45354 !important;
      }
    }

    .wrap-title.card {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: -8px;
        left: -64px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/credit-card.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    .wrap-title.invoice {
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: -64px;
        width: 48px;
        height: 48px;
        background: url('../../../assets/images/icons/receipt.svg') 0 0 no-repeat;
        background-size: cover;
      }
    }

    .wrap-title {
      position: relative;
      display: flex;
      justify-content: space-between;

      h5 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      & + p {
        color: #a3a3a3;
      }

      button {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #808080;
      }

      .btn-text {
        margin: 0 0 0 auto;

        &-blue {
          font-weight: 500;
          color: #1da0ff;
          padding: 0px 0px 0px 0px;
        }
      }
    }

    .info {
      .title {
        line-height: 31px;
        padding-top: 5px;
        font-size: 16px;
        color: #808080;
        span:first-child {
          display: inline-block;
          width: 75px;
        }

        span + span {
          padding: 0px 0px 0px 8px;
        }
      }
      .period {
        font-size: 16px;
      }
      .price {
        font-size: 16px;
        font-weight: bold;
        color: $dodger-blue;
      }
      .origin {
        font-size: 16px;
        color: #a3a3a3;
        text-decoration: line-through;
      }
      span + span {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $charcoal-grey;
      }
    }
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-area {
        display: flex;
        align-items: center;
      }
      .price {
        color: $dodger-blue;
        font-weight: bold;
        font-size: 24px;
      }
      .origin-price {
        text-decoration: line-through;
        font-weight: bold;
        color: $gray-500;
        display: inline-block;
        padding-right: 20px;
      }
    }

    .txt-updated {
      margin: 10px 0 30px;
    }

    &__input-box {
      position: relative;
      &__sub {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a3a3a3;
        padding: 5px 0px 0px 0px;
      }
    }
  }

  .wrap-btn {
    position: relative;
    width: 50rem;
    margin: 0 auto;
    .area-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .toss-setting {
        background-color: #f2f2f2;
        border: none;
        color: #343a40;
        padding: 12px 20px;
      }
    }
  }
  button.btn-text {
    font-size: 16px;
    font-weight: 500;
    color: #1da0ff;
  }
  button.btn-text-block {
    position: absolute;
    right: 0px;
    width: 180px;
    height: 40px;
    line-height: 1;
    font-weight: 500;
    font-size: 14px;

    &.side {
      position: absolute;
      width: auto;
      border: none;
      right: 195px;
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a3a3a3;

      &:disabled {
        cursor: default;
      }
    }
  }

  &__payment-list {
    padding: 30px 0px 0px 0px;

    /deep/ .carousel-item-container {
      margin: 0px -36px 0px -36px;
    }
  }

  /deep/section {
    width: 48rem;
    margin: 0 auto;
  }

  /deep/.carousel-btn-prev {
    top: 220px;
    left: -82px;
  }

  /deep/.carousel-btn-next {
    right: -79px;
    top: 220px;
  }
}
</style>
