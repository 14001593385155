<template>
  <el-dialog
    id="overlapping-schedule"
    class="overlapping-schedule"
    :title="`${isEditMode ? '수정' : '등록'} 결과`"
    :visible="show"
    @close="handleCloseDialog"
  >
    <p class="overlapping-schedule__result">{{ overlappingSchedules.length }}개의 일정이 중복됩니다.</p>

    <li class="overlapping-schedule__list-header">
      <span></span>
      <span>수업명</span>
      <span>수업일</span>
      <span>수업시간</span>
      <span>강사</span>
      <span>룸</span>
      <span>중복항목</span>
    </li>

    <ul class="overlapping-schedule__list">
      <li class="overlapping-schedule__list-item" v-for="data in overlappingSchedules" :key="data.id">
        <i class="el-icon-error"></i>
        <span>{{ data.title }}</span>
        <span>{{ data.date }}</span>
        <span>{{ data.time }}</span>
        <span class="highlight">{{ data.staff }} 강사</span>
        <span></span>
        <span>{{ data.overlapReason }}</span>
      </li>
    </ul>

    <div slot="footer" class="overlapping-schedule__buttons">
      <el-button @click="handleCloseDialog" type="primary">
        확인
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    isEditMode: Boolean,
    close: Function,
    overlappingSchedules: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.overlapping-schedule {
  .el-dialog {
    width: 95%;
    max-width: 720px;
  }

  &__result {
    font-weight: bold;
    opacity: 0.6;
  }

  &__list {
    @include flex(column);
    margin-top: 5px;
    max-height: 40vh;
    overflow-y: auto;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 20px 2fr 3fr 2fr 2fr 1fr 2fr;
      place-items: center;
      padding-bottom: 8px;
    }

    &-header {
      border-bottom: 1px solid #ebebeb;
      margin-top: 8px;
      font-weight: bold;
    }

    &-item {
      i {
        color: $color-danger;
      }

      a,
      span {
        @include ellipsis;
        text-align: center;
        width: 100%;
      }

      span.highlight {
        color: $color-danger;
        font-weight: bold;
      }
    }
  }
}
</style>
