<template>
  <el-dialog
    class="success-charge-dialog"
    :title="modalTitle"
    width="320px"
    :visible="show"
    @close="handleClickCancel"
    :show-close="false"
    append-to-body
  >
    <p>
      <label>{{ amountLabel }}</label>
      <span>
        {{ payload.amount | comma }}원
        <span v-if="showInstallment">(할부 {{ payload.installment }}개월)</span>
        / {{ payload.point | comma }}P
      </span>
    </p>
    <p>
      <label>{{ pointLabel }}</label>
      <span>{{ payload.total | comma }}P</span>
    </p>

    <div slot="footer" class="set-auto-charge-dialog__buttons">
      <el-button @click="handleClickCancel" type="primary">확인</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    handleClickCancel: Function,
    payload: { flag: String | undefined, amount: Number, point: Number, total: Number, installment: Number },
  },

  computed: {
    modalTitle() {
      if (this.payload.flag === 'UPDATE') {
        return '자동 충전 금액 변경';
      }
      return '결제 성공';
    },

    amountLabel() {
      if (this.payload.flag === 'UPDATE') {
        return '변경 금액';
      }
      return '결제 금액';
    },

    pointLabel() {
      if (this.payload.flag === 'UPDATE') {
        return '현재 잔여 포인트';
      }
      return '충전 후 잔여 포인트';
    },

    showInstallment() {
      return this.payload.installment > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  top: 20vh;
  width: 320px;
  &__title {
    color: #303133;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  &__header {
    padding: 20px 20px 10px 20px;
  }

  &__body {
    padding: 0;
    p {
      padding: 10px 20px 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > label {
        width: 117px;
        font-size: 14px;
        color: #606266;
      }

      > span {
        padding: 0px 0px 0px 16px;
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #606266;
      }
    }
  }

  &__footer {
    padding: 10px 20px 20px 20px;
    text-align: center;

    .el-button {
      width: 64px;
      height: 32px;
      padding: 0px 0px 0px 0px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
