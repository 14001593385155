<template>
  <section class="calendar-controls">
    <custom-tabs
      class="calendar-controls__tabs"
      :active_tab="filters.eventType"
      :tabs="eventTypeTabs"
      :handleChangeTab="handleChangeTab"
    />

    <div class="calendar-controls__buttons">
      <el-button plain size="small" icon="el-icon-arrow-left" @click="handleClickPrev" />
      <el-button plain size="small" :disabled="present[viewType] === datepickerValues[viewType]" @click="handleClickToday">{{
        viewType === 'week' ? '이번주' : '오늘'
      }}</el-button>
      <el-button plain size="small" icon="el-icon-arrow-right" @click="handleClickNext" />
      <el-button plain size="small" v-popover:staffFilter>강사별보기</el-button>
      <el-popover
        popper-class="calendar-controls__staff-filter-popover"
        ref="staffFilter"
        trigger="click"
        v-model="showStaffFilter"
        @show="onShowStaffFilter"
      >
        <custom-checkbox
          class="calendar-controls__staff-filter__check-all"
          :checked="staffFilter.showAll"
          :indeterminate="!staffFilter.showAll && staffFilter.ids.length !== 0"
          @change="handleChangeShowStaffAll"
          >전체 선택</custom-checkbox
        >
        <draggable
          v-model="staffList"
          class="calendar-controls__staff-filter"
          ghost-class="calendar-controls__staff-filter__list-item--ghost"
          :animation="200"
          :fallbackTolerance="5"
        >
          <transition-group name="flip-list">
            <li v-for="staff in staffList" :key="staff.id">
              <custom-checkbox
                :value="staff.id"
                :color="`#${_.get(staff, 'profile.representative_color') || '000'}`"
                :checked="staffFilter.ids.includes(staff.id)"
                @change="handleChangeShowStaffIds"
                >{{ _.get(staff, 'profile.name') }}</custom-checkbox
              >
            </li>
          </transition-group>
        </draggable>
        <div class="calendar-controls__staff-filter__buttons">
          <el-button @click="showStaffFilter = false">
            취소
          </el-button>
          <el-button type="primary" @click="handleSaveStaffFilter">
            완료
          </el-button>
        </div>
      </el-popover>
      <el-radio-group class="calendar-controls__view-type" v-model="calendarViewType" size="small">
        <el-radio-button v-for="option in calendarViewTypeOptions" :key="option.value" :label="option.value">{{
          option.label
        }}</el-radio-button>
      </el-radio-group>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: { draggable },

  data() {
    return {
      eventTypeTabs: [
        { value: 'all', label: '전체' },
        { value: 'private', label: '프라이빗수업' },
        { value: 'group', label: '그룹수업' },
        { value: 'counseling', label: '상담' },
        { value: 'etcSchedule', label: '기타일정' },
      ],
      showStaffFilter: false,
      staffFilter: {
        showAll: false,
        ids: [],
      },
      staffList: [],
    };
  },

  computed: {
    present() {
      return this.$store.getters['schedule/present'];
    },
    datepickerValues() {
      return this.$store.getters['schedule/datepickerValues'];
    },
    filters() {
      return this.$store.getters['schedule/filters'];
    },
    staffOptions() {
      return this.$store.getters['schedule/staffOptions'];
    },

    viewType() {
      return this.calendarViewType.split('|')[0];
    },

    calendarViewType: {
      get() {
        return this.$store.getters['schedule/calendarViewType'];
      },
      set(value) {
        this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', value);
      },
    },

    calendarViewTypeOptions() {
      const useRooms = _.get(this.studioPolicies, 'is_use_rooms');

      let options = [
        { value: 'month', label: '월간' },
        { value: 'week', label: '주간' },
      ];
      if (!useRooms) {
        options = [...options, { value: 'date|instructor', label: '일간' }];
      } else {
        options = [...options, { value: 'date|instructor', label: '일간(강사별)' }, { value: 'date|room', label: '일간(룸별)' }];
      }

      return options;
    },
  },

  async created() {
    this.$store.dispatch('schedule/getStaffOptions');
    if (this.calendarViewType === 'date|room' && !this.studioPolicies.is_use_rooms) {
      this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', 'week');
    }
  },

  methods: {
    handleChangeTab(eventType) {
      this.$store.commit('schedule/SET_FILTERS', { eventType });
    },

    handleClickPrev() {
      const { moment, viewType, datepickerValues } = this;
      const unit = viewType === 'date' ? 'day' : viewType;
      const date = moment(datepickerValues[viewType]).subtract(1, unit);
      this.$store.commit('schedule/SET_DATEPICKER_VALUES', date);
    },

    handleClickToday() {
      this.$store.commit('schedule/SET_DATEPICKER_VALUES', this.present[this.viewType]);
    },

    handleClickNext() {
      const { moment, viewType, datepickerValues } = this;
      const unit = viewType === 'date' ? 'day' : viewType;
      const date = moment(datepickerValues[viewType]).add(1, unit);

      this.$store.commit('schedule/SET_DATEPICKER_VALUES', date);
    },

    onShowStaffFilter() {
      this.staffFilter = { ...this.filters.staff };
      this.staffList = [...this.staffOptions];
    },

    handleChangeShowStaffAll() {
      const { showAll } = this.staffFilter;
      const ids = !showAll ? this.staffOptions.map(({ id }) => id) : [];

      this.staffFilter = {
        showAll: !showAll,
        ids,
      };
    },

    handleChangeShowStaffIds(value) {
      const { ids } = this.staffFilter;
      if (ids.includes(value)) {
        this.staffFilter.ids = ids.filter(id => id !== value);
      } else {
        this.staffFilter.ids = [...ids, value];
      }

      const staffCountAll = this.staffOptions.length;
      const staffCount = this.staffFilter.ids.length;

      this.staffFilter.showAll = staffCountAll === staffCount;
    },

    handleSaveStaffFilter() {
      this.showStaffFilter = false;

      /** 필터 저장 */
      const { showAll, ids } = this.staffFilter;
      this.$store.commit('schedule/SET_FILTERS', {
        staff: { showAll, ids },
      });

      /** 강사 정렬 순서 저장 */
      const staffListOrders = this.staffList.map(({ id }) => id);
      this.$store.commit('schedule/SET_STAFF_LIST_ORDERS', staffListOrders);
      this.$store.commit('schedule/SET_STAFF_OPTIONS', this.staffList);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-controls {
  @include flex(column-reverse, center, flex-start);

  &__buttons {
    @include flex(row, stretch, center);

    button {
      background: $gray-100;

      &:hover,
      &:focus {
        background: $gray-200;
        color: $charcoal-grey;
      }

      &:disabled {
        background: $gray-100;

        &:hover {
          background: $gray-100;
        }
      }
    }

    /deep/ .el-radio-group {
      margin-left: 10px;
    }

    /deep/ .el-radio-button {
      margin: 0;
    }
  }

  &__staff-filter {
    span {
      @include flex(column);
      margin: 10px 20px;
      padding: 10px 0;
      border: solid #eee;
      border-width: 1px 0;
      max-height: 50vh;
      overflow-y: auto;
    }

    li {
      margin-right: 7px;
      transition: background 0.15s;

      &:hover {
        background: rgba($color-primary, 0.1);
      }
    }

    &__list-item--ghost {
      background: rgba($color-primary, 0.2);
      opacity: 0.5;
    }

    &-popover {
      width: 90%;
      max-width: 360px;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
    }

    &__check-all {
      margin: 10px 20px;
    }
  }

  &__view-type {
    display: none;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

@media (min-width: 568px) {
  .calendar-controls {
    &__tabs {
      align-self: flex-start;
    }

    &__buttons {
      align-self: flex-end;
    }

    &__view-type {
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  .calendar-controls {
    @include flex(row, center, space-between);
  }
}
</style>
