<template>
  <el-dialog class="etc-detail-mode-modal" :visible.sync="show" :before-close="handleClose">
    <div slot="title" class="etc-detail-mode-modal__title">
      <p>기타 일정 {{ this.modeText }}</p>
    </div>

    <div class="etc-detail-mode-modal__body">
      <el-radio-group v-model="mode">
        <el-radio label="single">이 일정만 {{ this.modeText }}</el-radio>
        <el-radio label="bulk">이후 일정 모두 {{ this.modeText }}</el-radio>
      </el-radio-group>
    </div>

    <div class="etc-detail-mode-modal__buttons">
      <el-button @click="handleClose">
        취소
      </el-button>
      <el-button type="primary" @click="handleConfirm">
        확인
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    modalMode: String,
    onClose: Function,
    onEdit: Function,
    onDelete: Function,
  },

  data() {
    return {
      mode: 'single',
    };
  },

  computed: {
    modeText() {
      return this.modalMode === 'edit' ? '수정' : '삭제';
    },
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.mode = 'single';
      }
    },
  },

  methods: {
    handleClose() {
      this.onClose();
    },
    handleConfirm() {
      if (this.modalMode === 'edit') {
        this.$emit('onEdit', this.mode);
      } else {
        this.$emit('onDelete', this.mode);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-detail-mode-modal {
  /deep/ .el-dialog {
    width: 90%;
    max-width: 360px;
    top: 50%;
    margin-top: -114px !important;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  /deep/ .el-dialog__headerbtn {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 8px 20px 20px;
  }

  /deep/ .el-radio-group {
    flex-direction: column;

    .el-radio {
      padding: 8px 0;
      margin-left: 0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__buttons {
    margin-top: 12px;
    text-align: right;
  }
}
</style>
