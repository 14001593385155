<template>
  <el-dialog
    class="payment-result-dialog"
    :title="payResult"
    :visible="show"
    :show-close="false"
    :close-on-click-modal="false"
    width="320px"
  >
    <div v-if="paymentSuccess" class="payment-result-dialog__success">
      <label>결제수단</label>
      <p class="payment-method">
        <span v-for="method in paymentMethod" :key="method">
          {{ method }}
        </span>
      </p>
      <label>결제금액</label>
      <span> {{ paymentAmount | comma }}원 </span>
    </div>
    <div v-else class="payment-result-dialog__fail">
      <p>결제가 취소 되었습니다.</p>
      <span>실패사유 : ({{ payload.paymentResultCode }}) {{ payload.errorMessage }} </span>
    </div>
    <txtChangePayPlan
      v-if="showInfo"
      :existPlan="payload.existPlan"
      :selectedPlan="payload.selectedPlan"
      :paymentDate="payload.paymentDate"
    />
    <div slot="footer">
      <BaseButton @click="$emit('close')" size="large">확인</BaseButton>
    </div>
  </el-dialog>
</template>

<script>
import txtChangePayPlan from './txtChangePayPlan';
export default {
  components: {
    txtChangePayPlan,
  },
  props: {
    show: { type: Boolean, default: false },
    payload: { type: Object, default: null },
  },
  computed: {
    paymentSuccess() {
      return _.get(this.payload, 'success');
    },
    paymentMethod() {
      return _.get(this.payload, 'paymentMethod', '').split(' ');
    },
    paymentAmount() {
      return _.get(this.payload, 'paymentAmount', 0);
    },
    payResult() {
      return this.payload.success ? '결제 성공' : '결제 실패';
    },
    showInfo() {
      if (this.payload.success) {
        return (
          (this.payload.existPlan === 2 || this.payload.existPlan === 4) &&
          (this.payload.selectedPlan.id === 1 || this.payload.selectedPlan.id === 3)
        );
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-result-dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }

  &__success {
    display: grid;
    grid-template-columns: 8rem 1fr;
    grid-gap: 20px;
    align-items: center;

    .payment-method {
      @include flex(column, end, end);
    }
    label {
      font-size: 13px;
    }
    span {
      font-size: 13px;
      font-weight: bold;
      text-align: right;
    }
  }
}
</style>
