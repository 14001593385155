<template>
  <MainLayout :padded="false">
    <ContentHeader :breadcrumb="breadcrumb" :title="title" :contentMaxWidth="1024" />
    <section class="etc-schedule-form" v-loading="loading">
      <form-block :index="1" label="제목" class="etc-schedule-form__title">
        <TextInput v-model="etcTitle" :placeholder="'제목'" material />
      </form-block>

      <form-block :index="2" label="일정" flexRow>
        <EtcDateRangeForm @handleChangeDate="handleChangeDate" />
      </form-block>

      <form-block :index="3" label="일정 공유">
        <EtcStaffForm @handleChangeSelectedStaffs="handleChangeSelectedStaffs" />
      </form-block>

      <form-block :index="4" label="대표 컬러">
        <EtcColorForm @handleChangeColor="handleChangeColor" />
      </form-block>

      <form-block :index="5" label="수업중복 허용" class="etc-schedule-form__overlap">
        <el-checkbox v-model="isOverlap" slot="checkbox">사용함</el-checkbox>
      </form-block>

      <form-block :index="6" label="공개 설정" class="etc-schedule-form__private">
        <el-radio-group v-model="isPrivate">
          <el-radio :label="false">전체 공개</el-radio>
          <el-radio :label="true">비공개</el-radio>
        </el-radio-group>
      </form-block>
    </section>

    <bottom-action-bar :message="lastUpdatedAt">
      <el-button v-loading="isSaving" :disabled="isSaving" @click="saveEtcSchedule">일정 {{ this.modeText }} 완료</el-button>
    </bottom-action-bar>

    <OverlapSchedule
      :show="showOverlapDialog"
      :isEditMode="!!etcScheduleId"
      :overlappingSchedules="overlappingSchedules"
      @close="handleCloseOverlapDialog"
    />
  </MainLayout>
</template>

<script>
import EtcStaffForm from '@/components/EtcSchedule/EtcStaffForm.vue';
import EtcDateRangeForm from '@/components/EtcSchedule/EtcDateRangeForm.vue';
import EtcColorForm from '@/components/EtcSchedule/EtcColorForm.vue';
import OverlapSchedule from '@/components/EtcSchedule/OverlapSchedule.vue';

export default {
  components: { EtcStaffForm, EtcDateRangeForm, EtcColorForm, OverlapSchedule },
  data() {
    const { moment } = this;
    const now = moment();
    const nextHour = now.minutes() > 0 ? now.add(1, 'hour') : now;

    return {
      isSaving: false,
      showOverlapDialog: false,
      overlappingSchedules: [],
      etcTitle: '',
      start: moment().format('YYYY-MM-DD'),
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      startTime: nextHour.startOf('hour').format('HH:mm'),
      endTime: nextHour
        .startOf('hour')
        .add(30, 'minutes')
        .format('HH:mm'),
      weekdays: [],
      selectedStaffs: [],
      isOverlap: true,
      isPrivate: true,
      color: 'ffffff',
    };
  },

  computed: {
    loading() {
      return this.$store.getters['etcSchedule/loading'];
    },
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    breadcrumb() {
      return {
        parent: { path: '/schedule', label: '일정' },
        current_route_label: this.title,
      };
    },
    etcScheduleId() {
      return this.$route.query.id;
    },
    modeText() {
      return this.etcScheduleId ? '수정' : '등록';
    },
    title() {
      return `기타 일정 ${this.modeText}`;
    },
    lastUpdatedAt() {
      if (!this.etcScheduleId || !this.etcScheduleDetail) return null;
      const date = this.etcScheduleDetail.updated_at || this.etcScheduleDetail.created_at;
      if (!date) return null;

      const suffix = this.etcScheduleDetail.updated_at ? '에 마지막으로 수정됨' : '에 생성됨';

      return `${this.$filters.datetime(date)} ${suffix}`;
    },
  },

  watch: {
    etcScheduleDetail: {
      deep: true,
      handler(newValue) {
        if (!this.etcScheduleId || !newValue) return;
        const { is_overlap, is_private, title } = newValue;
        this.etcTitle = title;
        this.isOverlap = is_overlap;
        this.isPrivate = is_private;
      },
    },
  },

  async created() {
    if (this.etcScheduleId) {
      await this.$store.dispatch('etcSchedule/getEtcDetail', this.etcScheduleId);
    } else {
      this.color = this.currentUser.profile.representative_color;
    }
  },

  methods: {
    handleChangeDate(dateData) {
      const { startDate, endDate, startTime, endTime, weekdays } = dateData;
      this.startDate = startDate;
      this.endDate = endDate;
      this.startTime = startTime;
      this.endTime = endTime;
      this.weekdays = weekdays;
    },

    handleChangeSelectedStaffs(staffs) {
      this.selectedStaffs = staffs;
    },

    handleChangeColor(color) {
      this.color = color;
    },

    handleCloseOverlapDialog() {
      this.showOverlapDialog = false;
    },

    /** 수업중복 허용하지 않고 요청 시 중복 일정 에러로 반환받고 가공 */
    getOverlappingLectures(overlapData) {
      return overlapData.map(item => {
        return {
          id: item.id,
          title: item.title,
          date: this.$filters.dateKoreanWithWeekday(item.start_on.date),
          time: `${this.$filters.time(item.start_on.date)}~${this.$filters.time(item.end_on.date)}`,
          staff: item.staff,
          overlapReason: '강사',
        };
      });
    },

    handleError(error) {
      if (error.response.data.length) {
        this.overlappingSchedules = this.getOverlappingLectures(error.response.data);
        this.showOverlapDialog = true;
      } else if (error.response.status === 403) {
        const message = `기타 일정 ${this.modeText} 권한이 없습니다.`;
        this.$utils.notify.error(this, '실패', message);
      } else {
        this.$utils.notify.parseError(this, error);
      }
    },

    async createEtcSchedule(params) {
      try {
        const res = await this.$api.etcSchedule.create(params);
        this.$utils.notify.success(this, '성공', '일정이 등록되었습니다.');
        this.$router.replace(`/etc-schedule/detail?id=${res.data.etc_schedule_time_id}`);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },

    async updateEtcSchedule(mode, params) {
      try {
        let apiParams = { ...params };
        const scheduleId = mode === 'single' ? this.etcScheduleId : this.etcScheduleDetail.etc_schedule_id;

        if (mode === 'single') {
          const { start_date, end_date, start_time, end_time, ...rest } = params;
          const start_on = `${start_date} ${start_time}`;
          const end_on = `${end_date} ${end_time}`;
          apiParams = {
            ...rest,
            start_on,
            end_on,
          };
        }

        // 이후 모든 수정에서는 start 추가
        if (mode === 'bulk') {
          apiParams = {
            ...apiParams,
            start: this.etcScheduleDetail.start_on,
          };
        }
        switch (mode) {
          case 'single':
            await this.$api.etcSchedule.updateSingle(scheduleId, apiParams);
            break;
          case 'bulk':
            await this.$api.etcSchedule.updateBulk(scheduleId, apiParams);
            break;
        }
        this.$utils.notify.success(this, '성공', '일정이 수정되었습니다.');
        this.$router.back();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isSaving = false;
      }
    },

    validate() {
      if (!this.etcTitle) {
        this.$utils.notify.error(this, 'ERROR', '제목을 입력해 주세요.');
        return false;
      } else if (this.etcTitle.length > 200) {
        this.$utils.notify.error(this, 'ERROR', '제목은 200자 이내로 입력해 주세요.');
        return false;
      } else {
        return true;
      }
    },

    async saveEtcSchedule() {
      if (!this.validate()) return;

      this.isSaving = true;
      const query = this.$route.query;

      const staffIds = this.selectedStaffs.filter(id => id !== this.currentUser.id);
      const isSameDay = this.startDate === this.endDate;

      const params = {
        studio_id: this.currentUser.studio_id,
        register_id: this.currentUser.id,
        title: this.etcTitle,
        color: this.color,
        is_repeat: isSameDay ? false : !!this.weekdays.length,
        is_overlap: this.isOverlap,
        is_private: this.isPrivate,
        start_date: this.startDate,
        end_date: this.endDate,
        start_time: `${this.startTime}:00`,
        end_time: `${this.endTime}:00`,
        weekday: this.weekdays.length ? this.weekdays : undefined,
        staff_ids: staffIds.length ? staffIds : undefined,
      };

      if (!this.etcScheduleId) {
        this.createEtcSchedule(params);
      } else if (query.mode) {
        this.updateEtcSchedule(query.mode, params);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-form {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 10px 10px 60px;

  @include mq(768px) {
    padding: 30px 30px 60px;
  }

  &__title {
    .custom-text-input {
      max-width: 447px;
    }
  }

  &__overlap {
    /deep/ .form-block__label {
      margin-bottom: 0;
    }
  }

  &__private {
    /deep/ .form-block__content {
      border-bottom: none;
    }
    .el-radio-group {
      height: 31px;
      align-items: center;
    }
  }
}
</style>
