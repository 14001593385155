<template>
  <section v-loading="loading" class="etc-schedule-detail-header">
    <div class="etc-schedule-detail-header__breadcrumb">
      <Breadcrumb :parent="{ path: '/schedule', label: '일정' }" current="일정 상세" background-color="transparent" />
      <span v-if="isPrivate" class="etc-schedule-detail-header__private-tag-wrapper">
        <img src="@/assets/images/icons/lock_bold.svg" />
        <span class="private-text">
          비공개
        </span>
      </span>
    </div>

    <div class="etc-schedule-detail-header__content">
      <ArrowTooltipButton
        direction="prev"
        :disabled="!etcScheduleDetail.etc_schedule_time_prev"
        :tooltipInfo="prevTooltipInfo"
        @click="handleClickPrevEtc"
      />

      <div class="etc-schedule-detail-header__content__title">
        <h3>{{ title }}</h3>
        <p>{{ etcMeta }}</p>
      </div>

      <ArrowTooltipButton
        direction="next"
        :disabled="!etcScheduleDetail.etc_schedule_time_next"
        :tooltipInfo="nextTooltipInfo"
        @click="handleClickNextEtc"
      />
    </div>
  </section>
</template>

<script>
import ArrowTooltipButton from '@/components/EtcScheduleDetail/ArrowTooltipButton.vue';

export default {
  components: {
    ArrowTooltipButton,
  },

  computed: {
    loading() {
      return this.$store.getters['etcSchedule/loading'];
    },
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    lecture() {
      return this.$store.getters['lecture/lecture'];
    },
    title() {
      return _.get(this.etcScheduleDetail, 'title') || '';
    },
    isPrivate() {
      return _.get(this.etcScheduleDetail, 'is_private');
    },
    etcMeta() {
      const date = this.moment(this.etcScheduleDetail.start_on).format('ll dddd');
      const start_on = this.moment(this.etcScheduleDetail.start_on).format('HH:mm');
      const end_on = this.moment(this.etcScheduleDetail.end_on).format('HH:mm');
      let etcMeta = `${date} · ${start_on} ~ ${end_on}`;

      return etcMeta;
    },
    prevTooltipInfo() {
      return this.getTooltipInfo(
        this.etcScheduleDetail?.etc_schedule_time_prev?.start_on,
        this.etcScheduleDetail?.etc_schedule_time_prev?.end_on,
        '이전 일정',
      );
    },
    nextTooltipInfo() {
      return this.getTooltipInfo(
        this.etcScheduleDetail?.etc_schedule_time_next?.start_on,
        this.etcScheduleDetail?.etc_schedule_time_next?.end_on,
        '다음 일정',
      );
    },
  },

  methods: {
    getTooltipInfo(startOn, endOn, title) {
      let dateTime = '';

      if (startOn) {
        const mainStartDate = this.moment(this.etcScheduleDetail?.start_on).startOf('day');
        const scheduleStartDate = this.moment(startOn).startOf('day');

        if (scheduleStartDate.isSame(mainStartDate)) {
          // 메인 일정과 같은 날짜인 경우 시간만 표시
          dateTime = `${this.$filters.time(startOn)} ~ ${this.$filters.time(endOn)}`;
        } else {
          // 메인 일정과 다른 날짜인 경우 날짜와 시간 모두 표시
          dateTime = `${this.$filters.dateWithWeekdayWithTime(startOn)} ~ ${this.$filters.time(endOn)}`;
        }
      }

      return {
        title,
        dateTime,
      };
    },

    handleClickPrevEtc() {
      if (!this.etcScheduleDetail.etc_schedule_time_prev) return;
      this.$router.push(`/etc-schedule/detail?id=${this.etcScheduleDetail.etc_schedule_time_prev.id}`);
    },

    handleClickNextEtc() {
      if (!this.etcScheduleDetail.etc_schedule_time_next) return;
      this.$router.push(`/etc-schedule/detail?id=${this.etcScheduleDetail.etc_schedule_time_next.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-detail-header {
  background: #fff;
  width: 95%;
  max-width: 720px;
  margin: auto;
  padding: 24px 0 8px;

  @include mq(1024px) {
    max-width: 1400px;
    padding: 24px 60px 8px;
  }

  &__breadcrumb {
    position: relative;
  }

  &__private-tag-wrapper {
    display: inline-flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    border-radius: 4px;
    background: rgba(52, 58, 64, 0.52);
    padding: 0 6px;

    .private-text {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'button-left title button-right'
      '. tags .';
    grid-gap: 8px 0;
    place-items: center;

    @include mq(1024px) {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-rows: 64px 21px;
    }

    &__button {
      &.left {
        grid-area: button-left;
        justify-self: end;
      }
      &.right {
        grid-area: button-right;
        justify-self: start;
      }

      &__popover {
        @include flex(column, center, center);
        p + p {
          margin-top: 4px;
        }
      }
    }

    &__title {
      grid-area: title;
      display: grid;
      grid-gap: 8px;
      place-items: center;
      padding: 0 14px;

      h3 {
        font-size: 23px;
        text-align: center;

        @include mq(1024px) {
          @include ellipsis;
          width: 100%;
        }
      }

      p {
        color: rgba($charcoal-grey, 0.9);
        font-size: 15px;
      }
    }

    &__tags {
      grid-area: tags;

      span {
        height: 18px;
        border-radius: 4px;
        background: $deep-sky-blue;
        color: #fff;
        font-size: 12px;
        padding: 0 8px;
      }

      span + span {
        margin-left: 8px;
      }
    }
  }
}
</style>
