export const BOOKING_STATUS = {
  WAITING: 'booking_waiting',
  BOOKED: 'booked',
  CONFIRMED: 'booking_confirmed',
  ATTENDED: 'attendance',
  CHECKIN: 'checkIn',
  ABSENT: 'absence',
  NOSHOW: 'noshow',
  CANCEL: 'cancel',
};

export const BOOKING_STATUS_ARRAY = {
  BOOKED: [BOOKING_STATUS.WAITING, BOOKING_STATUS.BOOKED, BOOKING_STATUS.CONFIRMED],
  AFTER_CLASS: [BOOKING_STATUS.ATTENDED, BOOKING_STATUS.ABSENT, BOOKING_STATUS.NOSHOW],
  ABSENT: [BOOKING_STATUS.ABSENT, BOOKING_STATUS.NOSHOW],
};

export const BOOKING_STATUS_LABEL = {
  booking_waiting: '예약 대기',
  booking_pending: '예약 요청',
  booked: '예약',
  booking_confirmed: '예약 확정',
  attendance: '출석',
  absence: '결석',
  noshow: '노쇼',
  cancel: '취소',
};

export const COURSE_TYPE = {
  G: '그룹',
  P: '프라이빗',
  C: '코스',
};

export const PAYMENT_METHOD = {
  card: '카드',
  cash: '현금',
  mixed: '카드+현금',

  wire_transfer: '계좌이체',
  point: '포인트',
};

export const PAYMENT_STATUS = {
  purchase: '발급',
  upgrade: '업그레이드',
  refund: '환불',
  extension: '연장',
  transfer: '양도',
  tutorial: '체험',
  experience: '체험', // 기존 tutorial와의 변수명 차이로 새로 생성
  new: '신규결제',
  reNew: '재결제',
  're-take': '재결제', // 기존 reNew와의 변수명 차이로 새로 생성
  installment_payment: '미수금',
  full_payment: '미수금',
  delete: '삭제',
  change: '변경',
};

export const PAYMENT_VAR = {
  installment_payment: 'installment_payment',
  full_payment: 'full_payment',
  refund: 'refund',
  upgrade: 'upgrade',
  tutorial: 'tutorial',
  new: 'new',
  renew: 'renew',
  delete: 'delete',
  purchase: 'purchase',
  extension: 'extension',
  transfer: 'transfer',
};

export const WEEKDAYS = {
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
  0: '일',
};

export const COLORS = [
  'f95454',
  'ff99b9',
  'ff7e83',
  'ff8a54',
  'ffcb1f',
  '96d750',
  '27dead',
  '4bd4f8',
  '64aeff',
  '5464ad',
  'b286f0',
];

/**
 * 설정 > 역할별 권한 설정
 * 권한 트리 레이아웃 작성시 필요
 * -> 권한 아이디가 화면에 표시되는 순으로 되어 있지 않아서 필요한 정렬 기준
 */
// prettier-ignore
export const PERMISSION_ORDER = [
  101310, 101311, 101312, 101317, 101313, 101314, 101315, 101316, // 시설
  101410, 101411, 101412, 101413, 101414, 101415, 101416, 101430, 101431, 101432, 101433, 101420, 101417, 101418, 101419, // 회원 및 상담
  101510, 101511, 101512, // 수강권
  101207, 101208, 101209, 101225, 101210, // 수업 > 본인의 그룹 수업
  101211, 101212, 101213, 101226, 101214, // 수업 > 본인의 프라이빗 수업
  101216, 101215, 101217, 101218, 101227, 101219, // 수업 > 다른 스태프의 그룹 수업
  101221, 101220, 101222, 101223, 101228, 101224, // 수업 > 다른 스태프의 프라이빗 수업
  101252, 101253, 101254 // 수업 메모
];

export const NOTIFICATION_FILTER = {
  booking: '예약',
  lecture: '수업',
  counsel: '상담',
  ticket: '수강권',
  course: '클래스',
  board: '공지사항/문의',
};

/** 알림 타입별 클릭규 액션 */
export const NOTIFICATION_ACTION_TYPES = {
  'message.group.booking.member.staff': 'REDIRECT_TO_LECTURE',
  'message.group.booking.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.group.booking.batch.staff': 'REDIRECT_TO_NEAREST_LECTURE',
  'message.group.booking.full.staff': 'REDIRECT_TO_LECTURE',
  'message.group.cancel.member.staff': 'REDIRECT_TO_LECTURE',
  'message.group.cancel.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.group.wait.member.staff': 'REDIRECT_TO_LECTURE',
  'message.group.wait.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.group.wait_cancel.member.staff': 'REDIRECT_TO_LECTURE',
  'message.group.wait_cancel.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.group.change.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.group.change.time.staff': 'REDIRECT_TO_LECTURE',
  'message.group.change.title.staff': 'REDIRECT_TO_LECTURE',
  'message.group.change.daily.old': 'REDIRECT_TO_LECTURE',
  'message.group.change.daily.new': 'REDIRECT_TO_LECTURE',
  'message.group.delete.lecture.staff': 'REDIRECT_TO_DELETED_LECTURE_LIST',
  'message.group.delete.course.staff': 'REDIRECT_TO_DELETED_LECTURE_LIST',
  'message.change.course': 'REDIRECT_TO_DELETED_LECTURE_LIST',
  'message.fail.course': null,
  'message.group.delete.auto.staff': 'REDIRECT_TO_DELETED_LECTURE_LIST',
  'message.private.booking.member.staff': 'REDIRECT_TO_LECTURE',
  'message.private.booking.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.private.cancel.member.staff': 'REDIRECT_TO_LECTURE',
  'message.private.cancel.staff.staff': 'REDIRECT_TO_LECTURE',
  'message.private.delete.lecture.staff': 'REDIRECT_TO_DELETED_LECTURE_LIST',
  'message.change.staff': 'REDIRECT_TO_USER',
  'message.change.room': 'REDIRECT_TO_LECTURE',
  'message.counsel.insert.staff': 'REDIRECT_TO_COUNSEL',
  'message.counsel.change.staff': 'REDIRECT_TO_COUNSEL',
  'message.counsel.cancel.staff': 'REDIRECT_TO_COUNSEL',
  'message.ticket.staff': 'REDIRECT_TO_USER',
  'message.refund.staff': 'REDIRECT_TO_USER',
  'message.bonus.day.staff': 'REDIRECT_TO_TICKET',
  'message.bonus.cnt.staff': 'REDIRECT_TO_TICKET',
  'message.bonus.total.staff': 'REDIRECT_TO_TICKET',
  'message.reminder.ticket.expiration.staff': 'REDIRECT_TO_USERLIST',
  'message.reminder.ticket.expiration.member': 'REDIRECT_TO_USERLIST',
  'message.reminder.ticket.count.staff': 'REDIRECT_TO_USERLIST',
  'message.reminder.ticket.count.member': 'REDIRECT_TO_USERLIST',
  'message.enter.staff': 'REDIRECT_TO_LECTURE',
  'message.board.user.qna.create.staff': 'REDIRECT_TO_BOARD',
  'message.board.admin.notice.create.staff': null,
};

/** 알림 클릭시 액션의 레이블 */
export const NOTIFICATION_ACTION_LABELS = {
  REDIRECT_TO_LECTURE: '수업 보기',
  REDIRECT_TO_COUNSEL: '상담 목록 보기',
  REDIRECT_TO_NEAREST_LECTURE: '수업 보기',
  REDIRECT_TO_DELETED_LECTURE_LIST: '삭제된 수업 목록',
  REDIRECT_TO_USER: '회원 보기',
  REDIRECT_TO_USERLIST: '회원 목록',
  REDIRECT_TO_TICKET: '수강권 보기',
  REDIRECT_TO_BOARD: '문의글 보기',
};

/** 락커 상태 타입 */
export const LOCKER_STATE_TYPE = {
  USE: 'USE',
  IMPOSSIBLE: 'IMPOSSIBLE',
  DELETE: 'DELETE',
  UNUSE: 'UNUSE',
  ADD: 'ADD',
};

/** 설정 타이틀 */
export const ROUTE_LABELS = {
  SettingsRequired: '필수정보 설정',
  SettingsOperations: '운영정보 설정',
  SettingsPermissions: '역할별 권한 설정',
  SettingsRooms: '룸 설정',
  SettingsDivisions: '수업 구분 설정',
  SettingsUserGrades: '회원 등급 설정',
  SettingsServicePayments: '서비스 결제',
  SettingsServicePayments_tossRedirect: '서비스 결제',
  SettingsServicePayments_paymentInfo: '서비스 결제',
  SettingsPayments: '포인트 충전',
  SettingsAutoMessageNoti: '자동 알림 설정',
};

/** 상담 채널 타입 */
export const COUNSEL_CHANNEL_TYPES = {
  phone: '전화상담',
  visit: '방문상담',
  chat: '채팅상담',
  etc: '기타',
};

/** 게시판 타입 */
export const BOARD_TYPES = {
  NOTICE: 'notice',
  QNA: 'qna',
  STUDIO_NOTICE: 'studio-notice',
  STUDIO_NOTICE_DETAIL: 'studio-notice-detail',
};

export const TICKET_HISTORY_KIND = {
  count: '변경',
  created: '발급',
  deducted: '차감',
  payment: '결제',
  upgrade: '업그레이드',
  extension: '연장',
  batch: '일괄변경',
  text: '변경', // -> context 내용 그대로 출력
  installment_payment: '미수금',
  delete: '삭제',
  full_payment: '미수금',
  holding: '수강권정지',
  staff: '담당강사',
  transfer: '양도',
};

export const CONTENT_LABEL = {
  // 횟수 정보
  max_coupon: '전체횟수',
  remaining_coupon: '잔여횟수',
  // usable_coupon: '예약가능횟수', -> 사용자가 변경할 수 없는 항목
  remaining_cancel: '취소가능횟수',
  daily_booking_change_limit: '당일예약변경',
  booking_limit_per_week: '주간예약제한',
  booking_limit_per_month: '월간예약제한',

  //기간제 예약 취소 표시
  is_show_cancel_count: '취소가능표시',

  // 기간 정보
  availability_start_at: '이용시작일',
  expire_at: '이용종료일',
  start_holding: '정지시작일',
  end_holding: '정지종료일',

  // 사용여부 정보 (true/false)
  is_shared: '패밀리수강권',
  use_weekly_auto_coupon_balance: '자동차감',

  // 티켓타입 정보
  type: '수강권타입',

  // 결제정보
  amount: '결제금액',
  card_amount: '카드',
  installment_period: '할부개월수',
  cash_amount: '현금',
  wiretransfer_amount: '계좌이체',
  unpaid_amount: '미수금',
  settlement_at: '결제일',

  // 포인트
  point_amount: '포인트',
  reward_point: '포인트 적립',
};

export const TYPEOF = {
  // 횟수 정보
  max_coupon: 'count',
  remaining_coupon: 'count',
  remaining_cancel: 'count',
  daily_booking_change_limit: 'count',
  booking_limit_per_week: 'count',
  booking_limit_per_month: 'count',

  // 기간 정보
  availability_start_at: 'date',
  expire_at: 'date',
  start_holding: 'date',
  end_holding: 'date',

  // 사용여부 정보 (true/false)
  is_shared: 'boolean',
  use_weekly_auto_coupon_balance: 'boolean',
  is_show_cancel_count: 'boolean',

  // 티켓타입 정보
  type: 'ticketType',

  // 결제정보
  amount: 'price',
  card_amount: 'price',
  installment_period: 'installment_period',
  cash_amount: 'price',
  wiretransfer_amount: 'price',
  unpaid_amount: 'price',
  settlement_at: 'date',

  //포인트
  reward_point: 'point',
  point_amount: 'point',
};

export const POINT_TYPE = Object.freeze({
  reward: '적립',
  batch_reward: '적립 (일괄적립)',
  deduct: '차감',
  batch_deduct: '차감 (일괄차감)',
  refund: '환불',
  destruction: '소멸',
  mix: '혼합',
});

export const POINT_STATUS = Object.freeze({
  purchase: '발급',
  change: '변경',
  upgrade: '업그레이드',
  extension: '업그레이드',
  installment_payment: '미수금',
  full_payment: '미수금',
  refund: '환불',
  transfer: '양도',
});

export const POINT_TYPE_OPTIONS = Object.freeze([
  { label: '적립', value: 'save' },
  { label: '차감', value: 'remove' },
  { label: '소멸', value: 'destruction' },
]);

export const POINT_STATUS_OPTIONS = Object.freeze([
  { label: '발급', value: 'purchase' },
  { label: '변경', value: 'change' },
  { label: '업그레이드', value: 'upgrade' },
  { label: '미수금', value: 'installment_payment' },
  { label: '환불', value: 'refund' },
  { label: '양도', value: 'transfer' },
]);
