<template>
  <div>
    <div class="etc-schedule-detail-info" v-loading="loading">
      <div class="etc-schedule-detail-info__header">
        <h4>일정정보</h4>
        <div class="etc-schedule-detail-info__header__buttons">
          <button v-if="showEditButton" @click="handleClickEdit">
            수정
          </button>
          <button v-if="showDeleteButton" class="danger" @click="handleClickDelete">
            삭제
          </button>
        </div>
      </div>
      <div class="etc-schedule-detail-info__register">
        <label>일정 공유</label>
        <img :src="registerAvatar" alt="작성자 이미지" />
        <p>{{ sharedStaffs }}</p>
      </div>
      <div class="etc-schedule-detail-info__list">
        <label>공유 목록 ({{ staffsList.length }}명)</label>
        <ul>
          <li v-for="(staff, index) in staffsList" :key="staff.id">
            <img :src="staff.image" alt="강사 이미지" />
            <div class="staff-info-wrapper">
              <div class="staff-name-wrapper">
                <a class="name" @click="handleClickStaffName(staff.id)">{{ staff.name }}</a>
                <span class="role">{{ staff.role }}</span>
                <span v-if="index === 0" class="register-tag">작성자</span>
              </div>
              <span class="mobile">{{ staff.mobile }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <UpdateModeModal
      :show="showModal"
      :modalMode="modalMode"
      :onClose="handleCloseModal"
      @onEdit="handleEdit"
      @onDelete="handleDelete"
    />
  </div>
</template>

<script>
import UpdateModeModal from './UpdateModeModal.vue';
export default {
  components: { UpdateModeModal },
  data() {
    return {
      showModal: false,
      modalMode: 'edit',
      isDeleting: false,
    };
  },

  computed: {
    loading() {
      return this.$store.getters['etcSchedule/loading'];
    },
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    register() {
      return _.get(this.etcScheduleDetail, 'register') || '';
    },
    registerAvatar() {
      return this.staffImage(this.register.image);
    },
    staffsList() {
      if (!this.etcScheduleDetail) return [];
      const staffs = this.etcScheduleDetail.staffs.map(staff => ({
        ...staff,
        image: this.staffImage(staff.image),
        mobile: this.$filters.mobile(staff.mobile) ?? '-',
      }));
      const { id, name, role, mobile } = this.register;
      const register = {
        id,
        name,
        image: this.registerAvatar,
        role: role === 'studio_owner' ? '스튜디오 오너' : role,
        mobile: this.$filters.mobile(mobile) ?? '-',
      };
      return [register, ...staffs];
    },
    sharedStaffs() {
      if (this.staffsList.length === 1) return this.register.name;
      return `${this.register.name} 외 ${this.staffsList.length - 1}명`;
    },
    showEditButton() {
      /** 기타일정 수정/삭제는 작성자 본인만 가능 */
      return this.currentUser.id === this.etcScheduleDetail.register.id && this.canUpdateMyEtcSchedule;
    },
    showDeleteButton() {
      return this.currentUser.id === this.etcScheduleDetail.register.id && this.canDeleteMyEtcSchedule;
    },
  },

  methods: {
    staffImage(imgObj) {
      return this.$utils.getImageUrl({ image: imgObj }, '48x48');
    },
    handleClickStaffName(staffId) {
      if (!this.canViewStaff) {
        this.$utils.notify.error(this, '오류', '권한이 없습니다.');
        return;
      }
      this.$router.push(`/staffs/detail?id=${staffId}`);
    },
    handleClickEdit() {
      const isPastSchedule = this.moment(this.etcScheduleDetail.start_on).isBefore(this.moment());
      if (isPastSchedule) {
        this.handleEdit('single');
      } else {
        this.handleOpenEditModal();
      }
    },
    handleClickDelete() {
      this.handleOpenDeleteModal();
    },
    handleOpenEditModal() {
      this.modalMode = 'edit';
      this.showModal = true;
    },
    handleOpenDeleteModal() {
      this.modalMode = 'delete';
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    handleEdit(mode) {
      const url = `/etc-schedule/edit?mode=${mode}&id=${this.etcScheduleDetail.id}`;
      this.$router.push(url);
      this.handleCloseModal();
    },
    async handleDelete(mode) {
      this.handleCloseModal();
      const title = '기타 일정 삭제';
      const message = `일정을 삭제하시겠습니까?
        <br><span style="color: red; font-weight: bold;">삭제된 일정은 복구할 수 없습니다.</span>`;
      const proceed = await this.$confirm(message, title, {
        dangerouslyUseHTMLString: true,
      })
        .then(() => true)
        .catch(() => false);

      if (proceed) {
        this.isDeleting = true;
        try {
          const scheduleId = mode === 'single' ? this.etcScheduleDetail.id : this.etcScheduleDetail.etc_schedule_id;
          switch (mode) {
            case 'single':
              await this.$api.etcSchedule.delete(scheduleId);
              break;
            case 'bulk':
              await this.$api.etcSchedule.deleteBulk(scheduleId, {
                studio_id: this.currentUser.studio_id,
                staff_id: this.currentUser.id,
                start: this.etcScheduleDetail.start_on,
              });
              break;
          }
          this.$utils.notify.success(this, '성공', '일정이 삭제되었습니다.');
          this.$router.back();
        } catch (error) {
          if (error.response.status === 403) {
            const message = `기타 일정 삭제 권한이 없습니다.`;
            this.$utils.notify.error(this, '실패', message);
          } else {
            this.$utils.notify.parseError(this, error);
          }
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-detail-info {
  padding: 24px;
  height: 100%;

  @include mq(1024px) {
    border-right: 1px solid #f0f0f0;
  }

  label {
    color: rgba(#000, 0.4);
    font-size: 12px;
  }

  &__header {
    @include flex(row, center, space-between);
    flex-wrap: wrap;

    h4 {
      color: #000;
      font-size: 17px;
      font-weight: 400;
    }

    &__buttons {
      margin-left: auto;

      button {
        font-size: 12px;
        color: #329af0;
        padding: 4px;

        &.danger {
          color: $color-danger;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__register {
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-gap: 10px;
    justify-items: start;
    padding: 16px 0 24px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 12px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      grid-row: span 2;
    }

    p {
      color: rgba(#000, 0.9);
      font-size: 15px;
    }
  }

  &__list {
    ul {
      margin-top: 12px;
      @include flex(column);
      gap: 20px;
    }

    li {
      @include flex(row);
      gap: 20px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .staff-name-wrapper {
        @include flex(row, center);
        gap: 6px;
        margin-bottom: 4px;

        span {
          flex: 0 0 auto;
          font-weight: 400;

          &.name {
            font-size: 15px;
            color: rgba(#000, 0.9);
          }

          &.role {
            font-size: 11px;
            color: rgba(52, 58, 64, 0.6);
          }

          &.register-tag {
            width: 40px;
            height: 20px;
            line-height: 20px;
            padding: 0 6px;
            border-radius: 2px;
            background-color: rgba(71, 153, 242, 0.12);
            font-weight: 500;
            font-size: 10px;
            color: #4799f2;
          }
        }
      }
      .mobile {
        font-weight: 400;
        font-size: 14px;
        color: rgba(52, 58, 64, 0.6);
      }
    }
  }
}
</style>
