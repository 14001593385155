import axios from '../axios';

const PAYMENT_URL = '/v2/staff/token-payment';
const PAYMENT_POLICY_URL = '/v2/staff/payment-policy';
const TOSS_CARD_TYPE = {
  yearly: 'toss_brand_pay',
  monthly: 'toss_bill_pay',
  point: 'toss_brand_bill_pay',
};

export default {
  getSmsProducts: () => {
    return axios.get(`${PAYMENT_URL}/point/products`);
  },
  getServicePlanProducts: () => axios.get(`${PAYMENT_URL}/service/products`),
  getCards: type => axios.get(`${PAYMENT_URL}/card`, { params: { pg_type: ['ks_pay', TOSS_CARD_TYPE?.[type]] } }),
  getTossCardCount: () => axios.get(`${PAYMENT_URL}/card/toss/has-card-count`), // 연결제용 토스 카드 등록 갯수
  getCardPromotions: () => axios.get(`${PAYMENT_URL}/card/toss/promotions`), // 토스 무이자할부혜택

  getOrderId: productId => axios.get(`${PAYMENT_URL}/service/toss/order-id/${productId}`), // 토스 연결제용
  getBrandPayAgreement: params => axios.get(`${PAYMENT_URL}/card/toss/brand-pay-agree`, { params }), // 토스 포인트충전용 약관동의

  registCardApi: body => axios.post(`${PAYMENT_URL}/card/add-token-card`, body),
  registCardForMonthlyServiceWithTossBilling: body => axios.post(`${PAYMENT_URL}/card/add-token-card/toss-billing`, body),
  tossBrandPayCardRefresh: () => axios.post(`${PAYMENT_URL}/card/add-token-card/toss-brand-billing`), // 토스 브랜드페이로 카드 등록한 경우 대비 백엔드에서 갱신하도록

  deleteCard: id => axios.delete(`${PAYMENT_URL}/card/${id}`), // DELETE

  confirmYearPayment: ({ paymentKey, amount, orderId, subscriptionId, chargeNotificationNumber }) =>
    axios.post(`${PAYMENT_URL}/service/toss/payments/confirm/${subscriptionId}`, {
      paymentKey,
      amount,
      orderId,
      subscription_payment_notification_number: chargeNotificationNumber, // optional
    }),

  requestPayNow: ({ point_id, card_id }, body) => {
    return axios
      .post(`${PAYMENT_URL}/point/${card_id}/${point_id}`, body)
      .then(response => response)
      .catch(error => error.response);
  }, //포인트 단건 결제
  requestPayment: ({ subscription_id, card_id }, body) =>
    axios.post(`${PAYMENT_URL}/service/${card_id}/${subscription_id}`, body), // 서비스 단건 결제
  // 결제 정보
  getPaymentPolicy: () => axios.get(`${PAYMENT_POLICY_URL}`), // GET
  patchPaymentPolicy: body => axios.patch(`${PAYMENT_POLICY_URL}`, body), // PATCH
  getSMSPaymentHistory: params =>
    axios.get(`${PAYMENT_URL}/point`, {
      params,
    }),
  getServicePaymentHistory: params =>
    axios.get(`${PAYMENT_URL}/service`, {
      params,
    }),

  getServicePaymentInfo: () => axios.get(`${PAYMENT_URL}/service/payment`),
};
