<template>
  <li class="memo-item">
    <div class="memo-item__meta">
      <span>{{ memo.updated_at | datetime }}</span>
      <span>{{ staffName }}</span>
    </div>

    <div class="memo-item__buttons">
      <PlainButton v-if="hasDeleteMemoPermission" @click="$emit('delete', memo)" type="danger" round>삭제</PlainButton>
      <PlainButton v-if="hasUpdateMemoPermission" @click="$emit('edit', memo)" round>수정</PlainButton>
    </div>

    <div v-if="isEditing" class="memo-item__content">
      <div class="memo-item__content__input" :class="{ focus: inputFocused }" v-loading="isImageUploading">
        <Textarea ref="memo-editing" v-model="text" @focus="inputFocused = true" @blur="inputFocused = false" required />

        <div class="memo-item__content__input-images" :class="{ 'no-image': !attachments.length }">
          <div class="memo-item__content__input-images__item" v-for="attachment in attachments" :key="attachment.id">
            <img :src="getImageUrl(attachment.path, 0)" alt="memo image" />
            <CircleButton @click="handleClickRemoveImage(attachment.id)" size="small">
              <i class="el-icon-close"></i>
            </CircleButton>
          </div>
        </div>
      </div>

      <div class="memo-item__content__buttons">
        <div class="memo-item__content__buttons__attach-image">
          <PlainButton @click="handleClickAttachImage" type="info" :disabled="attachments.length >= attachmentLimit" round
            >사진 추가</PlainButton
          >
          <input ref="attachImage" type="file" accept=".jpg, .jpeg, .png" @change="handleChangeImage" />
        </div>
        <div class="memo-item__content__buttons__base">
          <PlainButton @click="$emit('cancel-edit')" type="danger" round>취소</PlainButton>
          <BaseButton @click="$emit('save')" :disabled="saveButtonDisabled" round>저장</BaseButton>
        </div>
      </div>
    </div>

    <div v-else class="memo-item__content">
      <div class="memo-item__content__memo" :class="{ expand, overflow: memoContentOverflow }">
        <p ref="memoContent" v-html="memo.memo" />
      </div>
      <div v-if="!!memo.attachments.length" class="memo-item__content__images" :class="{ expand }">
        <img
          v-for="attachment in memo.attachments"
          :key="attachment.id"
          :src="getImageUrl(attachment.path, !expand ? 48 : 0)"
          alt="memo image"
        />
      </div>
    </div>

    <PlainButton v-if="showExpandButton" class="memo-item__expand" @click="toggleExpand" type="info" round>{{
      expand ? '메모 접기' : '메모 열기'
    }}</PlainButton>
  </li>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ memo: '', attachments: [], attachment: [] }),
    },
    memo: { type: Object, required: true },
    isEditing: { type: Boolean, default: false },
    attachmentLimit: { type: Number, default: 3 },
    canUpdateMemo: { type: Boolean, default: false },
    canDeleteMemo: { type: Boolean, default: false },
  },

  data() {
    return {
      inputFocused: false,
      expand: false,
      memoContentOverflow: false,
      isImageUploading: false,
    };
  },

  computed: {
    text: {
      get() {
        return this.value.memo || '';
      },
      set(memo) {
        this.$emit('input', { ...this.value, memo });
      },
    },

    attachments: {
      get() {
        return this.value.attachments || [];
      },
      set(attachments) {
        this.$emit('input', {
          ...this.value,
          attachments,
          attachment: attachments.map(({ id }) => id),
        });
      },
    },

    staffName() {
      const staffName = _.get(this.memo, 'staff.name', '');
      const roleName = _.get(this.memo, 'roles', '');

      return this.memo.staff ? `${staffName} ${roleName}` : '시스템';
    },

    /**
     * 1. 수정중이 아니고,
     * 2. 본인이 스튜디오 오너 또는 본인 메모는 역할별 권한설정에서 메모 수정, 삭제에 체크되어 있는지 (본인 메모가 아니면 권한 상관없이 수정/삭제 불가)
     */
    hasUpdateMemoPermission() {
      return !this.isEditing && (this.isStudioOwner || (this.memo.staff?.id === this.currentUser.id && this.canUpdateMemo));
    },

    hasDeleteMemoPermission() {
      return !this.isEditing && (this.isStudioOwner || (this.memo.staff?.id === this.currentUser.id && this.canDeleteMemo));
    },

    showExpandButton() {
      return !this.isEditing && (this.memoContentOverflow || this.memo.attachments.length);
    },

    saveButtonDisabled() {
      return this.value && !this.value.memo && !this.value.attachment.length;
    },
  },

  watch: {
    isEditing(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs['memo-editing'].$el.focus();
        });
      }
    },

    'value.memo': {
      handler() {
        this.$nextTick(() => {
          const element = this.$refs.memoContent;
          this.memoContentOverflow = element && element.clientHeight < element.scrollHeight;
        });
      },
      immediate: true,
    },
  },

  methods: {
    handleClickAttachImage() {
      this.$refs.attachImage.click();
    },

    handleClickRemoveImage(imageId) {
      this.attachments = this.attachments.filter(({ id }) => id !== imageId);
    },

    toggleExpand() {
      this.expand = !this.expand;
    },

    getImageUrl(path, size) {
      return this.$utils.getImagePath(path, size);
    },

    /** 이미지 추가 */
    async handleChangeImage(e) {
      this.isImageUploading = true;
      const file = _.get(e, 'target.files[0]');

      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          this.isImageUploading = false;
          return this.$utils.notify.error(this, '오류', '이미지 사이즈는 2MB까지 가능합니다.');
        }

        try {
          let convertedFile;
          if (file.type === 'image/png') {
            await this.$utils.convertPNGToJPEG(file).then(jpgFile => {
              convertedFile = jpgFile;
            });
          }

          const formData = new FormData();
          formData.append('files[]', convertedFile || file);
          const res = await this.$api.attachment.upload(formData);
          const uploaded = _.get(res, 'data[0]');
          if (uploaded) {
            this.attachments = [...this.attachments, uploaded];
          }
          e.target.value = null;
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        }
      }
      this.isImageUploading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.memo-item {
  border: solid 1px #cccccc;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 104px;
  grid-gap: 16px;
  align-items: start;
  padding: 16px;
  margin-bottom: 24px;
  position: relative;

  &__meta {
    display: grid;
    grid-gap: 8px;
    font-size: 12px;
    color: #666666;
  }

  &__buttons {
    display: inline-flex;
    gap: 8px;
    justify-content: flex-end;
  }

  &__content {
    grid-column: span 2;
    font-size: 12px;
    color: #333333;
    @include flex(column);

    &__input {
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;

      &.focus {
        border-color: $deep-sky-blue;
      }

      textarea {
        border: none;
      }
    }

    &__input-images {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
      padding: 16px;

      &.no-image {
        padding: 0;
      }

      &__item {
        position: relative;

        img {
          width: 100%;
        }

        button {
          background: $gray-500;
          color: #fff;
          font-size: 16px;
          position: absolute;
          top: -12px;
          right: -12px;

          &:hover {
            background: $gray-200;
            color: $charcoal-grey;
          }
        }
      }
    }

    &__memo {
      position: relative;
      padding-bottom: 8px;

      p {
        max-height: 58px;
        overflow: hidden;
        white-space: pre-line;
      }

      &.overflow {
        &::after {
          content: '...';
          position: absolute;
          bottom: -4px;
          left: 0;
        }
      }

      &.expand {
        p {
          max-height: none;
        }

        &::after {
          display: none;
        }
      }
    }

    &__images {
      display: grid;
      grid-template-columns: repeat(3, 48px);
      grid-gap: 24px;
      padding: 24px 0 0;

      &.expand {
        grid-template-columns: 1fr;
        padding: 24px 0;
      }

      img {
        width: 100%;
      }
    }

    &__buttons {
      @include flex(row, center, space-between);
      margin-top: 16px;

      button + button {
        margin-left: 8px;
      }

      &__base {
        button {
          width: 48px;
        }
      }

      &__attach-image {
        position: relative;
        width: 93px;
        overflow: hidden;

        input {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }
  }

  &__expand {
    background: #fff;
    position: absolute;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      background: $gray-200 !important;
    }
  }
}
</style>
