<template>
  <el-dialog class="create-lesson-modal" :visible.sync="show" :before-close="onClose">
    <div slot="title" class="create-lesson-modal__title">
      <h3>일정등록</h3>
    </div>

    <div class="create-lesson-modal__body">
      <a @click="handleClickCreatePrivate(payload)" :class="{ disabled: !canCreatePrivateLecture() }">
        <div>
          <h5>프라이빗 수업</h5>
          <p>개인/듀엣/트리플 레슨 (예약 필수)</p>
          <p v-if="!canCreatePrivateLecture()" class="no-permission">권한없음</p>
        </div>
      </a>
      <a
        @click="handleClickCreateGroup(payload)"
        :style="checkDisabled && 'content: none;'"
        :class="{ disabled: !canCreateGroupLecture() || checkDisabled }"
      >
        <div>
          <h5>그룹 수업</h5>
          <p>고정된 스케쥴의 오픈형 수업 과정 (자유 수강형/예약 필수)</p>
          <p v-if="!canCreateGroupLecture() && !checkDisabled" class="no-permission">권한없음</p>
          <p class="period-limit" v-if="checkDisabled">선택한 기간에 수업을 생성할 수 없습니다.</p>
        </div>
      </a>
      <a @click="handleClickCreateCounseling(payload)" :class="{ disabled: !canCreateCounsel }">
        <div>
          <h5>상담</h5>
          <p>전화/방문/채팅/기타 상담</p>
          <p v-if="!canCreateCounsel" class="no-permission">권한없음</p>
        </div>
      </a>
      <a @click="handleClickCreateMyEtcSchedule(payload)" :class="{ disabled: !canCreateMyEtcSchedule }">
        <div>
          <h5>기타 일정</h5>
          <p>수업 외 일정</p>
          <p v-if="!canCreateMyEtcSchedule" class="no-permission">권한없음</p>
        </div>
      </a>
    </div>

    <div class="create-lesson-modal__footer">
      <p>
        수업/클래스란?<br />
        수업은 말 그대로 하루 한 회차의 수업을 의미하며, 그런 수업들이 모여<br />
        이루어진 프로그램을 일컬어 클래스라 칭합니다.
      </p>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    onClose: Function,
    payload: {},
    checkDisabled: Boolean,
  },

  methods: {
    handleClickCreatePrivate(payload) {
      if (this.canCreatePrivateLecture()) {
        // this.$router.push('/course/private/create');
        let path = '/course/private/create';
        path += Object.entries(payload).reduce((str, entry, index) => {
          const [key, value] = entry;
          const separator = index === 0 ? '?' : '&';
          str = `${str}${separator}${key}=${value}`;
          return str;
        }, '');
        this.$router.push(path);
      }
    },

    handleClickCreateGroup(payload) {
      if (this.canCreateGroupLecture()) {
        // this.$router.push('/course/group/create');
        let path = '/course/group/create';
        path += Object.entries(payload).reduce((str, entry, index) => {
          const [key, value] = entry;
          const separator = index === 0 ? '?' : '&';
          str = `${str}${separator}${key}=${value}`;
          return str;
        }, '');

        this.$router.push(path);
      }
    },

    handleClickCreateCounseling(payload) {
      if (this.canCreateCounsel) {
        const { start_on, end_on, instructor_id } = payload;
        this.$router.push({
          name: 'counsels',
          params: {
            visitCounseling: true,
            instructor_id,
          },
          query: {
            start_on,
            end_on,
          },
        });
      }
    },

    handleClickCreateMyEtcSchedule(payload) {
      if (this.canCreateMyEtcSchedule) {
        let path = '/etc-schedule/create';
        path += Object.entries(payload).reduce((str, entry, index) => {
          const [key, value] = entry;
          const separator = index === 0 ? '?' : '&';
          str = `${str}${separator}${key}=${value}`;
          return str;
        }, '');

        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-lesson-modal {
  @include flex(row, center, center);

  /deep/ .el-dialog {
    width: 90%;
    max-width: 368px;
  }

  &__body {
    a {
      cursor: pointer;

      &:not(:last-child) div {
        border-bottom: 1px solid #eee;
      }

      &:hover div {
        background: #eee;
      }

      &.disabled {
        cursor: default;
        pointer-events: none;

        div {
          background: #ddd;
          position: relative;

          .no-permission {
            color: $color-danger;
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }

      div {
        padding: 20px 0;
        transition: 0.15s;

        h5 {
          font-size: 14px;
          padding: 2px 10px;
        }

        p {
          font-size: 11px;
          padding: 2px 10px;
        }

        .period-limit {
          color: red;
        }
      }
    }
  }

  &__footer {
    background: rgba(250, 251, 251, 0.5);
    border: 1px solid rgba(#000, 0.06);
    border-radius: 2px;
    margin-top: 15px;
    padding: 15px;

    p {
      font-size: 10px;
    }
  }
}
</style>
