<template
  ><div class="etc-schedule-date-form">
    <div class="etc-schedule-date-form__datetime__wrapper">
      <div class="etc-schedule-date-form__datetime">
        <template v-if="!isEditMode">
          <label>날짜</label>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="~"
            start-placeholder="시작일"
            end-placeholder="종료일"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :picker-options="daterangePickerOptions"
            :clearable="false"
            required
          />
        </template>
        <template v-if="editMode === 'single'">
          <label>날짜</label>
          <el-date-picker
            ref="endDate"
            v-model="endDate"
            type="date"
            format="yyyy. M. d."
            value-format="yyyy-MM-dd"
            :clearable="false"
            required
          />
        </template>
        <div v-if="editMode === 'bulk'" class="etc-schedule-date-form__datetime__bulk">
          <div>
            <label>시작일</label>
            <el-date-picker
              ref="start"
              v-model="start"
              type="date"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :clearable="false"
              required
              disabled
            />
          </div>
          <div>
            <label>종료일</label>
            <el-date-picker
              ref="tempEndDate"
              v-model="tempEndDate"
              type="date"
              format="yyyy. M. d."
              value-format="yyyy-MM-dd"
              :clearable="false"
              :picker-options="bulkEndDatePickerOptions"
              required
              @change="handleEndDateChange"
            />
          </div>
        </div>
      </div>
      <div class="etc-schedule-date-form__datetime">
        <hours-input
          ref="lectureTime"
          v-model="time"
          startLabel="시작 시간"
          :startHourOptions="{
            start: '00:00',
            end: '23:50',
            step: '00:05',
          }"
          endLabel="종료 시간"
          :endHourOptions="{
            start: time.start || '00:00',
            end: '23:55',
            step: '00:05',
            minTime: time.start,
          }"
          :clearable="false"
        />
      </div>
      <el-button
        class="etc-schedule-date-form__datetime__button"
        :disabled="!time.start"
        size="small"
        type="info"
        @click="setEndTime(30)"
        >30분</el-button
      >
      <el-button
        class="etc-schedule-date-form__datetime__button"
        :disabled="!time.start"
        size="small"
        type="info"
        @click="setEndTime(50)"
        >50분</el-button
      >
      <el-button
        class="etc-schedule-date-form__datetime__button hour"
        :disabled="!time.start"
        size="small"
        type="info"
        @click="setEndTime(60)"
        >1시간</el-button
      >
    </div>
    <div v-if="showRepeat" class="etc-schedule-date-form__repeat__wrapper">
      <div class="etc-schedule-date-form__repeat">
        <label>반복 설정</label>
        <el-select
          v-model="repeat"
          :placeholder="disabledRepeat ? '반복 안함' : '반복 설정'"
          :disabled="disabledRepeat"
          filterable
          clearable
          @change="handleRepeatChange"
        >
          <el-option v-for="options in repeatOptions" :key="options.value" :value="options.value" :label="options.label" />
        </el-select>
      </div>
      <div class="etc-schedule-date-form__repeat__custom" v-if="repeat === 'custom'">
        <ul class="etc-schedule-date-form__weekday-buttons">
          <li v-for="weekday in weekdaysList" :key="weekday">
            <el-button
              size="small"
              circle
              :type="isWeekdaySelected(weekday) ? 'primary' : 'default'"
              @click="handleClickWeekday(weekday)"
              >{{ weekday }}</el-button
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- 종료일 변경 확인 다이얼로그 -->
    <el-dialog
      class="etc-schedule-date-form__end-date-confirm-modal"
      title="종료일 변경"
      :visible.sync="showConfirmChangeEndDate"
      :before-close="handleCloseDialog"
    >
      <span>종료일 변경 시, 종료일 이후의 일정은 삭제됩니다. 계속 하시겠습니까?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelEndDateChange">취소</el-button>
        <el-button type="primary" @click="handleConfirmEndDateChange">확인</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const { moment } = this;
    const now = moment();
    const nextHour = now.minutes() > 0 ? now.add(1, 'hour') : now;

    return {
      start: moment().format('YYYY-MM-DD'),
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      tempEndDate: moment().format('YYYY-MM-DD'),
      showConfirmChangeEndDate: false, // 종료일 변경 확인 다이얼로그 노출 여부
      startTime: nextHour.startOf('hour').format('HH:mm'),
      endTime: nextHour
        .startOf('hour')
        .add(30, 'minutes')
        .format('HH:mm'),
      daterange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      weekdaysList: ['월', '화', '수', '목', '금', '토', '일'],
      weekdays: [],
      repeat: null,
      repeatOptions: [
        { label: '매주 월요일', value: 'monday' },
        { label: '주중 매일 (월~금)', value: 'weekdays' },
        { label: '맞춤 설정', value: 'custom' },
      ],
    };
  },

  computed: {
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    time: {
      get() {
        return {
          start: this.startTime,
          end: this.endTime,
        };
      },
      set(time) {
        if (!time.start || !time.end) return;
        this.startTime = time.start;
        this.endTime = time.end;
      },
    },
    isEditMode() {
      return !!this.$route.query.id;
    },
    editMode() {
      return this.$route.query.mode;
    },
    disabledRepeat() {
      return this.startDate === this.endDate;
    },
    bulkEndDatePickerOptions() {
      return {
        disabledDate: date => {
          const formattedDate = this.moment(date).format('YYYY-MM-DD');
          return formattedDate < this.start;
        },
      };
    },
    daterangePickerOptions() {
      return {
        disabledDate: date => {
          const baseDate = this.startDate ? this.moment(this.startDate) : this.moment();
          const aMonthAgo = baseDate.clone().subtract(1, 'months');
          const aYearLater = baseDate.clone().add(1, 'year');
          return !this.moment(date).isBetween(aMonthAgo, aYearLater, null, '[]');
        },
      };
    },
    showRepeat() {
      if (this.editMode === 'single') return false;
      return true;
    },
  },

  watch: {
    daterange() {
      if (this.daterange && this.daterange.length === 2) {
        this.startDate = this.daterange[0];
        this.endDate = this.daterange[1];

        if (this.startDate === this.endDate) {
          this.weekdays = [];
          this.repeat = null;
        }
        this.emitDateChange();
      }
    },

    endDate: {
      handler(newVal, oldVal) {
        if (newVal === this.startDate) {
          this.weekdays = [];
          this.repeat = null;
        }

        if (newVal !== oldVal) {
          this.emitDateChange();
        }
      },
    },

    time: {
      deep: true,
      handler() {
        this.emitDateChange();
      },
    },

    /** 수정 페이지에서 날짜 시간 초기화 */
    etcScheduleDetail: {
      deep: true,
      handler(newValue) {
        const { start_on, end_on, etc_schedule } = newValue;
        const startDate = this.editMode === 'single' ? start_on : etc_schedule.start_date;
        const endDate = this.editMode === 'single' ? end_on : etc_schedule.end_date;
        this.start = this.moment(start_on).format('YYYY-MM-DD');
        this.handleInitWeekdays(etc_schedule);
        this.handleInitDateTime({ startDate, endDate, startTime: start_on, endTime: end_on });
      },
    },
  },

  async created() {
    if (this.$route.query) {
      /* 생성 시 달력에서 특정날짜 클릭하고 온 경우 초기 날짜 세팅 */
      const { start_on, end_on } = this.$route.query;
      if (start_on && end_on) {
        this.handleInitDateTime({ startDate: start_on, endDate: end_on, startTime: start_on, endTime: end_on });
      }
    }
  },

  methods: {
    emitDateChange() {
      this.$emit('handleChangeDate', {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        weekdays: this.weekdays,
      });
    },

    handleInitDateTime({ startDate, endDate, startTime, endTime }) {
      this.startDate = this.moment(startDate).format('YYYY-MM-DD');
      this.endDate = this.moment(endDate).format('YYYY-MM-DD');
      this.tempEndDate = this.moment(endDate).format('YYYY-MM-DD');
      this.startTime = this.moment(startTime).format('HH:mm');
      this.endTime = this.moment(endTime).format('HH:mm');
      this.daterange = [this.startDate, this.endDate];
      this.emitDateChange();
    },

    handleInitWeekdays(etc_schedule) {
      if (!etc_schedule.weekday || etc_schedule.weekday.length === 7 || etc_schedule.weekday.length === 0) {
        this.repeat = null;
      } else if (etc_schedule.weekday.length === 1 && etc_schedule.weekday[0] === 1) {
        this.repeat = 'monday';
      } else if (etc_schedule.weekday.length === 5 && _.isEqual(etc_schedule.weekday, [1, 2, 3, 4, 5])) {
        this.repeat = 'weekdays';
      } else {
        this.repeat = 'custom';
      }
      this.weekdays = etc_schedule.weekday || [];
    },

    handleEndDateChange(date) {
      if (this.editMode !== 'bulk') return;

      this.tempEndDate = date;

      const originalEndDate = this.moment(_.get(this.etcScheduleDetail, 'etc_schedule.end_date'));
      const isSelectBeforeOriginalEndDate = this.moment(date).isBefore(originalEndDate); // 기존 코스 종료일보다 이전 날짜를 선택했을 때만 다이얼로그 노출
      if (isSelectBeforeOriginalEndDate) {
        this.showConfirmChangeEndDate = true;
      }
    },

    // 종료일 변경 확인
    handleConfirmEndDateChange() {
      this.endDate = this.tempEndDate;
      this.showConfirmChangeEndDate = false;
      this.emitDateChange();
    },

    // 종료일 변경 취소
    handleCancelEndDateChange() {
      this.tempEndDate = this.endDate;
      this.showConfirmChangeEndDate = false;
    },

    // 다이얼로그 닫기 핸들러
    handleCloseDialog() {
      this.handleCancelEndDateChange();
    },

    setEndTime(duration) {
      const [hour, minute] = this.time.start.split(':');
      let endTimeObj = this.moment()
        .hour(parseInt(hour))
        .minute(parseInt(minute))
        .second(0)
        .add(duration, 'minutes');

      this.endTime = endTimeObj.format('HH:mm');

      // 자정을 넘어가는 경우 23:59로 설정
      if (this.endTime === '00:00') {
        this.endTime = '23:59';
      }

      this.emitDateChange();
    },

    /** 요일 텍스트를 숫자로 변환 (월=1, 화=2, ..., 일=0) */
    getWeekdayNum(weekday) {
      const weekdayIndex = this.weekdaysList.indexOf(weekday);
      return weekdayIndex + 1 === 7 ? 0 : weekdayIndex + 1;
    },

    // 반복설정 특정 요일 선택 스타일용
    isWeekdaySelected(weekday) {
      const weekdayNum = this.getWeekdayNum(weekday);
      return this.weekdays.includes(weekdayNum);
    },

    /** 반복설정 셀렉 옵션 선택 시  */
    handleRepeatChange(repeat) {
      if (repeat === 'monday') {
        this.weekdays = [1];
      } else if (repeat === 'weekdays') {
        this.weekdays = [1, 2, 3, 4, 5];
      } else if (repeat === 'custom') {
        this.weekdays = [];
      }
      this.emitDateChange();
    },

    /** 요일 선택시 */
    handleClickWeekday(weekday) {
      const weekdayNum = this.getWeekdayNum(weekday);
      const index = this.weekdays.indexOf(weekdayNum);

      if (index === -1) {
        this.weekdays.push(weekdayNum);
      } else {
        this.weekdays.splice(index, 1);
      }

      this.emitDateChange();
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-date-form {
  &__datetime__wrapper {
    @include flex(row);
    flex-wrap: wrap;
  }

  &__datetime {
    @include flex(column);
    margin-top: 10px;

    &__bulk {
      @include flex(row);
      gap: 20px;

      > div {
        @include flex(column);
      }
    }

    .el-date-editor--daterange {
      width: 280px;
      padding: 3px 0;

      /deep/ .el-icon-date {
        width: 30px;
        height: 30px;
        margin-left: 0;
      }

      /deep/ .el-range-separator {
        line-height: 27px;
      }

      /deep/ .el-range-input {
        width: 100px;
      }

      /deep/ .el-range__close-icon {
        display: none;
      }
    }

    label {
      font-size: 12px;
      font-weight: 500;
    }

    .hours-input {
      padding: 0;

      @include mq(768px) {
        padding: 0 20px;
      }
    }

    &__button {
      align-self: center;
      margin-top: 10px;

      &.hour {
        margin-right: 10px;
      }
    }
  }

  &__repeat__wrapper {
    @include flex(column);
    margin-top: 30px;
    width: 200px;

    /deep/ .el-select {
      width: 100%;
    }

    /deep/ .el-input {
      max-width: 200px;
    }

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__weekday-buttons {
    @include flex(row);
    padding: 12px 0;

    li + li {
      margin-left: 5px;
    }
  }

  /deep/ &__end-date-confirm-modal {
    .el-dialog {
      width: 480px;
      top: 50%;
      margin-top: -100px !important;
    }
  }
}
</style>
