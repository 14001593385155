<template>
  <MainLayout :borderTop="`#${etcScheduleColor}`" :padded="false">
    <Header />

    <section class="etc-schedule-detail__body">
      <ArrowTooltipButton
        bordered
        direction="prev"
        :disabled="!etcScheduleDetail.etc_schedule_prev"
        :tooltipInfo="prevTooltipInfo"
        @click="handleClickPrevEtcInCourse"
      />
      <div class="etc-schedule-detail__body__content">
        <DetailInfo />
        <EtcScheduleMemo />
      </div>
      <ArrowTooltipButton
        bordered
        direction="next"
        :disabled="!etcScheduleDetail.etc_schedule_next"
        :tooltipInfo="nextTooltipInfo"
        @click="handleClickNextEtcInCourse"
      />
    </section>
  </MainLayout>
</template>

<script>
import Header from '@/components/EtcScheduleDetail/Header';
import ArrowTooltipButton from '@/components/EtcScheduleDetail/ArrowTooltipButton.vue';
import DetailInfo from '@/components/EtcScheduleDetail/DetailInfo.vue';
import EtcScheduleMemo from '@/components/EtcScheduleDetail/EtcScheduleMemo.vue';

export default {
  components: {
    Header,
    ArrowTooltipButton,
    DetailInfo,
    EtcScheduleMemo,
  },

  computed: {
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    etcScheduleColor() {
      return this._.get(this.etcScheduleDetail, 'color') || '000';
    },
    prevTooltipInfo() {
      const prevInfo = this.etcScheduleDetail.etc_schedule_prev;
      const dateTime = prevInfo
        ? `${this.$filters.dateWithWeekdayWithTime(prevInfo.start_on)} ~ ${this.$filters.time(prevInfo.end_on)}`
        : '';
      return {
        title: '이전 반복 일정',
        dateTime,
      };
    },
    nextTooltipInfo() {
      const nextInfo = this.etcScheduleDetail.etc_schedule_next;
      const dateTime = nextInfo
        ? `${this.$filters.dateWithWeekdayWithTime(nextInfo.start_on)} ~ ${this.$filters.time(nextInfo.end_on)}`
        : '';
      return {
        title: '다음 반복 일정',
        dateTime,
      };
    },
  },

  watch: {
    '$route.query': {
      handler: 'loadData',
      immediate: true,
    },
  },

  methods: {
    async loadData() {
      await this.$store.dispatch('etcSchedule/getEtcDetail', this.$route.query.id);
      await this.$store.dispatch('etcSchedule/getMemos', this.$route.query.id);
      if (!this.etcScheduleDetail.id) {
        this.$utils.notify.error(this, '오류', '일정이 존재하지 않습니다.');
        return this.$router.push('/schedule');
      }
    },

    handleClickPrevEtcInCourse() {
      if (!this.etcScheduleDetail.etc_schedule_prev) return;
      this.$router.push(`/etc-schedule/detail?id=${this.etcScheduleDetail.etc_schedule_prev.id}`);
    },

    handleClickNextEtcInCourse() {
      if (!this.etcScheduleDetail.etc_schedule_next) return;
      this.$router.push(`/etc-schedule/detail?id=${this.etcScheduleDetail.etc_schedule_next.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-detail {
  &__body {
    @include flex(row, flex-start, center);
    width: 95%;
    margin: 8px auto 10px;

    .etc-schedule-detail-arrow__button {
      min-width: 36px;
      margin-top: 48px;

      &.prev {
        margin-right: 12px;
      }
      &.next {
        margin-left: 12px;
      }

      @include mq(568px) {
        &.prev {
          margin-right: 24px;
        }

        &.next {
          margin-left: 24px;
        }
      }

      @include mq(1024px) {
        margin-top: 35vh;
      }

      img {
        width: 18px;
        height: 18px;
      }

      &__popover {
        @include flex(column, center, center);
        p + p {
          margin-top: 4px;
        }
      }
    }

    &__content {
      border-radius: 2px;
      box-shadow: 0 8px 20px -16px rgba(#000, 0.5), 0 0 20px 0 rgba(#000, 0.03);
      background: #fff;
      width: 100%;
      max-width: 600px;

      @include mq(1024px) {
        max-width: 1280px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: calc(100vh - 230px);
      }
    }
  }
}
</style>
