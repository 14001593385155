import api from '@api';

const DEFAULT_DETAIL = {
  staffs: [],
  register: {
    image: { host: '', name: '', path: '' },
  },
};

export const state = {
  loading: false,
  detail: { ...DEFAULT_DETAIL },
  etcCourse: {},
  memosLoading: false,
  memos: [],
};

export const getters = {
  loading: state => state.loading,
  detail: state => state.detail,
  etcCourse: state => state.etcCourse,
  memosLoading: state => state.memosLoading,
  memos: state => state.memos,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_DETAIL(state, detail) {
    state.detail = {
      ...detail,
    };
  },

  SET_COURSE_DETAIL(state, etcCourse) {
    state.etcCourse = {
      ...etcCourse,
    };
  },

  SET_MEMOS_LOADING(state, loading) {
    state.memosLoading = loading;
  },

  SET_MEMOS(state, memos) {
    state.memos = [...memos];
  },
};

export const actions = {
  async getEtcDetail({ commit }, etcScheduleId) {
    try {
      commit('SET_LOADING', true);
      const res = await api.etcSchedule.get(etcScheduleId);
      commit('SET_DETAIL', res.data);
    } catch (error) {
      commit('SET_DETAIL', DEFAULT_DETAIL);
    } finally {
      commit('SET_LOADING', false);
    }

    return;
  },

  async getEtcCourseDetail({ commit }, etcScheduleCourseId) {
    try {
      commit('SET_LOADING', true);
      const res = await api.etcSchedule.getCourse(etcScheduleCourseId);
      commit('SET_COURSE_DETAIL', res.data);
    } catch (error) {
      commit('SET_COURSE_DETAIL', {});
    } finally {
      commit('SET_LOADING', false);
    }
    return;
  },

  async getMemos({ commit }, etcScheduleId) {
    try {
      commit('SET_MEMOS_LOADING', true);
      const res = await api.memo.getAll('etc_schedule_times', etcScheduleId);
      commit('SET_MEMOS', res.data);
    } catch (error) {
      commit('SET_MEMOS', []);
    } finally {
      commit('SET_MEMOS_LOADING', false);
    }

    return;
  },
};
