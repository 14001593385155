import axios from '../axios';

const BASE_URL = '/v2/staff/etcSchedule';

export default {
  // 기타일정 목록
  getAll: params =>
    axios.get(BASE_URL, {
      params,
    }),

  // 기타일정 목록 count
  getCount: params =>
    axios.get(`${BASE_URL}/count`, {
      params,
    }),

  // 기타일정 중복 check (수업, 상담 생성 전 중복 체크용)
  getCheck: params =>
    axios.get(`${BASE_URL}/check`, {
      params,
    }),

  // 기타일정 코스 상세
  getCourse: id => axios.get(`${BASE_URL}/etcSchedule/${id}`),

  // 기타일정 상세
  get: id => axios.get(`${BASE_URL}/etcScheduleTime/${id}`),

  // 생성
  create: data => axios.post(BASE_URL, data),

  // 이 일정만 수정
  updateSingle: (id, data) => axios.patch(`${BASE_URL}/${id}`, data),

  // 이후 모든 수정
  updateBulk: (id, data) => axios.patch(`${BASE_URL}/bulk/${id}`, data),

  // 이 일정만 삭제
  delete: id => axios.delete(`${BASE_URL}/${id}`),

  // 이후 모든 일정 삭제
  deleteBulk: (id, data) => axios.delete(`${BASE_URL}/bulk/${id}`, { data }),
};
