<template>
  <div></div>
</template>

<script>
export default {
  created() {
    const queries = this.$route.query;
    if (queries?.result === 'success') {
      this.confirmRegisterCard(this.$route.query);
    }
    if (queries?.result === 'fail') {
      this.$utils.notify.error(this, '오류', queries.message || '카드 등록에 실패했습니다.');
      this.$router.replace({ name: 'SettingsServicePayments' });
    }
  },

  methods: {
    async confirmRegisterCard(query) {
      try {
        const response = await this.$api.payment.registCardForMonthlyServiceWithTossBilling({ authKey: query.authKey });
        //eslint-disable-next-line
        console.log(response);
        const cardId = response.data?.data.id;

        this.$router.replace({
          name: 'SettingsServicePayments',
          query: { status: query.status, cardId, selectedProductId: query.selectedProductId, step: query.step },
        });
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        this.$router.replace({ name: 'SettingsServicePayments' });
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!to.query.authKey) {
        vm.$router.replace({
          name: 'SettingsServicePayments',
        });
      }
      next();
    });
  },
};
</script>

<style lang="scss" scoped></style>
