<template>
  <el-tooltip effect="light" placement="bottom">
    <CircleButton
      v-loading="loading"
      :class="['etc-schedule-detail-arrow__button', direction]"
      :bordered="bordered"
      @click="$emit('click')"
    >
      <img :src="icon" :alt="tooltipInfo.title" />
    </CircleButton>
    <div slot="content" class="etc-schedule-detail-arrow__button__popover">
      <p>{{ tooltipInfo.title }}</p>
      <p>{{ tooltipInfo.dateTime }}</p>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    direction: {
      type: String,
      default: 'prev',
    },
    tooltipInfo: {
      type: Object,
      default() {
        return { title: '', dateTime: '' };
      },
    },
  },

  computed: {
    icon() {
      return this.direction === 'prev'
        ? require('@assets/images/icons/arrow-prev.svg')
        : require('@assets/images/icons/arrow-next.svg');
    },
  },
};
</script>

<style lang="scss">
.etc-schedule-detail-arrow__button__popover {
  @include flex(column, center, center);
  p + p {
    margin-top: 4px;
  }
}
</style>
