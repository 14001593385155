<template>
  <el-dialog :visible="show" width="500px" @close="$emit('close')">
    <span slot="title" class="title">{{ title }}</span>
    <div v-if="isChangeSubscription">
      <span>[{{ selectedProduct.title }}] (으)로 요금제를 변경합니다.</span><br />
      <br />
      <p class="price-info">다음 결제 예정일 : {{ nextPayDate }}</p>
      <p class="price-info">결제 금액 : {{ payPrice | comma }} 원</p>
      <br />
      요금제를 변경하시겠습니까?
    </div>
    <div v-if="!isChangeSubscription">
      <span class="price-info">결제 금액 : {{ payPrice | comma }} 원</span><br />
      결제를 진행하시겠습니까?
    </div>

    <div slot="footer">
      <el-button @click="$emit('close')">취소</el-button>
      <el-button type="primary" @click="$emit('proceedPayment')" :disabled="isLoading">
        {{ isChangeSubscription ? '확인' : '결제' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    case: String,
    payPrice: Number,
    expireDate: String,
    selectedProduct: Object,
    isLoading: Boolean,
  },
  computed: {
    title() {
      return `월 정기 결제 (${this.selectedProduct.grade.toUpperCase()})`;
    },

    nextPayDate() {
      return this.expireDate.replaceAll('.', '-');
    },

    isChangeSubscription() {
      const isAfter = this.moment(this.expireDate).isAfter(this.moment());
      return isAfter && this.selectedProduct.quantity === 1;
    },

    showWarnTxt() {
      switch (this.case) {
        case '13':
        case '12':
        case '14':
        case '31':
        case '32':
        case '34':
        case '21':
        case '23':
        case '41':
        case '43':
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #303133;
  font-size: 18px;
  font-weight: 500;
}
.price-info {
  font-weight: 700;
  color: $color-primary;
}
.txt-impact {
  color: $color-danger;
  font-weight: bold;
}
</style>
