<template>
  <div class="etc-schedule-memo" v-loading="memosLoading || isMutating">
    <div class="etc-schedule-memo__title">
      <h4>
        메모
        <CircleButton @click="toggleMemoSortBy" size="small" style="margin-left: 8px;">
          <i class="el-icon-sort" />
        </CircleButton>
      </h4>
      <button
        v-if="canCreateLectureMemo"
        class="etc-schedule-memo__button"
        @click="handleClickAddMemo"
        :disabled="memoCreating || memoEditing"
        round
      >
        추가
      </button>
    </div>

    <ul v-if="memoCreating || memos.length > 0">
      <MemoCreatingWithImage
        v-if="memoCreating"
        v-model="memoCreating"
        @cancel="memoCreating = null"
        @save="handleClickCreateMemo"
      />

      <MemoItemWithImage
        v-for="memo in memoSorted"
        :key="memo.id"
        v-model="memoEditing"
        :memo="memo"
        :isEditing="isEditing(memo.id)"
        :canUpdateMemo="canUpdateLectureMemo"
        :canDeleteMemo="canDeleteLectureMemo"
        @delete="handleClickDeleteMemo"
        @edit="handleClickEditMemo"
        @cancel-edit="handleClickCancelEditMemo"
        @save="handleClickSaveEditMemo"
      />
    </ul>

    <p v-else class="etc-schedule-memo__no-data">메모가 없습니다.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memoSortBy: 'desc',
      memoCreating: null,
      memoEditing: null,
      isMutating: false,
    };
  },

  computed: {
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    memos() {
      return this.$store.getters['etcSchedule/memos'];
    },
    memosLoading() {
      return this.$store.getters['etcSchedule/memosLoading'];
    },
    memoSorted() {
      const memos = _.sortBy(this.memos, 'updated_at');
      if (this.memoSortBy === 'desc') memos.reverse();
      return memos;
    },
  },

  methods: {
    /** 메모 정렬 */
    toggleMemoSortBy() {
      this.memoSortBy = this.memoSortBy === 'asc' ? 'desc' : 'asc';
    },

    /** 메모 추가 */
    handleClickAddMemo() {
      this.memoCreating = { memo: '', attachment: [], attachments: [] };
      this.memoEditing = null;
    },

    /** 메모 수정인지 체크 */
    isEditing(memoId) {
      return this.memoEditing && this.memoEditing.id === memoId;
    },

    /** 메모 삭제 */
    async handleClickDeleteMemo(memo) {
      const title = '메모 삭제';
      const message = '메모를 삭제하시겠습니까?';
      const proceed = await this.$confirm(message, title)
        .then(() => true)
        .catch(() => false);
      if (proceed) {
        if (this.isMutating) return;
        this.isMutating = true;
        try {
          await this.$api.memo.delete(memo.id);
          this.$store.dispatch('etcSchedule/getMemos', this.etcScheduleDetail.id);
        } catch (error) {
          this.$utils.notify.parseError(this, error);
        } finally {
          this.isMutating = false;
        }
      }
    },

    /** 메모 추가 */
    async handleClickCreateMemo() {
      if (this.isMutating) return;
      this.isMutating = true;
      try {
        const data = {
          ref_type: 'etc_schedule_times',
          ref_id: this.etcScheduleDetail.id,
          ..._.omit(this.memoCreating, 'attachments'),
        };
        await this.$api.memo.create(data);
        await this.$store.dispatch('etcSchedule/getMemos', this.etcScheduleDetail.id);
        this.memoCreating = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isMutating = false;
      }
    },

    /** 메모 수정 모드 */
    handleClickEditMemo(memo) {
      this.memoEditing = {
        ...memo,
        attachment: memo.attachments.map(({ id }) => id),
      };
      this.memoCreating = null;
    },

    /** 메모 수정 취소 */
    handleClickCancelEditMemo() {
      this.memoEditing = null;
    },

    /** 메모 수정 내용 저장 */
    async handleClickSaveEditMemo() {
      if (this.isMutating) return;
      this.isMutating = true;
      try {
        const data = _.omit(this.memoEditing, 'attachments');
        await this.$api.memo.update(data);
        this.isMutating = false;
        await this.$store.dispatch('etcSchedule/getMemos', this.etcScheduleDetail.id);
        this.memoEditing = null;
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      } finally {
        this.isMutating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-memo {
  border: solid #f0f0f0;
  border-width: 1px 0 0 0;
  padding: 24px;
  overflow: auto;

  @include mq(1024px) {
    border-width: 0;
  }

  &__button {
    font-size: 12px;
    color: #329af0;
    padding: 4px;

    &.danger {
      color: $color-danger;
    }

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__title {
    @include flex(row, center, space-between);
    flex-wrap: wrap;
    margin-bottom: 14px;

    h4 {
      @include flex(row, center);
      color: #000;
      font-size: 17px;
      font-weight: 400;
    }
  }

  &__no-data {
    font-size: 15px;
    opacity: 0.5;
    text-align: center;
    padding: 20px 0;
  }

  // MemoCreatingWithImage, MemoItemWithImage 의 취소, 삭제 버튼 스타일
  @mixin memo-button-style {
    button {
      width: 30px;
      border: none;
      font-size: 12px;
      padding: 4px 0;
      margin-left: 2px;

      &:hover {
        text-decoration: underline;
      }

      &.round {
        background: transparent;
        color: #4799f2;

        &.danger {
          color: #f56c6c;
        }
      }
    }
  }

  /deep/ .memo-creating__buttons__base {
    @include memo-button-style;
  }

  /deep/ .memo-item__content__buttons__base {
    @include memo-button-style;
  }

  /deep/ .memo-item__buttons {
    gap: 2px;

    button {
      border: none;
      font-size: 12px;
      padding: 4px 0;
      width: 30px;

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }
}
</style>
