<template>
  <div class="etc-schedule-color-form">
    <div class="etc-schedule-color-form__chips">
      <ColorChip
        v-for="(color, index) in colorChips"
        :key="index"
        :color="color"
        :checked="color === selectedColor"
        @click="handleColorSelect"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedColor: 'ffffff',
    };
  },

  computed: {
    etcScheduleDetail() {
      return this.$store.getters['etcSchedule/detail'];
    },
    colorPresets() {
      return this.$store.getters['colorPresets/etcColorPalette'];
    },
    colorChips() {
      const chips = [...this.colorPresets];
      const currentUserColor = this.currentUser.profile.representative_color;
      // 작성자의 강사 색상을 첫 번째 요소에 추가
      if (currentUserColor) {
        chips.unshift(currentUserColor);
      }
      return chips;
    },
    isEditMode() {
      return !!this.$route.query.id;
    },
  },

  watch: {
    etcScheduleDetail: {
      deep: true,
      handler(newValue) {
        if (!this.isEditMode || !newValue) return;

        const { color } = newValue;
        this.handleColorSelect(color);
      },
    },
  },

  created() {
    if (this.currentUser && this.currentUser.profile) {
      this.selectedColor = this.currentUser.profile.representative_color || 'ffffff';
    }
  },

  methods: {
    handleColorSelect(color) {
      this.selectedColor = color;
      this.$emit('handleChangeColor', color);
    },
  },
};
</script>

<style lang="scss" scoped>
.etc-schedule-color-form {
  &__chips {
    @include flex(row);
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>
